import React, { useMemo } from 'react'
import { playlistPropTypes } from 'helpers/proptypes'
import { konstants } from '@vizeat/helpers'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSearch } from 'hooks/search/useSearch'
import { Form, Segment, Dropdown } from 'semantic-ui-react'
import { Autocomplete } from '@vizeat/components/es6/components/Autocomplete'
import { Section } from './Section'
import { EventsSelectionModalTrigger } from '../Modal/EventsSelectionModal/EventsSelectionModalTrigger'
import { FormFiltersModal } from '../Modal/FormFiltersModal'
import { PlaylistMap } from './PlaylistMap'
import { CategoriesDropdown } from 'components/forms/categories/CategoriesDropdown'
import { getLocalityAndCountry, debouncedfetchAutocomplete } from 'helpers/places'
import { mergeInForm } from 'redux/forms/actions'

const { PLAYLIST_VISIBILITY, PLAYLIST_DISPLAY_TYPE, TAGS } = konstants

const excludeDestinationTag = (tagArray) => tagArray.filter((tag) => tag !== TAGS.DESTINATION_PLAYLIST)

export function PlaylistForm({ form, playlist }) {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const { handlePlaceChange, onViewportChange, eventIdsInVewport, isSearchingEvents, place } = useSearch({
    locality: getLocalityAndCountry(form.key_words),
    onPlaceChange,
  })

  const displayableTags = useMemo(() => excludeDestinationTag(form.tags), [form.tags])
  const eventsIds = useMemo(() => {
    const includedEventIds = form.search?.includeEventIds || []
    const JSEventIdsInVewport = eventIdsInVewport.toJS()
    if (includedEventIds.length > 0) return Array.from(new Set([...includedEventIds, ...JSEventIdsInVewport]))

    return JSEventIdsInVewport
  }, [eventIdsInVewport, form])
  const isDestination = form.tags.includes(TAGS.DESTINATION_PLAYLIST)
  const shouldHideDisplayTypeForm = form.visibility === PLAYLIST_VISIBILITY.PRIVATE

  function handleChangeForm(value) {
    dispatch(mergeInForm({ formName: 'playlist', value }))
  }

  function onPlaceChange(fetchedPlace) {
    const shouldResetLocationParams = fetchedPlace.formatted !== place?.formatted
    const {
      locality: l,
      administrative_area_level_2: aal2,
      administrative_area_level_1: aal1,
      country,
      lat,
      lng,
      coordinates,
      provider_id: pid,
    } = fetchedPlace
    const locality = l || aal2 || aal1
    const keyWordsEntries = Object.entries({
      locality,
      country,
      lat: lat || coordinates.latitude,
      lng: lng || coordinates.latitude,
    }).filter(([, value]) => Boolean(value))

    handleChangeForm({ key_words: Object.fromEntries(keyWordsEntries) })
    if (shouldResetLocationParams) {
      const { radius, ...searchParams } = form.search
      handleChangeForm({ search: { ...searchParams, q: [locality, country].filter(Boolean).join(','), pid } })
    }
  }

  function switchToDestination(_, { value: shouldSwitchToDestination }) {
    if (shouldSwitchToDestination) {
      const { includeEventIds, ...value } = form.search
      handleChangeForm({
        tags: [...form.tags, TAGS.DESTINATION_PLAYLIST],
        ...(includeEventIds && { search: value }),
      })
    } else {
      const { radius, q, pid, ...value } = form.search
      handleChangeForm({
        tags: displayableTags,
        key_words: {},
        ...((radius || q || pid) && { search: value }),
      })
    }
  }

  function handleTagChange(_, { value }) {
    handleChangeForm({ tags: isDestination ? [TAGS.DESTINATION_PLAYLIST, ...value] : value })
  }

  return (
    <Form>
      {/* --------------------- NAME --------------------- */}
      <Section
        id='name'
        title={t('Playlists::Summary::Name')}
        subtitle={t(
          'Playlists::Summary::The name of your playlist, as it will appear on the website and partners dashboard',
        )}
      >
        <Form.Input
          placeholder={t('Playlists::Summary::Type in name')}
          value={form.title}
          onChange={(_, { value: title }) => handleChangeForm({ title })}
        />
      </Section>
      {/* ----------------- SUBTITLE ------------------ */}
      <Section
        id='subtitle'
        title={t('Playlists::Summary::Subtitle')}
        subtitle={t('Playlists::Summary::A short subtitle or tagline describing the playlist')}
      >
        <Form.Input
          placeholder={t('Playlists::Summary::Type in subtitle')}
          value={form.body}
          onChange={(_, { value: body }) => handleChangeForm({ body })}
        />
      </Section>
      {/* ----------------- VISIBILITY ------------------ */}
      <Section id='visibility' title={t('Playlists::Summary::Visibility')}>
        <Form.Radio
          name='visibility'
          value={PLAYLIST_VISIBILITY.PUBLIC}
          label={t('Playlists::Summary::Public (displayed in the site as a button or as a carousel)')}
          checked={form.visibility === PLAYLIST_VISIBILITY.PUBLIC}
          onChange={(_, { value: visibility }) => handleChangeForm({ visibility })}
        />
        <Form.Radio
          name='visibility'
          value={PLAYLIST_VISIBILITY.PRIVATE}
          label={t('Playlists::Summary::Private (reserved for "private" purposes only such as a private link)')}
          checked={form.visibility === PLAYLIST_VISIBILITY.PRIVATE}
          onChange={(_, { value: visibility }) => handleChangeForm({ visibility })}
        />
      </Section>
      {/* ----------------- DISPLAY TYPE ------------------ */}
      <Section
        id='display_type'
        title={t('Playlists::Summary::Display type')}
        subtitle={t('Playlists::Summary::Choose how to display the playlist on the search page')}
      >
        <Form.Radio
          name='display_type'
          value={PLAYLIST_DISPLAY_TYPE.CTA}
          label={t('Playlists::Summary::CTA')}
          checked={form.display_type === PLAYLIST_DISPLAY_TYPE.CTA}
          onChange={(_, { value }) => handleChangeForm({ display_type: value })}
          disabled={shouldHideDisplayTypeForm}
        />
        <Form.Radio
          name='display_type'
          value={PLAYLIST_DISPLAY_TYPE.CAROUSEL}
          label={t('Playlists::Summary::Carousel')}
          checked={form.display_type === PLAYLIST_DISPLAY_TYPE.CAROUSEL}
          onChange={(_, { value }) => handleChangeForm({ display_type: value })}
          disabled={shouldHideDisplayTypeForm}
        />
      </Section>

      {/* ----------------- PLAYLIST TYPE ------------------ */}
      <Section
        id='type'
        title={t('Playlists::Summary::Playlist type')}
        subtitle={t('Playlists::Summary::Choose whether to link your playlist to a location or a group of experiences')}
      >
        <Segment>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', marginBottom: '8px' }}>
            <Trans
              i18nKey={__("Playlists::Summary::You're about to create a <b><select /></b>")}
              components={{
                b: <b />,
                select: (
                  <Dropdown
                    autoComplete='off'
                    options={[
                      {
                        key: 'destination',
                        text: t('Playlists::destination'),
                        value: true,
                        as: isDestination ? 'b' : 'span',
                      },
                      {
                        key: 'playlist',
                        text: t('Playlists::playlist'),
                        value: false,
                        as: isDestination ? 'span' : 'b',
                      },
                    ]}
                    value={isDestination}
                    onChange={switchToDestination}
                  />
                ),
              }}
            />
          </div>

          {!isDestination && (
            <Trans
              i18nKey={__(
                'Playlists::Summary::You can hand pick the events you want to be included in the playlist by <button>clicking here</button>',
              )}
              components={{
                button: (
                  <EventsSelectionModalTrigger handleChangeForm={handleChangeForm} formSearchParams={form.search} />
                ),
              }}
            />
          )}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <Autocomplete
              placeholder={t('Playlists::Summary::Type in the location')}
              initialValue={place?.formatted}
              typeahead
              getItems={async (value) =>
                await debouncedfetchAutocomplete({ query: value, language: i18n.language, type: 'area' })
              }
              itemToString={(item) => item?.formatted || ''}
              onChange={({ item }) => handlePlaceChange(item)}
            />

            {place && (
              <React.Fragment>
                <Segment
                  loading={isSearchingEvents}
                  color={isSearchingEvents ? 'grey' : eventsIds.length > 0 ? 'green' : 'red'}
                >
                  <Trans
                    i18nKey={__(
                      'Playlists::Summary::<bold>{{count}}</bold> event has been found in <bold>{{locality}}</bold>',
                    )}
                    values={{ count: eventIdsInVewport.size, locality: place?.formatted }}
                    components={{ bold: <b /> }}
                  />
                  <p>
                    {t(
                      'Playlists::Summary::The experiences that can be found in the search will be those inside the circle',
                    )}
                  </p>
                </Segment>

                <PlaylistMap
                  onViewportChange={onViewportChange}
                  eventsIds={eventsIds}
                  place={place}
                  handleChangeForm={handleChangeForm}
                  form={form}
                />
              </React.Fragment>
            )}
          </div>
        </Segment>
      </Section>

      {/* ----------------- ORDER ------------------ */}
      <Section
        id='order'
        title={t('Playlists::Summary::Display order')}
        subtitle={t('Playlists::Summary::Choose the order display of the playlist')}
      >
        <Form.Input
          label={t('Playlists::Summary::Order')}
          type='number'
          min={1}
          inline
          value={form.order}
          onChange={(_, { value: order }) => handleChangeForm({ order })}
        />
      </Section>
      {/* ----------------- PLAYLIST TAGS ------------------ */}
      <Section id='tags' title={t('Playlists::Summary::Tags')}>
        <CategoriesDropdown handleChange={handleTagChange} value={displayableTags} />
      </Section>
      {/* ----------------- PLAYLIST FILTERS ------------------ */}
      <Section
        id='filters'
        title={t('Playlists::Summary::Filters')}
        subtitle={t('Playlists::Summary::Select filters that will applied on the playlist')}
      >
        <FormFiltersModal handleChangeForm={handleChangeForm} form={form} playlist={playlist} />
      </Section>
    </Form>
  )
}

PlaylistForm.propTypes = {
  form: playlistPropTypes.isRequired,
  playlist: playlistPropTypes,
}

PlaylistForm.defaultProps = {
  playlist: undefined,
}
