import {
  getEntities,
  getEntity,
  isFetching,
  getError,
  getEntityOrder,
  getEntityTotal,
  isDeleting,
  isUpdating,
} from './_utils'
import { Map, List } from 'immutable'
import { getTag } from './tags'

export const getCategories = getEntities('categories')
export const getCategory = getEntity('categories')
export const getCategoriesOrder = getEntityOrder('categories')
export const getCategoriesTotal = getEntityTotal('categories')
export const getSortedCategories = (state) => getCategoriesOrder(state).map((id) => getCategory(state, { id }))
export const getCategoriesWithTags = (state) => {
  const categories = getCategories(state)
  return categories.map((category) =>
    category.set(
      'tags',
      category.tags.map((tagId) => getTag(state, { id: tagId })),
    ),
  )
}
const getCategoryByName = (state, categoryName) =>
  getCategoriesWithTags(state).find(({ name }) => name === categoryName) || new Map()
const getCategoryTags = (state, categoryName) => {
  const category = getCategoryByName(state, categoryName)
  return category.get('tags', new List()).sortBy((tag) => tag.title)
}
export const getCategoriesTagsTitle = (state) => {
  return getCategoriesWithTags(state)
    .map((category) => category.tags)
    .toList()
    .flatten(true)
    .map((tag) => tag.title)
}
export const fetchingCategories = isFetching('/api/categories')
export const getCategoriesError = getError('/api/categories')
export const archivingCategory = (state, id) => isDeleting(`/api/categories/${id}`)(state)
export const unarchivingCategory = (state, id) => isUpdating(`/api/categories/${id}/unarchive`)(state)
export const getArchiveCategoryError = (state, id) => getError(`/api/categories/${id}`)(state)
export const getUnarchiveCategoryError = (state, id) => getError(`/api/categories/${id}/unarchive`)(state)

export const getAlcohols = (state) => getCategoryTags(state, 'alcohols')
export const getAlcohol = (state, { id }) => getAlcohols(state).find((alcohol) => alcohol.get('id') === id) || new Map()

export const getDiets = (state) => getCategoryTags(state, 'diets')
export const getDiet = (state, { id }) => getDiets(state).find((diet) => diet.get('id') === id) || new Map()

export const getAmenities = (state) => getCategoryTags(state, 'amenities')
export const getAmenity = (state, { id }) => getAmenities(state).find((amenity) => amenity.id === id) || new Map()

export const getFoods = (state) => getCategoryTags(state, 'foods')
export const getFood = (state, { id }) => getFoods(state).find((food) => food.get('id') === id) || new Map()
