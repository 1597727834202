import React, { useCallback, useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { useHasLoadingSucceeded } from 'hooks'
import { useSelector } from 'react-redux'
import {
  getScheduleEventBookedSeats as getScheduleEventBookedSeatsSelector,
  getIsClosedSchedule as getIsClosedScheduleSelector,
  fetchingEventPlanning,
  getEventPlanningError,
} from 'redux/entities/selectors'
import { Form, Icon, Accordion, AccordionTitle, AccordionContent } from 'semantic-ui-react'
import { DateSection, PricingSection, RestrictionSection, OccasionSection, EventSection } from './index'
import { set } from 'lodash'
import { isDateTodayOrLater } from 'helpers/dates'
import moment from 'moment'

export function ExperiencesAccordion({
  diets,
  guest,
  handleAccordionPanel,
  handleChange,
  host,
  demandDate,
  isPanelActive,
  requestsPayload,
  selectedTags,
}) {
  const { t } = useTranslation()

  const {
    experience,
    seats = 0,
    isApprovedRequest = false,
    price,
    mDate,
    beginsAt = '19:00',
    endsAt = '22:00',
    occasion = 'other',
    leadId,
    restrictions = [],
    body,
  } = requestsPayload[host.id] || {}
  const getIsClosedSchedule = useSelector((state) => (date) => {
    const mDate = moment.utc(date)
    return getIsClosedScheduleSelector(state, mDate, experience?.id)
  })
  const getScheduleEventBookedSeats = useSelector(
    (state) => (mDate) => getScheduleEventBookedSeatsSelector(state, { date: mDate, eventId: experience?.id }),
  )

  const isFetchingEventPlanning = useSelector((state) => fetchingEventPlanning(state, experience?.id))
  const fetchingEventPlanningError = useSelector((state) => getEventPlanningError(state, experience?.id))
  const hasEventPlanningFetchSucceeded = useHasLoadingSucceeded(isFetchingEventPlanning, fetchingEventPlanningError)

  const isDateInValid = useCallback(
    (date) => getIsClosedSchedule(date) || getScheduleEventBookedSeats(date) > 0 || !isDateTodayOrLater(date),
    [getIsClosedSchedule, getScheduleEventBookedSeats],
  )

  const handleRequestPayloadChange = useCallback(
    (key, value) => {
      handleChange({ requestsPayload: set(requestsPayload, [host.id, key], value) })
    },
    [handleChange, host.id, requestsPayload],
  )

  useEffect(() => {
    if (experience?.id && hasEventPlanningFetchSucceeded && !mDate && !isDateInValid(demandDate)) {
      handleRequestPayloadChange('mDate', demandDate)
    }
  }, [demandDate, experience?.id, handleRequestPayloadChange, hasEventPlanningFetchSucceeded, isDateInValid, mDate])

  function handleExperienceTimeChange({ from, to } = {}) {
    if (from) handleRequestPayloadChange('beginsAt', from)
    if (to) handleRequestPayloadChange('endsAt', to)
  }

  function handleSeatsChange(_, { value }) {
    const numberOfSeats = parseInt(value, 10)
    if (!experience?.max_seats) return handleRequestPayloadChange('seats', numberOfSeats)
    handleRequestPayloadChange('seats', Math.min(numberOfSeats, experience?.max_seats))
  }

  return (
    <Accordion fluid styled>
      <AccordionTitle active={isPanelActive} index={host.id} onClick={() => handleAccordionPanel(host.id)}>
        <Icon name='dropdown' />
        {`${host.firstname} ${host.lastname} - (${seats} seats)`}
      </AccordionTitle>
      <AccordionContent active={isPanelActive}>
        <Form.Group>
          <Form.Field width={10}>
            <EventSection
              canShowLabel
              host={host}
              isDisabled={false}
              event={experience}
              filterTags={selectedTags}
              onEventChange={(experience) => handleRequestPayloadChange('experience', experience)}
              fullWidth
            />
          </Form.Field>
          <Form.Field width={2} required>
            <label>{t('EventsCalendar::Seats')}</label>
            <Form.Input
              type='number'
              name='seats'
              min={0}
              max={experience?.max_seats}
              disabled={!experience}
              placeholder={t('EventsCalendar::How many guests?')}
              value={seats}
              onChange={handleSeatsChange}
            />
          </Form.Field>
          <Form.Field width={4}>
            <Form.Radio
              label={t('EventsCalendar::Create approved requests')}
              toggle
              checked={isApprovedRequest}
              onChange={(_, { checked }) => handleRequestPayloadChange('isApprovedRequest', checked)}
              style={{ marginTop: 32 }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <PricingSection
            currency={guest.currency}
            date={mDate}
            event={experience}
            handleChange={({ price }) => handleRequestPayloadChange('price', price)}
            isPriceDisabled={isDateInValid(mDate) || !isApprovedRequest}
            price={price}
            seats={seats}
            user={guest}
          />
        </Form.Field>
        <Form.Field>
          <DateSection
            eventId={experience?.id}
            date={mDate}
            handleDateChange={(mDate) => handleRequestPayloadChange('mDate', mDate)}
            handleChange={(experienceTime) => handleExperienceTimeChange(experienceTime)}
            isDisabled={!experience}
            isTimeDisabled={isDateInValid(mDate)}
            from={beginsAt}
            to={endsAt}
            disabledDays={isDateInValid}
          />
        </Form.Field>
        <Form.Group>
          <OccasionSection
            inputWitdh={8}
            occasion={occasion}
            handleChange={({ occasion }) => handleRequestPayloadChange('occasion', occasion)}
            isDisabled={false}
          />
          <Form.Field width={8}>
            <label>{t('EventsCalendar::Lead')}</label>
            <Form.Input
              type='number'
              name='leadId'
              min={0}
              placeholder={t('EventsCalendar::Lead id')}
              value={leadId}
              onChange={(_, { value }) => handleRequestPayloadChange('leadId', parseInt(value, 10))}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <RestrictionSection
            restrictions={restrictions}
            diets={diets}
            handleChange={({ restrictions }) => handleRequestPayloadChange('restrictions', restrictions)}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('EventsCalendar::Message to the hosts (optional):')}</label>
          <Form.TextArea
            name='body'
            value={body}
            placeholder={t('EventsCalendar::Write a message')}
            rows={2}
            onChange={(_, { value }) => handleRequestPayloadChange('body', value)}
          />
        </Form.Field>
      </AccordionContent>
    </Accordion>
  )
}

ExperiencesAccordion.propTypes = {
  diets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  guest: PropTypes.shape({
    currency: PropTypes.shape({}),
  }).isRequired,
  handleAccordionPanel: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  host: PropTypes.shape({
    id: PropTypes.number,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
  }).isRequired,
  demandDate: PropTypes.moment.isRequired,
  isPanelActive: PropTypes.bool.isRequired,
  requestsPayload: PropTypes.shape({
    experience: PropTypes.shape({
      id: PropTypes.number,
      max_seats: PropTypes.number,
    }),
    seats: PropTypes.number,
    isApprovedRequest: PropTypes.bool,
    price: PropTypes.number,
    mDate: PropTypes.moment,
    occasion: PropTypes.string,
    leadId: PropTypes.number,
    restrictions: PropTypes.arrayOf(PropTypes.string),
    body: PropTypes.string,
  }).isRequired,
  selectedTags: PropTypes.arrayOf(PropTypes.string).isRequired,
}
