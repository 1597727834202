import axios from 'axios'
import settings from 'settings'
import { pick } from 'lodash'
import { normalize } from 'normalizr'

if (__DEV__) {
  axios.interceptors.request.use(
    (config) => {
      console.log(
        `${config.method} on ${config.url}`,
        'params:',
        config.params || 'no params',
        'data:',
        config.data || 'no payload',
      ) // eslint-disable-line no-console
      return config
    },
    (error) => {
      console.error('error', error) // eslint-disable-line no-console
      return Promise.reject(error)
    },
  )
}

// DEPRECATED: use @vizeat/redux-entities instead
export const buildHandlers = (dispatch) => ({
  /**
   * handle response from api. Pass the action type to dispatch and the expected keys to retrieve in data
   * @param {String} [actionType] redux action to dispatch
   * @param {Array} [expectedKeys] keys to retrieve in response.data
   * @param {String|Number} [id] (optional) id of the object, will be added in the object dispatched
   * @param {Object} [payloadPatch] object if you need to add anything to the payload that'll be sent in the redux action
   */
  response(actionType, expectedKeys = [], id = null, payloadPatch = {}) {
    return (response) => {
      const errors = expectedKeys.filter((key) => !response.data[key])
      if (errors.length > 0) throw new Error(`Missing data: ${errors.join(', ')}`)

      dispatch({
        type: actionType,
        payload: {
          ...pick(response.data, expectedKeys),
          ...payloadPatch,
        },
        id,
      })
      return response
    }
  },

  // DEPRECATED: use @vizeat/redux-entities instead
  normalizeResponse(responseSchema, actionType, otherKeys = [], id = null, payloadPatch) {
    return (response) => {
      if (!responseSchema) throw new Error('You must provide a schema')
      const errors = otherKeys.filter((key) => !response.data[key])
      if (errors.length) throw new Error(`Missing data: ${errors.join(', ')}`)

      dispatch({
        type: actionType,
        payload: {
          ...normalize(response.data, responseSchema),
          ...pick(response.data, otherKeys),
          ...payloadPatch,
        },
        id,
      })
      return response
    }
  },

  // DEPRECATED: user @vizeat/redux-entities instead
  /**
   * error handler which returns the function that will handle the error object
   * [actionType] redux action type to dispatch
   * [id] (optional) add an id to the action object
   */
  error(actionType, id = null, patch = null) {
    return (error) => {
      if (__DEV__) {
        console.error('api error', error) // eslint-disable-line no-console
        console.trace(error.stack) // eslint-disable-line no-console
      }
      dispatch({
        type: actionType,
        error: error.response || error.message || error,
        id,
        patch,
      })
      return error
    }
  },
  catchedError(error) {
    if (__DEV__) {
      console.error('unexpected error', error) // eslint-disable-line no-console
      console.trace(error.stack) // eslint-disable-line no-console
    }
  },
})

class Api {
  constructor({ baseURL, headers = {} }) {
    this.config = { headers }
    this.instance = axios.create({ baseURL })
    this.timeout = null
  }

  setHeader(key, value) {
    if (typeof key === 'string') this.config.headers[key.toLocaleLowerCase()] = value
  }

  removeHeader = (key = '') => {
    if (typeof key === 'string') delete this.config.headers[key.toLocaleLowerCase()]
  }

  // Wrapper
  get = (path, config = {}) => this.instance.get(path, { ...this.config, ...config })
  post = (path, payload, config = {}) => this.instance.post(path, payload, { ...this.config, ...config })
  put = (path, payload, config = {}) => this.instance.put(path, payload, { ...this.config, ...config })
  delete = (path, config = {}) => this.instance.delete(path, { ...this.config, ...config })
  patch = (path, payload, config = {}) => this.instance.patch(path, payload, { ...this.config, ...config })
  head = (path, config = {}) => this.instance.head(path, { ...this.config, ...config })

  // Delay API calls and call just with lastValue
  delayedGet = ({
    path,
    config,
    successCallback = () => {},
    errorHandler = (e) => {
      throw e
    },
    delay = 350,
  }) => {
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(async () => {
      try {
        const res = await this.get(path, config)
        return successCallback(res.data)
      } catch (e) {
        errorHandler(e)
      }
    }, delay)
  }
}

const internalApiHeaders = {
  Accept: [
    `application/vnd.vizeat.com::${settings.screenApiVersion}+json`,
    `application/vnd.business.vizeat.com::${settings.businessApiVersion}+json`,
  ].join(','),
}

export const businessApi = new Api({
  baseURL: settings.businessApiUrl,
  headers: internalApiHeaders,
})
export const screenApi = new Api({
  baseURL: settings.screenApiUrl,
  headers: internalApiHeaders,
})
