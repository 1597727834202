import React from 'react'
import { TagsListSearchForm } from './TagsListSearchForm'
import { TagsList } from './TagsList'

export function TagsIndex() {
  return (
    <div>
      <TagsListSearchForm />
      <TagsList />
    </div>
  )
}
