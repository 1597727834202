import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { List, Map } from 'immutable'
import { withTranslation } from 'react-i18next'
// helpers
import PropTypes from 'helpers/proptypes'
// components
import { Form, Message } from 'semantic-ui-react'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { CategoriesDropdown } from 'components/forms/categories/CategoriesDropdown'
// helpers
import { buildSelectOptions } from 'helpers/forms'
import { getTranslatedOptions } from 'helpers/options'
import { payoutGatewayOptions } from 'helpers/payouts'
import { hostTypologyOptions } from 'helpers/users'
// redux
import { updateUser } from 'redux/entities/actions'
import { createForm, removeForm, mergeInForm } from 'redux/forms/actions'
import { getForm } from 'redux/forms/reducer'
import { getSortedCurrencies, getSortedLanguages, updatingUser, getUserError } from 'redux/entities/selectors'
import { UserDescriptionsInput } from './UserDescriptionInput'

const mapStateToProps = (state, props) => ({
  fromStore: {
    currencies: getSortedCurrencies(state),
    languages: getSortedLanguages(state),
    updating: updatingUser(state, props.user.id),
    error: getUserError(state, props.user.id),
    form: getForm(state, 'user'),
  },
})
const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    updateUser: (payload) => dispatch(updateUser(props.user.id, payload)),
    createForm: ({ initialState }) => dispatch(createForm({ formName: 'user', initialState })),
    removeForm: () => dispatch(removeForm({ formName: 'user' })),
    mergeInForm: (formData) => dispatch(mergeInForm({ formName: 'user', value: formData })),
  },
})

class _UsersIdForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    user: PropTypes.immutable.record.isRequired,
    fromStore: PropTypes.shape({
      currencies: PropTypes.immutable.list,
      languages: PropTypes.immutable.list,
      updating: PropTypes.bool,
      error: PropTypes.immutable.map,
      form: PropTypes.immutable.map,
    }).isRequired,
    actions: PropTypes.shape({
      updateUser: PropTypes.func,
      createForm: PropTypes.func,
      removeForm: PropTypes.func,
      mergeInForm: PropTypes.func,
    }).isRequired,
  }

  state = {
    error: null,
  }

  UNSAFE_componentWillMount() {
    const {
      user,
      actions: { createForm },
    } = this.props
    createForm({ initialState: this.userEntityToUserForm(user) })
  }

  UNSAFE_componentWillReceiveProps({ user, actions }) {
    if (user.id !== this.props.user.id) {
      actions.mergeInForm(this.userEntityToUserForm(user))
    }
  }

  componentWillUnmount() {
    this.props.actions.removeForm()
  }

  userEntityToUserForm = (user) => ({
    tags: user.tags,
    phone: user.phone,
    currency_id: user.currency.id,
    languages_ids: user.languages.map((l) => l.id),
    descriptions: user.descriptions,
    payout_gateway: user.account.payout_gateway,
    properties: user.properties,
  })

  save = (e) => {
    e.preventDefault()
    const {
      fromStore: { form },
      actions,
    } = this.props
    return actions.updateUser(form.toJS()).then(() => {
      if (this.props.fromStore.error) this.setState({ error: this.props.fromStore.error })
    })
  }

  render() {
    const {
      t,
      user,
      fromStore: { form, currencies, languages, updating },
      actions,
    } = this.props
    const hasStripeAccount = !!user.account.stripe_account_id

    return (
      <Form onSubmit={this.save} warning={hasStripeAccount} error={!!this.state.error} loading={updating}>
        <Form.Field>
          <label>{t('Users::Summary::User Tags')}</label>
          <CategoriesDropdown
            handleChange={(_, { value }) => actions.mergeInForm({ tags: value })}
            value={form.get('tags', new List()).toJS()}
          />
        </Form.Field>

        <Form.Input
          label={t('Users::Summary::Phone')}
          type='tel'
          icon='phone'
          iconPosition='left'
          value={form.get('phone', '')}
          onChange={(e, { value }) => actions.mergeInForm({ phone: value })}
        />

        <Form.Select
          label={t('Users::Summary::Currency')}
          fluid
          options={buildSelectOptions(currencies)}
          disabled={hasStripeAccount}
          value={form.get('currency_id')}
          onChange={(e, { value }) => actions.mergeInForm({ currency_id: value })}
        />

        <Message warning>
          <p>{t('Users::Summary::The currency cannot be updated if the user already has a stripe account defined')}</p>
        </Message>

        <Form.Dropdown
          label={t('Users::Summary::Spoken languages')}
          multiple
          selection
          fluid
          search
          options={buildSelectOptions(languages)}
          value={form.get('languages_ids', new List()).toJS()}
          onChange={(e, { value }) => actions.mergeInForm({ languages_ids: value })}
        />

        {user.isHost && (
          <Form.Select
            label={t('Users::Summary::Host Typology')}
            fluid
            options={hostTypologyOptions}
            value={form
              .get('properties', new Map())
              .filter((property) => property.get('key') === 'host_typology')
              .getIn(['0', 'value'], '')}
            onChange={(e, { value }) =>
              actions.mergeInForm({
                properties: [
                  ...user.properties.toJS().filter((property) => property.key !== 'host_typology'),
                  { key: 'host_typology', value, zone: 'private' },
                ],
              })
            }
          />
        )}
        {form.get('descriptions') && (
          <UserDescriptionsInput
            hostLanguage={user.account.language}
            updateForm={(formDescriptions) => actions.mergeInForm({ descriptions: formDescriptions.toList() })}
            formDescriptions={form
              .get('descriptions')
              .reduce((acc, cur) => acc.set(String(cur.get('language_id')), Map(cur)), Map())}
          />
        )}

        <Form.Select
          label={t('Users::Summary::Payout Gateway')}
          fluid
          options={getTranslatedOptions(t, payoutGatewayOptions)}
          value={form.get('payout_gateway')}
          defaultValue={form.get('payout_gateway')}
          onChange={(e, { value }) => actions.mergeInForm({ payout_gateway: value })}
        />

        <Form.Field style={{ textAlign: 'center' }}>
          <Form.Button>{t('Users::Summary::Submit')}</Form.Button>
        </Form.Field>

        <ApiErrorMessage error={this.state.error} modal />
      </Form>
    )
  }
}

export const UsersIdForm = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_UsersIdForm))
