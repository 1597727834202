import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { getPricing, fetchingPricing, getFetchingPricingError } from 'redux/entities/selectors'
import { fetchPricing as fetchPricingAction } from 'redux/entities/actions'
import { useHasLoadingSucceeded } from 'hooks'
import { Tooltip } from '@vizeat/components/es6/components/Tooltip'
import { Form, Label, Icon } from 'semantic-ui-react'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { SelectCurrency } from 'components/inputs'
import { numbers } from '@vizeat/helpers'
import debounce from 'lodash/debounce'

const { formatIntegerPrice, formatPriceAsFloat, formatPriceAsInt } = numbers

export function PricingSection({
  allowGuestPriceEdition,
  currency,
  date,
  event,
  handleChange,
  isCurrencyDisabled,
  isPriceDisabled,
  price: initialHostPrice,
  isFixedPrice,
  initialGuestPrice,
  seats,
  user,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const pricing = useSelector((state) => getPricing(state, { id: event?.id }))
  const isFetchingPricing = useSelector((state) => fetchingPricing(state, event?.id))
  const fetchingPricingError = useSelector((state) => getFetchingPricingError(state, event?.id))
  const hasPricingFetchSucceeded = useHasLoadingSucceeded(isFetchingPricing, fetchingPricingError)

  const [price, setPrice] = useState({ host: initialHostPrice, guest: initialGuestPrice })
  const [guestCurrencyId, setGuestCurrencyId] = useState(currency.id)
  const [isFixedGuestPriceMode, setIsFixedGuestPriceMode] = useState(isFixedPrice)

  const debouncedFetchPricing = useMemo(
    () =>
      debounce(
        () => {
          dispatch(
            fetchPricingAction(event?.id, {
              currency_id: isFixedGuestPriceMode ? guestCurrencyId : user.currency_id,
              date: typeof date === 'string' ? date : date.format('YYYY-MM-DD'),
              guest_price: isFixedGuestPriceMode ? price.guest : undefined,
              host_price: price.host,
              seats,
              user_id: user.id,
              gateway: 'invoice', // doesn't add any currency conversion fees
            }),
          )
        },
        1000,
        { trailing: true },
      ),
    [
      date,
      dispatch,
      event?.id,
      guestCurrencyId,
      isFixedGuestPriceMode,
      price.guest,
      price.host,
      seats,
      user.currency_id,
      user.id,
    ],
  )

  const onPriceChange = useCallback(
    (shouldReset) => {
      handleChange({
        isFixedPrice: isFixedGuestPriceMode,
        currency: shouldReset ? {} : pricing.getIn(['payer', 'currency']),
        guestPrice: isFixedGuestPriceMode && !shouldReset ? price.guest : undefined,
        price: shouldReset ? undefined : pricing.getIn(['payee', 'price']),
        totalPrice: shouldReset ? undefined : pricing.getIn(['payer', 'total_paid']),
      })
    },
    [handleChange, isFixedGuestPriceMode, price.guest, pricing],
  )

  const handlePriceChange = useCallback(
    (role, value) => {
      const roles = { host: 'payee', guest: 'payer' }
      const formattedValue = formatPriceAsInt(value, pricing.getIn([roles[role], 'currency']))
      setPrice((prevPrice) => ({ ...prevPrice, [role]: formattedValue }))
    },
    [pricing],
  )

  useEffect(() => {
    if (event?.id && user?.id && date && seats > 0) debouncedFetchPricing()
    return () => debouncedFetchPricing.cancel()
  }, [date, event?.id, debouncedFetchPricing, user?.id, seats])

  useEffect(() => {
    if (hasPricingFetchSucceeded) {
      setPrice((prevPrice) => ({
        host: pricing.getIn(['payee', 'price']),
        guest: isFixedGuestPriceMode ? prevPrice.guest : pricing.getIn(['payer', 'unit_price_with_fees']),
      }))
      setGuestCurrencyId(pricing.getIn(['payer', 'currency', 'id']))
    }
  }, [hasPricingFetchSucceeded, isFixedGuestPriceMode, price.guest, price.host, pricing])

  useEffect(() => {
    if (hasPricingFetchSucceeded || fetchingPricingError) onPriceChange(fetchingPricingError)
  }, [fetchingPricingError, hasPricingFetchSucceeded, onPriceChange])

  if (!pricing.getIn(['payee', 'price'])) return null

  return (
    <Fragment>
      <Form.Group widths='equal' className='__item'>
        <Form.Field width={1}>
          <label>
            {t('EventsCalendar::Host price/seat {{currency}}', {
              currency: pricing.getIn(['payee', 'currency', 'iso_3']),
            })}
          </label>
          <Form.Input
            type='number'
            min={0}
            disabled={isPriceDisabled}
            placeholder={t('EventsCalendar::Enter a price per seat')}
            value={formatPriceAsFloat(price.host, pricing.getIn(['payee', 'currency']))}
            onChange={(_, { value }) => handlePriceChange('host', value)}
            step={pricing.getIn(['payee', 'currency', 'iso_3']) === 'JPY' ? 1 : 'any'}
          />
        </Form.Field>
        <Form.Field width={1}>
          <label>
            {isFixedGuestPriceMode
              ? t('EventsCalendar::Fixed guest price/seat {{currency}}', {
                  currency: pricing.getIn(['payer', 'currency', 'iso_3']),
                })
              : t('EventsCalendar::Estimated guest price/seat {{currency}}', {
                  currency: pricing.getIn(['payer', 'currency', 'iso_3']),
                })}
            {allowGuestPriceEdition && (
              <Tooltip
                renderContent={() => t('EventsCalendar::Click the icon to toggle edit mode for the guest price')}
              >
                <Icon
                  name='edit'
                  color={isFixedGuestPriceMode ? 'green' : 'red'}
                  onClick={() => setIsFixedGuestPriceMode(!isFixedGuestPriceMode)}
                />
              </Tooltip>
            )}
          </label>
          <Form.Input
            type='number'
            min={0}
            disabled={!isFixedGuestPriceMode}
            placeholder={t('EventsCalendar::Enter a price per seat')}
            value={formatPriceAsFloat(price.guest, pricing.getIn(['payer', 'currency']))}
            onChange={(_, { value }) => handlePriceChange('guest', value)}
            step={pricing.getIn(['payee', 'currency', 'iso_3']) === 'JPY' ? 1 : 'any'}
            error={fetchingPricingError}
          />
        </Form.Field>
        {isFixedGuestPriceMode && (
          <Form.Field width={1}>
            <label>{t('EventsCalendar::Guest currency')}</label>
            <SelectCurrency
              isDisabled={isCurrencyDisabled}
              currencyId={guestCurrencyId}
              handleCurrencyChange={setGuestCurrencyId}
              error={fetchingPricingError}
            />
          </Form.Field>
        )}
      </Form.Group>

      <ApiErrorMessage error={fetchingPricingError} modal />
      <Trans
        i18nKey={__(
          'EventsCalendar::Fees rate: {{feesRate}}% | Fees: {{fees}} | Total guest price incl. fees: <label>{{totalPrice}}</label>',
        )}
        values={{
          feesRate: (pricing.getIn(['fees', 'rate']) * 100).toFixed(2),
          fees: formatIntegerPrice(pricing.getIn(['fees', 'total']), 'en', pricing.getIn(['fees', 'currency'])),
          totalPrice: formatIntegerPrice(
            pricing.getIn(['payer', 'total_paid']),
            'en',
            pricing.getIn(['payer', 'currency']),
          ),
        }}
        components={{ label: <Label circular color='blue' /> }}
      />
    </Fragment>
  )
}

PricingSection.propTypes = {
  allowGuestPriceEdition: PropTypes.bool,
  currency: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  date: PropTypes.oneOfType([PropTypes.moment, PropTypes.string]).isRequired,
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  handleChange: PropTypes.func.isRequired,
  isCurrencyDisabled: PropTypes.bool,
  isPriceDisabled: PropTypes.bool,
  price: PropTypes.number,
  isFixedPrice: PropTypes.bool,
  initialGuestPrice: PropTypes.number,
  seats: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency_id: PropTypes.number,
  }),
}

PricingSection.defaultProps = {
  allowGuestPriceEdition: false,
  event: undefined,
  isFixedPrice: false,
  isCurrencyDisabled: false,
  isPriceDisabled: false,
  price: undefined,
  initialGuestPrice: undefined,
  user: undefined,
}
