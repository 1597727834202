import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import moment from 'moment'
import { InputExpandingOnFocus } from 'layouts'
import { Form, Button } from 'semantic-ui-react'
import { SearchInput, SelectInput } from 'components/inputs'
import { CategoriesDropdown } from 'components/forms/categories/CategoriesDropdown'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { konstants } from '@vizeat/helpers'
import PropTypes from 'helpers/proptypes'
import { getTranslatedOptions } from 'helpers/options'
import {
  mealTypesOptions,
  statusOptions,
  toReviewStatusOptions,
  dateRangeOptions,
  freezeOpts,
  isPublishedOpts,
  instantBookingOpts,
  bookingModeOpts,
  applicationTypeOpts,
} from 'helpers/events'
import { getFormattedAdmins } from 'helpers/admins'
import { eventsFollowupsActions } from 'helpers/followups'
import { useSelector } from 'react-redux'
import { getLocation } from 'redux/reducers/router'
import { getAdmins } from 'redux/entities/selectors'

const { SORTED_CITIES } = konstants
const DEFAULT_QUERY_PARAMS = {
  search: undefined,
  city: undefined,
  host: undefined,
  status: undefined,
  location: undefined,
  daterange: undefined,
  fromdate: undefined,
  todate: undefined,
  instantbooking: undefined,
  tags: undefined,
  applicationType: undefined,
  published: undefined,
  followups: undefined,
  assignment: undefined,
  isFrozen: undefined,
  type: undefined,
  minGuests: undefined,
  maxGuests: undefined,
  bookingMode: undefined,
}

function _EventListSearchForm({ eventsType, router }) {
  const { t } = useTranslation()
  const location = useSelector(getLocation)
  const admins = useSelector(getAdmins)

  const [queryParams, setQueryParams] = useState(DEFAULT_QUERY_PARAMS)

  const options = useMemo(
    () => ({
      type: getTranslatedOptions(t, mealTypesOptions),
      status: getTranslatedOptions(t, eventsType === 'review' ? toReviewStatusOptions : statusOptions),
      published: getTranslatedOptions(t, isPublishedOpts),
      isFrozen: getTranslatedOptions(t, freezeOpts),
      bookingMode: getTranslatedOptions(t, bookingModeOpts),
      daterange: getTranslatedOptions(t, dateRangeOptions),
      instantbooking: getTranslatedOptions(t, instantBookingOpts),
      applicationType: getTranslatedOptions(t, applicationTypeOpts),
      followups: getTranslatedOptions(t, eventsFollowupsActions),
    }),
    [eventsType, t],
  )
  const adminOptions = useMemo(() => getFormattedAdmins(admins), [admins])

  function updateQuery(query) {
    router.push(location.mergeIn(['query'], { ...query, offset: 0 }).toJS())
  }

  function clearSorting() {
    updateQuery({ sortBy: undefined, order: undefined })
  }

  function clearFilters() {
    setQueryParams(DEFAULT_QUERY_PARAMS)
  }

  function updateQueryParams(params) {
    setQueryParams((prevState) => ({ ...prevState, ...params }))
  }

  function updateDateRange(value) {
    updateQueryParams({
      daterange: value,
      ...(value === '' && { fromdate: undefined, todate: undefined }),
      ...(value === 'published_at' && { published: '1' }),
    })
  }

  function updateDate(mDate, queryParamKey) {
    if (!mDate) return updateQueryParams({ [queryParamKey]: undefined })

    const formattedDate = mDate.format('YYYY-MM-DD')
    const complementaryKey = ['fromdate', 'todate'].find((key) => key !== queryParamKey)
    const currentMomentFromDate = moment(queryParams.fromdate, 'YYYY-MM-DD')
    const currentMomentToDate = moment(queryParams.todate, 'YYYY-MM-DD')
    const shouldUpdateComplementaryDate =
      !queryParams[complementaryKey] ||
      (queryParamKey === 'fromdate' && mDate.isAfter(currentMomentToDate)) ||
      (queryParamKey === 'todate' && mDate.isBefore(currentMomentFromDate))

    if (shouldUpdateComplementaryDate) return updateQueryParams({ fromdate: formattedDate, todate: formattedDate })
    updateQueryParams({ [queryParamKey]: formattedDate })
    if (!queryParams.daterange) updateDateRange('created_at')
  }

  function handleSubmit(e) {
    e.preventDefault()
    updateQuery(queryParams)
  }

  useEffect(() => {
    setQueryParams({
      search: location.getIn(['query', 'search'], DEFAULT_QUERY_PARAMS.search),
      city: location.getIn(['query', 'city'], DEFAULT_QUERY_PARAMS.city),
      host: location.getIn(['query', 'host'], DEFAULT_QUERY_PARAMS.host),
      status: location.getIn(['query', 'status'], DEFAULT_QUERY_PARAMS.status),
      location: location.getIn(['query', 'location'], DEFAULT_QUERY_PARAMS.location),
      daterange: location.getIn(['query', 'daterange'], DEFAULT_QUERY_PARAMS.daterange),
      fromdate: location.getIn(['query', 'fromdate'], DEFAULT_QUERY_PARAMS.fromdate),
      todate: location.getIn(['query', 'todate'], DEFAULT_QUERY_PARAMS.todate),
      instantbooking: location.getIn(['query', 'instantbooking'], DEFAULT_QUERY_PARAMS.instantbooking),
      tags: location.getIn(['query', 'tags'], DEFAULT_QUERY_PARAMS.tags),
      applicationType: location.getIn(['query', 'applicationType'], DEFAULT_QUERY_PARAMS.applicationType),
      published: location.getIn(['query', 'published'], DEFAULT_QUERY_PARAMS.published),
      followups: location.getIn(['query', 'followups'], DEFAULT_QUERY_PARAMS.followups),
      assignment: location.getIn(['query', 'assignment'], DEFAULT_QUERY_PARAMS.assignment),
      isFrozen: location.getIn(['query', 'isFrozen'], DEFAULT_QUERY_PARAMS.isFrozen),
      type: location.getIn(['query', 'type'], DEFAULT_QUERY_PARAMS.type),
      minGuests: location.getIn(['query', 'minGuests'], DEFAULT_QUERY_PARAMS.minGuests),
      maxGuests: location.getIn(['query', 'maxGuests'], DEFAULT_QUERY_PARAMS.maxGuests),
      bookingMode: location.getIn(['query', 'bookingMode'], DEFAULT_QUERY_PARAMS.bookingMode),
    })
  }, [location])

  return (
    <Form onSubmit={handleSubmit} autoComplete='off'>
      <Form.Group style={{ whiteSpace: 'nowrap' }}>
        <InputExpandingOnFocus
          label={t('Experiences::Experience')}
          Component={SearchInput}
          queryName='search'
          icon='search'
          iconPosition='left'
          placeholder={t('Experiences::id, title or event date')}
          value={queryParams.search || ''}
          onChange={(value) => updateQueryParams({ search: value })}
        />

        <InputExpandingOnFocus
          label={t('Experiences::Host')}
          Component={SearchInput}
          queryName='host'
          placeholder={t('Experiences::id, first/last name, email, phone')}
          icon='search'
          iconPosition='left'
          value={queryParams.host || ''}
          onChange={(host) => updateQueryParams({ host })}
        />

        <InputExpandingOnFocus
          label={t('Experiences::Location')}
          Component={SearchInput}
          queryName='location'
          placeholder={t('Experiences::Address, city, country (iso), postal code')}
          icon='search'
          iconPosition='left'
          value={queryParams.location || ''}
          onChange={(location) => updateQueryParams({ location })}
        />

        <InputExpandingOnFocus
          label={t('Experiences::City')}
          blurWidth={120}
          Component={SelectInput}
          search
          compact
          queryName='city'
          placeholder={t('Experiences::Choose a city ...')}
          options={[
            { text: '', value: '' },
            { text: t('Experiences::Tier 1 cities'), value: 'tier1' },
          ].concat(SORTED_CITIES)}
          value={queryParams.city}
          onChange={(city) => updateQueryParams({ city })}
        />

        <Form.Field>
          <label>{t('Experiences::Experience type')}</label>
          <SelectInput queryName='type' fluid multiple search options={options.type} value={queryParams.type} />
        </Form.Field>

        <Form.Field>
          <label>{t('Experiences::Filter by status')}</label>
          <SelectInput queryName='status' fluid multiple search options={options.status} value={queryParams.status} />
        </Form.Field>

        <Form.Field>
          <label>{t('Experiences::Tags')}</label>
          <CategoriesDropdown
            handleChange={(_, { value }) => updateQueryParams({ tags: value.join(',') })}
            value={queryParams.tags?.split(',')}
          />
        </Form.Field>

        {eventsType !== 'review' && (
          <InputExpandingOnFocus
            label={t('Experiences::Published')}
            blurWidth={120}
            Component={SelectInput}
            queryName='published'
            fluid
            search
            options={options.published}
            value={queryParams.published}
          />
        )}

        <InputExpandingOnFocus
          label={t('Experiences::Frozen Experiences')}
          blurWidth={120}
          Component={SelectInput}
          queryName='isFrozen'
          fluid
          search
          options={options.isFrozen}
          value={queryParams.isFrozen}
        />

        <InputExpandingOnFocus
          label={t('Experiences::Booking Mode')}
          blurWidth={120}
          Component={SelectInput}
          queryName='bookingMode'
          fluid
          search
          options={options.bookingMode}
          value={queryParams.bookingMode}
        />

        <Form.Field>
          <Button type='submit' style={{ marginTop: '24px' }}>
            {t('Experiences::Search')}
          </Button>
        </Form.Field>
      </Form.Group>

      <Form.Group style={{ whiteSpace: 'nowrap' }}>
        <InputExpandingOnFocus
          label={t('Experiences::Search by date range')}
          blurWidth={120}
          Component={SelectInput}
          queryName='daterange'
          fluid
          options={options.daterange}
          onChange={updateDateRange}
          value={queryParams.daterange}
          preventNavigation
        />

        <Form.Field>
          <label>{t('Experiences::Between')}</label>
          <div>
            <DatePicker
              name='fromdate'
              todayButton={t('Experiences::Today')}
              fluid
              selected={
                queryParams.fromdate && moment(queryParams.fromdate).isValid() ? moment(queryParams.fromdate) : ''
              }
              onChange={(date) => updateDate(date, 'fromdate')}
              placeholderText={t('Experiences::start date')}
              dateFormat='DD/MM/YYYY'
            />
          </div>
        </Form.Field>

        <Form.Field>
          <label>{t('Experiences::And')}</label>
          <DatePicker
            name='todate'
            todayButton={t('Experiences::Today')}
            fluid
            selected={queryParams.todate && moment(queryParams.todate).isValid() ? moment(queryParams.todate) : ''}
            onChange={(date) => updateDate(date, 'todate')}
            placeholderText={t('Experiences::end date')}
            dateFormat='DD/MM/YYYY'
          />
        </Form.Field>

        <InputExpandingOnFocus
          label={t('Experiences::Min Guests')}
          blurWidth={60}
          Component={SearchInput}
          queryName='minGuests'
          placeholder={t('Experiences::Min Guests')}
          type='number'
          min={1}
          fluid
          value={queryParams.minGuests || ''}
          onChange={(value) => updateQueryParams({ minGuests: value })}
        />

        <InputExpandingOnFocus
          label={t('Experiences::Max Guests')}
          blurWidth={60}
          Component={SearchInput}
          type='number'
          min={1}
          queryName='maxGuests'
          placeholder={t('Experiences::Max Guests')}
          fluid
          value={queryParams.maxGuests || ''}
          onChange={(value) => updateQueryParams({ maxGuests: value })}
        />

        <InputExpandingOnFocus
          label={t('Experiences::Instant bookings')}
          blurWidth={120}
          Component={SelectInput}
          queryName='instantbooking'
          fluid
          search
          options={options.instantbooking}
          value={queryParams.instantbooking}
        />

        <InputExpandingOnFocus
          label={t('Experiences::New hosts / New events')}
          blurWidth={120}
          Component={SelectInput}
          queryName='applicationType'
          fluid
          search
          options={options.applicationType}
          value={queryParams.applicationType}
        />

        <InputExpandingOnFocus
          label={t('Experiences::Followups')}
          blurWidth={120}
          Component={SelectInput}
          queryName='followups'
          options={options.followups}
          fluid
          search
          value={queryParams.followups}
        />

        <InputExpandingOnFocus
          label={t('Experiences::Assignment')}
          blurWidth={120}
          Component={SelectInput}
          queryName='assignment'
          fluid
          search
          options={adminOptions}
          value={queryParams.assignment}
        />

        <Form.Field>
          <label>&nbsp;</label>
          <Button type='submit'>{t('Experiences::Search')}</Button>
        </Form.Field>

        <Button.Group vertical basic compact size='mini'>
          <Button onClick={clearFilters}>{t('Experiences::Clear filters')}</Button>
          <Button onClick={clearSorting}>{t('Experiences::Clear sorting')}</Button>
        </Button.Group>
      </Form.Group>
    </Form>
  )
}

_EventListSearchForm.propTypes = {
  eventsType: PropTypes.string,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

_EventListSearchForm.defaultProps = {
  eventsType: undefined,
}

export const EventListSearchForm = withRouter(_EventListSearchForm)
