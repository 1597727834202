import { getEntities, getEntity } from './_utils'
import { languages } from '../schemas/statics'

// Countries
export const getCountries = getEntities('countries')
export const getCountry = getEntity('countries')

// Currencies
export const getCurrencies = getEntities('currencies')
export const getCurrency = getEntity('currencies')
export function getSortedCurrencies(state) {
  return getCurrencies(state)
    .toList()
    .sortBy((currency) => currency.title)
}

// Languages
export const getLanguages = getEntities('languages')
export const getLanguage = getEntity('languages')
export function getSortedLanguages(state) {
  return getLanguages(state)
    .toList()
    .sortBy((language) => language.title)
}
export function getLanguageByLocale(state, locale) {
  const emptyLanguage = new languages.Record()
  return getLanguages(state).find((language) => language.locale === locale, null, emptyLanguage)
}
