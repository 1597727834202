import settings from 'settings'
import moment from 'moment'
// Libs
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { withTranslation } from 'react-i18next'
// Components
import { Message, Segment, Modal, Button, Icon, Confirm } from 'semantic-ui-react'
import EditPageMenu from 'components/menus/EditPageMenu'
import { Link } from 'react-router'
import { animateScroll } from 'react-scroll'
import { MenuButton } from './MenuButton'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { FollowupsModal } from 'components/followups'
// Styles
import './Menu.css'
// Redux
import {
  updateEvent,
  deleteFile,
  updateEventFiles,
  approveEvent,
  saveAsDraft,
  rejectEvent,
  requestChangeOnEvent,
  duplicateEvent,
  reindexEvent,
  createFollowups,
  freezeEvent,
  unfreezeEvent,
  closeAllDatesEvent,
  openAllDatesEvent,
} from 'redux/entities/actions'
import { getLocation } from 'redux/reducers/router'
import { getForm } from 'redux/forms/reducer'
import { updatingEvent, duplicatingEvent, getEventError, getEventDuplicationError } from 'redux/entities/selectors'
import { getAdminProfile } from 'redux/reducers/admin/selectors'
import { connectAs } from 'helpers/users'

const mapStateToProps = (state, props) => ({
  fromStore: {
    duplicationError: getEventDuplicationError(state, props.event.id),
    location: getLocation(state),
    getEventForm: () => getForm(state, 'event'),
    updating: updatingEvent(state, props.event.id),
    duplicating: duplicatingEvent(state),
    error: getEventError(state, props.event.id),
    adminProfile: getAdminProfile(state),
  },
})

const mapDispatchToProps = (dispatch, props) => ({
  actions: {
    saveAll: (payload, adminProfile, shouldResetFutureEvents = false) => {
      if (payload && payload.toJS) payload = payload.toJS()
      const { cover, sources = [], foods = [], venues = [], delete_pictures_ids = [], ...eventPayload } = payload // eslint-disable-line camelcase
      const formattedPayload = {
        ...eventPayload,
        descriptions: eventPayload.descriptions
          .map((description) => ({
            ...description,
            items: description.items.sort((i1, i2) => i1.order - i2.order),
          }))
          .sort((d1, d2) => d1.order - d2.order),
        booking_deadline: moment.duration(eventPayload.booking_deadline).toISOString(),
        pe_booking_deadline: moment.duration(eventPayload.pe_booking_deadline).toISOString(),
        cancellation_deadline: moment.duration(eventPayload.cancellation_deadline).toISOString(),
        pe_cancellation_deadline: moment.duration(eventPayload.pe_cancellation_deadline).toISOString(),
        should_reset_future_events: shouldResetFutureEvents,
      }
      const promises = [dispatch(updateEvent(props.event.id, formattedPayload))]

      if (cover || sources.length || foods.length || venues.length) {
        promises.push(dispatch(updateEventFiles(props.event.id, { cover, sources, foods, venues })))
      }
      if (delete_pictures_ids.length) promises.push(...delete_pictures_ids.map((id) => dispatch(deleteFile(id))))
      if (payload.followup) {
        promises.push(
          dispatch(
            createFollowups({
              id: props.event.id,
              followups: [
                {
                  admin_id: adminProfile.id,
                  following_id: props.event.id,
                  following_type: 'events',
                  action: 'COMMENT',
                  comment: payload.followup,
                },
              ],
            }),
          ),
        )
      }
      return Promise.all(promises).then(([event, files]) => ({ event, files }))
    },
    approve: () => dispatch(approveEvent(String(props.event.id))),
    draft: () => dispatch(saveAsDraft(String(props.event.id))),
    requestChange: () => dispatch(requestChangeOnEvent(String(props.event.id))),
    reject: () => dispatch(rejectEvent(String(props.event.id))),
    duplicate: (id, payload) => dispatch(duplicateEvent(id, payload)),
    freezeEvent: () => dispatch(freezeEvent(props.event.id)),
    unfreezeEvent: () => dispatch(unfreezeEvent(props.event.id)),
    openAllDates: () => dispatch(openAllDatesEvent(props.event.id)),
    closeAllDates: () => dispatch(closeAllDatesEvent(props.event.id)),
    reindexEvent: () => dispatch(reindexEvent(props.event.id)),
    addFollowups: (payload) => dispatch(createFollowups(payload)),
  },
})

class _EventIdMenu extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    event: PropTypes.immutable.record.isRequired,
    onDuplicateEvent: PropTypes.func,
    fromStore: PropTypes.shape({
      duplicationError: PropTypes.shape({}),
      location: PropTypes.object,
      getEventForm: PropTypes.func.isRequired,
      updating: PropTypes.bool,
      error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      adminProfile: PropTypes.shape({
        id: PropTypes.number,
        fullname: PropTypes.string,
      }),
    }).isRequired,
    actions: PropTypes.shape({
      saveAll: PropTypes.func,
      approve: PropTypes.func,
      draft: PropTypes.func,
      requestChange: PropTypes.func,
      reject: PropTypes.func,
      duplicate: PropTypes.func,
      freezeEvent: PropTypes.func,
      unfreezeEvent: PropTypes.func,
      openAllDates: PropTypes.func,
      closeAllDates: PropTypes.func,
      reindexEvent: PropTypes.func,
      addFollowups: PropTypes.func,
    }).isRequired,
  }

  static defaultProps = {
    onDuplicateEvent: undefined,
    adminProfile: undefined,
  }

  static contextTypes = {
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
      goBack: PropTypes.func.isRequired,
    }).isRequired,
  }

  state = { openFollowup: false, confirmApproval: false, error: null }

  getMenuItems = (t) => [
    { text: t('Experiences::Summary::Categories'), value: 'section-tags' },
    { text: t('Experiences::Summary::Booking Modes'), value: 'booking-modes' },
    { text: t('Experiences::Summary::Community comment'), value: 'comment' },
    { text: t('Experiences::Summary::Description'), value: 'section-description' },
    { text: t('Experiences::Summary::Pricing'), value: 'section-pricing' },
    { text: t('Experiences::Summary::Capacity & Time'), value: 'section-capacity-and-time' },
    { text: t('Experiences::Summary::Experience Deadlines'), value: 'section-event-deadlines' },
    { text: t('Experiences::Summary::Amenities'), value: 'section-amenities' },
    { text: t('Experiences::Summary::Address & Map'), value: 'section-address' },
    { text: t('Experiences::Summary::Media'), value: 'section-media' },
  ]

  handleError = (promise) =>
    this.setState({ error: null }, async () => {
      await promise
      if (this.props.fromStore.error) this.setState({ error: this.props.fromStore.error })
    })

  handleWithFollowup = (func, action = 'ASSIGNMENT') => {
    const {
      actions,
      event,
      fromStore: { adminProfile },
    } = this.props

    this.handleError(func)
    this.handleError(
      actions.addFollowups({
        followups: [
          {
            admin_id: adminProfile.id,
            following_id: event.id,
            following_type: 'events',
            action,
            comment: adminProfile.fullname,
          },
        ],
      }),
    )
  }

  handleFollowupSubmit = (id, action, comment, e) => {
    e.preventDefault()
    return this.props.actions
      .addFollowups({
        followups: [
          {
            admin_id: this.props.fromStore.adminProfile.id,
            following_id: id,
            following_type: 'events',
            action,
            comment,
          },
        ],
      })
      .then(() => this.setState({ openFollowup: false }))
  }

  handleApproval(forced = false) {
    const { event, actions } = this.props
    if (event.tags.includes('vizeat') || forced) {
      this.setState({ confirmApproval: false })
      this.handleWithFollowup(actions.approve())
    } else {
      this.setState({ confirmApproval: true })
    }
  }

  requestChange = (t) =>
    this.setState({ error: null }, () => {
      return this.props.actions.requestChange().then(({ eventResponse, intercomResponse }) => {
        if (intercomResponse.response) {
          // how to detect axios error payload
          this.setState({
            error: fromJS({
              status: 404,
              statusText: 'Not found',
              data: {
                message: t(
                  'Experiences::Summary::User not found on intercom but you successfully updated the experience status to "request_change"',
                ),
              },
            }),
          })
        }
      })
    })

  duplicateEvent = async () => {
    const { event } = this.props

    if (event.place) delete event.place

    const response = await this.props.actions.duplicate(event.id, {
      overrides: { status: 'draft' },
      detailed: true,
      duplicateConstraints: true,
    })

    const createdEventId = response.payload.data.result.event
    const createEventError = this.props.fromStore.error

    if (!createEventError) {
      if (this.props.onDuplicateEvent) this.props.onDuplicateEvent(this.props.event.get('id'))
      animateScroll.scrollToTop({ duration: 250 })
      return this.setState({ openModal: false }, () => this.context.router.push(`/events/${createdEventId}`))
    } else {
      this.setState({ openModal: false })
    }
  }

  previewEvent(accountId) {
    connectAs(accountId, settings.globalUrlFactory.dashboard.events.index())
  }

  handleSave({ shouldResetFutureEvents }) {
    const { actions, fromStore } = this.props
    const { place, ...eventPayload } = fromStore.getEventForm()?.toJS() || {}

    const event = { ...eventPayload, place: { ...place, amenities_ids: place.amenities } }
    delete event.place.amenities

    this.setState({ showConfirmSaveEvent: false }, () =>
      this.handleError(actions.saveAll(event, fromStore.adminProfile, shouldResetFutureEvents)),
    )
  }

  render() {
    const {
      t,
      actions,
      event,
      fromStore: { duplicationError, updating },
    } = this.props
    const isFrozen = this.props.event.get('frozen_at') !== null
    return (
      <div className='event-id-menu'>
        <EditPageMenu goBack={() => this.context.router.goBack()} menuItems={this.getMenuItems(t)} />

        {!!this.props.event.get('published_at') && (
          <MenuButton
            as={Link}
            href={settings.webappUrlFactory.events.get(event.id)}
            target='_blank'
            className='view-event'
          >
            {t('Experiences::Summary::View experience in website')}
          </MenuButton>
        )}
        <Segment basic loading={!!updating}>
          <Modal
            open={this.state.showConfirmSaveEvent}
            onClose={() => this.setState({ showConfirmSaveEvent: false })}
            closeIcon
            trigger={
              <MenuButton basic color='black' onClick={() => this.setState({ showConfirmSaveEvent: true })}>
                {t('Experiences::Summary::Save')}
              </MenuButton>
            }
            basic
            size='small'
          >
            <Modal.Content>
              <p>{t('Experiences::Summary::If you have changed one of the following properties')}</p>
              <ul>
                <li>{t('Experiences::Summary::Price')}</li>
                <li>{t('Experiences::Summary::Start/End time')}</li>
                <li>{t('Experiences::Summary::Booking (private or public) deadline')}</li>
                <li>{t('Experiences::Summary::Cancellation (private or public) deadline')}</li>
                <li>{t('Experiences::Summary::Max/Min seats')}</li>
              </ul>
              <p>{t('Experiences::Summary::Do you want to apply the changes to future events (without bookings)?')}</p>
            </Modal.Content>
            <Modal.Actions>
              <Button basic inverted onClick={() => this.handleSave({ shouldResetFutureEvents: false })}>
                {t('Experiences::Summary::No, do not apply')}
              </Button>
              <Button color='green' inverted onClick={() => this.handleSave({ shouldResetFutureEvents: true })}>
                {t('Experiences::Summary::Apply')}
              </Button>
            </Modal.Actions>
          </Modal>
          {this.props.event.get('status') !== 'draft' && (
            <MenuButton basic color='purple' onClick={() => this.handleError(actions.draft())}>
              {t('Experiences::Summary::Save as draft')}
            </MenuButton>
          )}
          {this.props.event.get('status') !== 'approved' && (
            <MenuButton basic color='green' onClick={() => this.handleApproval()}>
              {t('Experiences::Summary::Approve')}
            </MenuButton>
          )}
          <MenuButton basic color='orange' onClick={() => this.handleWithFollowup(this.requestChange(t))}>
            {t('Experiences::Summary::Ask for more info')}
          </MenuButton>
          {this.props.event.get('status') !== 'rejected' && (
            <MenuButton basic color='red' onClick={() => this.handleWithFollowup(actions.reject(), 'REJECTED')}>
              {t('Experiences::Summary::Reject')}
            </MenuButton>
          )}

          <MenuButton
            basic
            color='blue'
            disabled={!event.allow_public_bookings && event.allow_private_bookings}
            onClick={() => this.handleError(isFrozen ? actions.unfreezeEvent() : actions.freezeEvent())}
          >
            {isFrozen ? t('Experiences::Summary::Unfreeze') : t('Experiences::Summary::Freeze')}
          </MenuButton>

          <MenuButton onClick={() => this.setState({ openModal: true })} basic>
            {t('Experiences::Summary::Duplicate')}
          </MenuButton>

          <MenuButton onClick={() => this.setState({ showConfirmCloseDates: true })} basic color='black'>
            {t('Experiences::Summary::Close all dates')}
          </MenuButton>

          <MenuButton onClick={() => this.setState({ showConfirmOpenDates: true })} basic color='black'>
            {t('Experiences::Summary::Open all dates')}
          </MenuButton>

          <MenuButton onClick={() => this.handleError(actions.reindexEvent())} basic color='brown'>
            {t('Experiences::Summary::Reindex')}
          </MenuButton>

          <MenuButton onClick={() => this.previewEvent(event.user.account_id)} basic color='pink'>
            {t('Experiences::Summary::Preview')}
          </MenuButton>

          <MenuButton onClick={() => this.setState({ openFollowup: true })} basic color='teal'>
            {t('Experiences::Summary::Followup')}
          </MenuButton>

          <Modal open={this.state.openModal} closeIcon onClose={() => this.setState({ openModal: false })}>
            <Modal.Content style={{ textAlign: 'center' }}>
              <Icon name='warning sign' />
              {t('Experiences::Summary::Are you sure you want to duplicate this experience?')}
            </Modal.Content>
            <div style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}>
              <ApiErrorMessage error={duplicationError} />
            </div>
            <Modal.Actions>
              <Button
                positive
                loading={!!updating}
                onClick={() => this.handleError(this.duplicateEvent())}
                disabled={!!duplicationError}
              >
                {t('Experiences::Summary::Continue')}
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal
            open={this.state.showConfirmCloseDates}
            closeIcon
            onClose={() => this.setState({ showConfirmCloseDates: false })}
          >
            <Modal.Header>{t('Experiences::Summary::Close all dates?')}</Modal.Header>
            <Modal.Content>
              {t(
                'Experiences::Summary::This will close all upcoming scheduled dates except dates with bookings, booking requests, private event requests, etc.',
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button
                positive
                basic
                loading={!!updating}
                onClick={() => {
                  this.handleError(actions.closeAllDates())
                  this.setState({ showConfirmCloseDates: false })
                }}
              >
                {t('Experiences::Summary::Close all dates')}
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal
            open={this.state.showConfirmOpenDates}
            closeIcon
            onClose={() => this.setState({ showConfirmOpenDates: false })}
          >
            <Modal.Header>{t('Experiences::Summary::Open all upcoming dates?')}</Modal.Header>
            <Modal.Content>
              <p>{t('Experiences::Summary::All upcoming dates currently closed will be opened.')}</p>
              <p>
                <Icon name={event.instant_booking ? 'check' : 'x'} color={event.instant_booking ? 'green' : 'red'} />
                {event.instant_booking
                  ? t('Experiences::Summary::Instant booking will be enabled')
                  : t('Experiences::Summary::Instant booking will NOT be enabled')}
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                positive
                basic
                loading={!!updating}
                onClick={() => {
                  this.handleError(actions.openAllDates())
                  this.setState({ showConfirmOpenDates: false })
                }}
              >
                {t('Experiences::Summary::Open all dates')}
              </Button>
            </Modal.Actions>
          </Modal>

          {this.state.openFollowup && (
            <FollowupsModal
              open={this.state.openFollowup}
              onClose={() => this.setState({ openFollowup: false })}
              followingType='events'
              followingId={event.id}
            />
          )}
        </Segment>
        <div className='menu-container'>
          <Message info className='event-info'>
            <Message.Content>{t('Experiences::Summary::Experience status:')}</Message.Content>
            <Message.Content>
              <b>
                {event.status.replace(/_/g, ' ')}
                {(event.status === 'to_review' || event.status === 'change_requested') && event.situation && (
                  <span> ({event.situation.replace(/_/g, ' ')})</span>
                )}
              </b>
            </Message.Content>
            <Message.Content>
              <b>
                {event.published_at ? t('Experiences::Summary::Published') : t('Experiences::Summary::Not published')}
              </b>
            </Message.Content>
          </Message>
        </div>
        <Confirm
          open={this.state.confirmApproval}
          onCancel={() => this.setState({ confirmApproval: false })}
          onConfirm={() => this.handleApproval(true)}
          header={t('Experiences::Summary::Warning')}
          content={t(
            "Experiences::Summary::You're about to approve an experience without any vizeat tag. The experience won't be display in the search results. Do you want to approve it anyway ?",
          )}
        />
        <ApiErrorMessage error={this.state.error} modal />
      </div>
    )
  }
}

export const EventIdMenu = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_EventIdMenu))
