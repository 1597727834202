import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHasLoadingSucceeded } from 'hooks/useHasLoadingSucceeded'
import { usePrevious } from 'hooks/usePrevious'
import { debouncedfetchAutocomplete, formatViewportObject, fetchPlace } from 'helpers/places'
import { getEventsIdsFromViewport, searchingEvents, getSearchError } from 'redux/entities/selectors'
import { searchEventsInMap } from 'redux/entities/actions'

const INITIAL_STATE = {
  place: undefined,
  coordinates: undefined,
  viewport: undefined,
}

export function useSearch({ locality, onPlaceChange }) {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()

  const previousLocality = usePrevious(locality)

  const [state, setState] = useState(INITIAL_STATE)

  const isSearchingEvents = useSelector(searchingEvents)
  const searchEventsError = useSelector(getSearchError)
  const hasSearchSucceeded = useHasLoadingSucceeded(isSearchingEvents, searchEventsError)
  const eventIdsInVewport = useSelector((reduxState) => getEventsIdsFromViewport(reduxState, state.viewport))

  const handleViewportChange = useCallback(
    (nextViewport) => {
      setState((prev) => {
        const shouldUpdateViewport = ['nelat', 'nelng', 'swlat', 'swlng'].some(
          (key) => nextViewport[key] !== state.viewport?.[key],
        )
        if (!shouldUpdateViewport) return prev
        return { ...prev, viewport: nextViewport }
      })
    },
    [state.viewport],
  )

  const handlePlaceChange = useCallback(
    async (place) => {
      if (!place?.provider_id) return

      const placeWithCoordinates = await fetchPlace({ id: place.provider_id, language: i18n.language })
      setState((prev) => ({
        ...prev,
        coordinates: placeWithCoordinates.coordinates,
        place: placeWithCoordinates,
      }))
      const { northeast, southwest } = placeWithCoordinates.coordinates.geometry.viewport
      handleViewportChange(formatViewportObject(northeast.lat, northeast.lng, southwest.lat, southwest.lng))
      onPlaceChange?.(placeWithCoordinates)
    },
    [handleViewportChange, i18n.language, onPlaceChange],
  )

  const onViewportChange = useCallback((coordinates, viewport) => {
    setState((prev) => ({ ...prev, coordinates, viewport }))
  }, [])

  useEffect(() => {
    if (!locality) setState(INITIAL_STATE)
  }, [locality])

  useEffect(() => {
    if (locality && previousLocality !== locality)
      debouncedfetchAutocomplete({ query: locality, language: i18n.language, type: 'area' }).then(([place]) =>
        handlePlaceChange(place),
      )
  }, [handlePlaceChange, i18n.language, locality, previousLocality])

  useEffect(() => {
    if (state.place) dispatch(searchEventsInMap({ q: state.place.address, pid: state.place.provider_id }))
  }, [dispatch, state.place])

  return {
    eventIdsInVewport,
    place: state.place,
    isSearchingEvents,
    searchEventsError,
    hasSearchSucceeded,
    handlePlaceChange,
    onViewportChange,
  }
}
