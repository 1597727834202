import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const tagsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'tags' })

export const fetchTags = ({ query } = {}) =>
  tagsActions.fetch({
    url: '/api/tags',
    schema: { tags: [schemas.tags] },
    query,
  })

export const fetchTag = (id, { query } = {}) =>
  tagsActions.fetch({
    url: `/api/tags/${id}`,
    schema: { tag: schemas.tags },
    query,
  })

export const createTag = (payload) =>
  tagsActions.create({
    url: '/api/tags',
    payload,
    schema: { tag: schemas.tags },
  })

export const updateTag = (id, payload, { query } = {}) =>
  tagsActions.update({
    url: `/api/tags/${id}`,
    payload,
    query,
    schema: { tag: schemas.tags },
  })

export const archiveTag = (id) =>
  tagsActions.delete({
    url: `/api/tags/${id}`,
  })

export const unarchiveTag = (id) =>
  tagsActions.update({
    url: `/api/tags/${id}/unarchive`,
    schema: { tag: schemas.tags },
  })
