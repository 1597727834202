import { screenApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const currenciesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'currencies' })
export const fetchCurrencies = () =>
  currenciesActions.fetch({
    url: '/currencies',
    schema: { currencies: [schemas.currencies] },
  })

export const languagesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'languages' })
export const fetchLanguages = () =>
  languagesActions.fetch({
    url: '/languages',
    schema: { languages: [schemas.languages] },
  })

const fetch = {
  currencies: fetchCurrencies,
  languages: fetchLanguages,
}
export function fetchStatics(statics = []) {
  return (dispatch) => Promise.all(statics.map((name) => dispatch(fetch[name]())))
}
