import {
  customBookingActions,
  payoutsActions,
  bookingsActions,
  filesActions,
  playlistsActions,
} from 'redux/entities/actions'
import schemasAndRecordsByEntity from 'redux/entities/schemas'
import { Map, List } from 'immutable'
import { actions as loginActions } from 'redux/actions/login'
import { normalize } from 'normalizr'

export default {
  [loginActions.LOGGED_IN]: (state, { payload }) => {
    const { schema: adminSchema, Record: AdminRecord } = schemasAndRecordsByEntity.admins
    const { schema: userSchema, Record: UserRecord } = schemasAndRecordsByEntity.users
    const { entities, result } = normalize(payload, { admin: adminSchema, user: userSchema })
    const adminRecord = new AdminRecord(entities.admins[result.admin])
    const userRecord = new UserRecord(entities.users[result.user])

    const currentAdminLanguage = state
      .getIn(['entities', 'languages'])
      .filter(({ id }) => id === payload.user.language_id)
      .get(String(payload.user.language_id), new schemasAndRecordsByEntity.languages.Record())

    return state
      .mergeIn(['entities', 'admins'], { [adminRecord.id]: adminRecord })
      .mergeIn(['entities', 'users'], { [userRecord.id]: userRecord })
      .setIn(['entities', 'admins', String(adminRecord.id), 'account', 'language'], currentAdminLanguage)
  },
  [payoutsActions.actionTypes.FETCHING]: (state) => state.setIn(['entities', 'batchedPayouts'], new Map()),
  [bookingsActions.actionTypes.CREATED]: (state, action) => {
    const schedules = state.getIn(['entities', 'schedules'])

    if (!schedules || !schedules.size) return state

    const pendingStatus = ['booking_request', 'request_information', 'schedule', 'payment_tentative', 'tentative']

    const id = action.payload.data.result.booking
    const booking = state
      .getIn(['entities', 'bookings'])
      .get(String(id), new schemasAndRecordsByEntity.bookings.Record())

    const updatedSchedules = schedules.map((schedule) => {
      if (schedule.id === booking.date) {
        const ids = schedule.get('bookings_ids')
        const pendingIds = schedule.get('pending_bookings_ids')
        const newPendingIds = pendingStatus.includes(booking.status)
          ? new List(pendingIds).concat(id)
          : new List(pendingIds)
        return schedule
          .setIn(['bookings_ids'], new List(ids && ids.concat(id)))
          .setIn(['pending_bookings_ids'], newPendingIds)
      }
      return schedule
    })

    return state.setIn(['entities', 'schedules'], new Map(updatedSchedules))
  },
  [filesActions.actionTypes.UPDATED]: (state, { payload }) => {
    const files = payload.data.entities.files

    if (!files) return state

    const updatedEvents = state.getIn(['entities', 'events']).map((event) => {
      const filteredFiles = Object.values(files).map((file) => (file.attachable_id === event.id ? file.id : undefined))
      return filteredFiles ? event.setIn(['files'], new List(filteredFiles)) : event
    })

    return state.setIn(['entities', 'events'], new Map(updatedEvents))
  },
  [customBookingActions.CLEAR_ALL]: (state) => {
    return state.deleteIn(['entities', 'bookings'])
  },
  [playlistsActions.actionTypes.FETCHED]: (state, { payload }) => {
    const results = payload.data.result
    if (!results?.metadata?.nextAllowedOrder) return state
    const playlistMetadata = state.getIn(['metadata', 'playlists'])
    const metadataWithNextOrder = new Map({
      count: playlistMetadata.get('count'),
      order: new List(playlistMetadata.get('order')),
      next_order: results.metadata.nextAllowedOrder,
    })
    return state.setIn(['metadata', 'playlists'], metadataWithNextOrder)
  },
}
