import React, { useState, useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router'
import { Grid, Icon, Accordion, AccordionTitle, AccordionContent, Label } from 'semantic-ui-react'
import { getPartnerDemandRequests, getRequestStatus } from 'redux/entities/selectors'

function RequestRow({ request, numberOfOldRequests }) {
  const { t } = useTranslation()
  const { id, date, event_id: eventId, event, user_id: userId, user, seats } = request
  const requestStatus = useSelector((state) => getRequestStatus(state, { id }))

  return (
    <Grid>
      <Grid.Column width={2}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Link to={`/requests?search=${id}`}>{id}</Link>
          {numberOfOldRequests > 0 && (
            <React.Fragment>
              <Label basic color='blue' horizontal>
                {t('PartnerDemands::Summary::+{{size}} more', { size: numberOfOldRequests })}
                <Icon style={{ margin: '0 0 0 8px' }} name='angle down' />
              </Label>
            </React.Fragment>
          )}
        </div>
      </Grid.Column>
      <Grid.Column width={2}>{date}</Grid.Column>
      <Grid.Column width={3}>
        <Link to={`/events/${eventId}`}>{`${eventId} - ${event.title}`}</Link>
      </Grid.Column>
      <Grid.Column width={3}>
        <Link to={`/users/${event.user_id}`}>
          {`${event.user_id} - ${event.user.firstname} ${event.user.lastname}`}
        </Link>
      </Grid.Column>
      <Grid.Column width={3}>
        <Link to={`/users/${userId}`}>{`${userId} - ${user.firstname} ${user.lastname}`}</Link>
      </Grid.Column>
      <Grid.Column width={1}>{seats}</Grid.Column>
      <Grid.Column width={2}>{requestStatus}</Grid.Column>
    </Grid>
  )
}

RequestRow.propTypes = {
  request: PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    event_id: PropTypes.number,
    event: PropTypes.shape({
      title: PropTypes.string,
      user_id: PropTypes.number,
      user: PropTypes.shape({
        firstname: PropTypes.string,
        lastname: PropTypes.string,
      }),
    }),
    user_id: PropTypes.number,
    user: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
    seats: PropTypes.number,
  }).isRequired,
  numberOfOldRequests: PropTypes.number,
}

RequestRow.defaultProps = {
  numberOfOldRequests: 0,
}

export function DemandRequests({ demand }) {
  const { t } = useTranslation()
  const requests = useSelector((state) => getPartnerDemandRequests(state, { id: demand.id }))
  const [activePanelIndex, setActivePanelIndex] = useState()

  const groupedRequests = useMemo(
    () =>
      Object.values(
        requests.reduce((acc, request) => {
          const hostId = request.event.user_id
          const hostRequests = (acc[hostId] || []).concat(request).sort((a, b) => a.created_at - b.created_at)
          return { ...acc, [hostId]: hostRequests }
        }, {}),
      ),
    [requests],
  )

  function handleAccordionPanel(_, { index }) {
    setActivePanelIndex(index === activePanelIndex ? null : index)
  }

  return (
    <React.Fragment>
      <Accordion fluid styled>
        <AccordionTitle active={false}>
          <Grid>
            <Grid.Column width={2}>{t('PartnerDemands::Summary::Id')}</Grid.Column>
            <Grid.Column width={2}>{t('PartnerDemands::Summary::Date')}</Grid.Column>
            <Grid.Column width={3}>{t('PartnerDemands::Summary::Event')}</Grid.Column>
            <Grid.Column width={3}>{t('PartnerDemands::Summary::Host')}</Grid.Column>
            <Grid.Column width={3}>{t('PartnerDemands::Summary::Guest')}</Grid.Column>
            <Grid.Column width={1}>{t('PartnerDemands::Summary::Seats')}</Grid.Column>
            <Grid.Column width={2}>{t('PartnerDemands::Summary::Status')}</Grid.Column>
          </Grid>
        </AccordionTitle>
      </Accordion>
      {groupedRequests.map(([lastRequest, ...oldRequests], index) => (
        <Accordion key={lastRequest.id} fluid styled style={{ marginTop: '12px' }}>
          <AccordionTitle active={index === activePanelIndex} index={index} onClick={handleAccordionPanel}>
            <RequestRow request={lastRequest} numberOfOldRequests={oldRequests?.length} />
          </AccordionTitle>
          {oldRequests?.length > 0 && (
            <AccordionContent active={index === activePanelIndex}>
              {oldRequests.map((request) => (
                <RequestRow key={request.id} request={request} />
              ))}
            </AccordionContent>
          )}
        </Accordion>
      ))}
    </React.Fragment>
  )
}

DemandRequests.propTypes = {
  demand: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
}
