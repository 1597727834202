import React from 'react'
import { BankAccountListSearchForm } from './BankAccountListSearchForm'
import { BankAccountList } from './BankAccountList'

export default function BankAccountIndex() {
  return (
    <div>
      <BankAccountListSearchForm />
      <BankAccountList />
    </div>
  )
}
