import { schema } from 'normalizr'
import { Record } from 'immutable'

const _schema = new schema.Entity('tags')

const _Record = Record({
  id: undefined,
  title: undefined,
  translated_titles: new Map(),
  description: undefined,
  metadata: new Map(),
  category_id: undefined,
  archived_at: undefined,
})

export const tags = {
  schema: _schema,
  Record: _Record,
}
