import settings from 'settings'
import { useEffect, useMemo } from 'react'
import { usePrevious } from 'hooks/usePrevious'
import { useDispatch, useSelector } from 'react-redux'
import { getLocation } from 'redux/reducers/router'
import { fetchBankAccounts, fetchPlaylists, fetchTags, fetchCategories } from 'redux/entities/actions'
import { omitBy } from 'lodash'
import { isInvalid } from 'helpers/forms'
import { getFullPath } from 'helpers/url'

const ACTIONS = {
  bankAccount: fetchBankAccounts,
  playlist: fetchPlaylists,
  tag: fetchTags,
  categories: fetchCategories,
}

export function useFetchPaginatedResults(entity) {
  const dispatch = useDispatch()
  const location = useSelector(getLocation)

  const currentPath = useMemo(() => getFullPath(location), [location])
  const previousPath = usePrevious(currentPath)
  const hasPathChanged = location.get('search') && previousPath !== currentPath

  useEffect(() => {
    const defaultQuery = { offset: 0, size: settings.defaultPaginationSize }
    const locationQuery = location.get('query')?.toJS()
    const query = omitBy({ ...defaultQuery, ...locationQuery }, isInvalid)
    if (hasPathChanged) dispatch(ACTIONS[entity]({ query }))
  }, [dispatch, entity, hasPathChanged, location])
}
