import React, { useMemo, useCallback } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCategoriesWithTags, fetchingCategories } from 'redux/entities/selectors'
import { Dropdown, Icon, Popup, List } from 'semantic-ui-react'
import { capitalize } from 'lodash'
import { CATEGORY_VISIBILITY_ICONS } from 'helpers/categories'
import './CategoriesDropdown.css'

function PopupContent({ label }) {
  const { t } = useTranslation()

  return (
    <Popup.Content>
      <List>
        <List.Item>
          <List.Header>{t('CategoriesDropdown::Tag')}</List.Header>
          {label.text.replace(`${capitalize(label.category)}: `, '')}
        </List.Item>
        {label.categoryDescription && (
          <List.Item>
            <List.Header>{t('CategoriesDropdown::Description')}</List.Header>
            {label.categoryDescription}
          </List.Item>
        )}
        <List.Item>
          <List.Header>{t('CategoriesDropdown::Visibility')}</List.Header>
          <span>
            {label.visibility} <Icon name={CATEGORY_VISIBILITY_ICONS[label.visibility]} />
          </span>
        </List.Item>
      </List>
    </Popup.Content>
  )
}

PopupContent.propTypes = {
  label: PropTypes.shape({
    categoryDescription: PropTypes.string,
    visibility: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.string,
    category: PropTypes.string,
  }).isRequired,
}

function useDropdownProps({ unsortedSelectedTags }) {
  const categories = useSelector(getCategoriesWithTags)

  const categoriesWithSortedTags = useMemo(
    () =>
      categories
        .toList()
        .sortBy((category) => category.get('name'))
        .map((category) => category.update('tags', (tags) => tags.sortBy((tag) => tag.get('title'))))
        .filter(({ tags }) => tags.size > 0),
    [categories],
  )

  const sortedSelectedTags = useMemo(() => {
    return categoriesWithSortedTags
      .filter(({ tags }) => tags.some((tag) => unsortedSelectedTags.includes(tag.get('title'))))
      .map(({ tags }) => tags.filter((tag) => unsortedSelectedTags.includes(tag.get('title'))))
      .map((tags) => tags.map((tag) => tag.get('title').trim()))
      .toJS()
      .flat()
  }, [categoriesWithSortedTags, unsortedSelectedTags])

  const formatCategorySeparator = useCallback(
    ({ name, color }) => ({
      key: name,
      icon: 'tag',
      text: capitalize(name),
      disabled: true,
      style: { backgroundColor: color },
    }),
    [],
  )

  const formatCategoryOption = useCallback(
    ({ tags, name, color, visibility, description }) =>
      tags.map((tag) => ({
        key: tag.id,
        text: `${capitalize(name)}: ${tag.title}`,
        value: tag.title,
        category: name,
        color,
        visibility,
        categoryDescription: description,
      })),
    [],
  )

  const options = useMemo(
    () =>
      categoriesWithSortedTags
        .toJS()
        .map((category) => {
          const shouldIncludeSeparator = !category.tags.every((tag) => unsortedSelectedTags.includes(tag.title))
          const categoryOptions = formatCategoryOption(category)
          if (shouldIncludeSeparator) categoryOptions.unshift(formatCategorySeparator(category))
          return categoryOptions
        })
        .flat(),
    [categoriesWithSortedTags, formatCategoryOption, formatCategorySeparator, unsortedSelectedTags],
  )

  return { options, sortedSelectedTags }
}

export function CategoriesDropdown({ error, handleChange, value }) {
  const { t } = useTranslation()
  const loading = useSelector(fetchingCategories)
  const { options, sortedSelectedTags } = useDropdownProps({ unsortedSelectedTags: value })

  function fuzzySearch(options, query) {
    const regexString = query.split('').reduce((acc, character) => (acc += character + '.*'), '.*')
    const regularExpression = new RegExp(regexString, 'i')
    return options.filter((option) => option?.text?.match(regularExpression))
  }

  function renderLabels(label) {
    return {
      className: '__categoryDropdownLabel',
      style: {
        padding: '0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      icon: (
        <Popup
          trigger={<Icon name={CATEGORY_VISIBILITY_ICONS[label.visibility]} />}
          content={<PopupContent label={label} />}
          position='bottom center'
        />
      ),
      detail: (
        <Popup
          trigger={<span className='__tag'>{label.text.replace(`${capitalize(label.category)}: `, '')}</span>}
          content={<PopupContent label={label} />}
          position='bottom center'
        />
      ),
      content: (
        <Popup
          trigger={
            <span className='__detail' style={{ backgroundColor: label.color, opacity: 0.8 }}>
              {capitalize(label.category)}
            </span>
          }
          content={<PopupContent label={label} />}
          position='bottom center'
        />
      ),
    }
  }

  return (
    <Dropdown
      multiple
      selection
      fluid
      options={options}
      search={fuzzySearch}
      renderLabel={renderLabels}
      value={sortedSelectedTags}
      onChange={handleChange}
      placeholder={t('Inputs::Select...')}
      error={error}
      loading={loading}
      className='__categoriesDropdown'
    />
  )
}

CategoriesDropdown.propTypes = {
  error: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
}

CategoriesDropdown.defaultProps = {
  error: false,
  value: [],
}
