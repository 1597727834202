import React from 'react'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { Table, Checkbox } from 'semantic-ui-react'
import { Link } from 'react-router'
import { useSelector } from 'react-redux'
import { getUser } from 'redux/entities/selectors'

export function BankAccountRow({ bankAccount, onSelect, selected }) {
  const user = useSelector((state) => getUser(state, { id: bankAccount.user }))
  return (
    <Table.Row>
      <Table.Cell>{bankAccount.id}</Table.Cell>

      <Table.Cell>{bankAccount.type}</Table.Cell>

      <Table.Cell>{bankAccount.country_iso}</Table.Cell>

      <Table.Cell>{moment(bankAccount.created_at).format('lll')}</Table.Cell>

      <Table.Cell>{moment(bankAccount.updated_at).format('lll')}</Table.Cell>

      <Table.Cell>
        <Link to={`/users/${user.id}/bank-accounts`}>{`${user.id} - ${user.firstname} ${user.lastname}`}</Link>
      </Table.Cell>

      <Table.Cell>{bankAccount.organization}</Table.Cell>

      <Table.Cell>{bankAccount.currency}</Table.Cell>

      <Table.Cell>{bankAccount.bank_name}</Table.Cell>

      <Table.Cell>{bankAccount.account_holder_name}</Table.Cell>

      <Table.Cell>{bankAccount.account_number}</Table.Cell>

      <Table.Cell>
        <Checkbox onChange={(_, { checked }) => onSelect(checked, bankAccount.id)} checked={selected} />
      </Table.Cell>
    </Table.Row>
  )
}

BankAccountRow.propTypes = {
  bankAccount: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    country_iso: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.number,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
    currency: PropTypes.string,
    organization: PropTypes.string,
    bank_name: PropTypes.string,
    account_holder_name: PropTypes.string,
    account_number: PropTypes.string,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
}
