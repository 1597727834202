import normalizeURL from 'normalize-url'
import qs from 'query-string'
import moment from 'moment'
import { MAP_SUBPATHS_TO_LANGUAGES, SOCIAL_NETWORK_URLS } from './konstants.js'
import { queryToString } from './queryUtils.js'

export type SocialName = 'facebook' | 'twitter' | 'instagram' | 'wechat' | 'linkedin' | 'tiktok'

// Unfortunately due to Edge & IE9 not supporting fully SearchParams and specifically the sort option
// But polyfills not implementing it because some other browser once shipped without it, it is basically not safe to use
// Also Edge complains if we touch user & password so that's why stripAuthentication needs to be false
const cleanURL = (url: string): string =>
  normalizeURL(url, {
    removeQueryParameters: undefined, // Otherwise it removes all tracking queries with utm_...
    stripWWW: false,
    stripAuthentication: false,
    sortQueryParameters: false,
  })

export function vizeatUrlsFactory({
  config,
}: {
  config: { env: string; url: { host: string; shortHost?: string; subdomains?: Record<string, string> } }
}): {
  static: {
    scheme: string
    host: string
    subdomains: {
      default: string
      en: string
      fr: string
      es: string
      it: string
      zh: string
      'zh-cn': string
      'zh-CN': string
      'zh-tw': string
      'zh-TW': string
      he: string
      screen: string
      business: string
      public: string
      static: string
      blog: string
      kitchen: string
      worker: string
      dashboard: string
      cw: string
    } & Record<string, string>
    canonical: string
    file: (path: string) => string
  }
  cfw: {
    scheme: string
    host: string
    subdomains: {
      default: string
      en: string
      fr: string
      es: string
      it: string
      zh: string
      'zh-cn': string
      'zh-CN': string
      'zh-tw': string
      'zh-TW': string
      he: string
      screen: string
      business: string
      public: string
      static: string
      blog: string
      kitchen: string
      worker: string
      dashboard: string
      cw: string
    } & Record<string, string>
    canonical: string
    geo: {
      place: (lang?: string, query?: Record<string, string>) => string
      autocomplete: (lang?: string, query?: Record<string, string>) => string
      geocode: (lang?: string, query?: Record<string, string>) => string
      reverse: (lang?: string, query?: Record<string, string>) => string
    }
    proxy: { stadiamaps: () => string }
    rates: { index: () => string; get: (base: string) => string }
  }
  web: {
    scheme: string
    host: string
    subdomains: {
      default: string
      en: string
      fr: string
      es: string
      it: string
      zh: string
      'zh-cn': string
      'zh-CN': string
      'zh-tw': string
      'zh-TW': string
      he: string
      screen: string
      business: string
      public: string
      static: string
      blog: string
      kitchen: string
      worker: string
      dashboard: string
      cw: string
    } & Record<string, string>
    canonical: (lang: string) => string
    home: (lang?: string, query?: Record<string, string>) => string
    homeOnlineExperiences: (lang: string, query: Record<string, string>) => string
    howItWorks: (lang?: string) => string
    search: (query: Record<string, string>, lang?: string) => string
    shorten: (hash: string) => string
    pages: { photoTutorial: (lang: string) => string }
    events: {
      get: (id: number, lang?: string, query?: Record<string, string>) => string
      book: (id: number, query: Record<string, string>, lang: string) => string
      invite: (id: number, token: string, lang: string) => string
    }
    users: {
      get: (id: number, lang?: string) => string
      widget: (lang: string, userId: number, currency: string, eventIds: number[]) => string
    }
    wishlists: { get: (id: number, lang: string, query?: Record<string, string>) => string }
  }
  dashboard: {
    scheme: string
    host: string
    subdomains: {
      default: string
      en: string
      fr: string
      es: string
      it: string
      zh: string
      'zh-cn': string
      'zh-CN': string
      'zh-tw': string
      'zh-TW': string
      he: string
      screen: string
      business: string
      public: string
      static: string
      blog: string
      kitchen: string
      worker: string
      dashboard: string
      cw: string
    } & Record<string, string>
    canonical: string
    forgottenPassword: () => string
    changePassword: (token: string) => string
    planning: { index: () => string; eventAtDate: (id: number, date: string) => string }
    events: {
      index: () => string
      new: () => string
      edit: (id: number) => string
      editProfile: (id: number) => string
      editPhoto: (id: number) => string
      editPlace: (id: number) => string
    }
    users: {
      profile: () => string
      reviews: { index: () => string; get: (type: string) => string }
      payoutPreferences: (query?: Record<string, string>) => string
      referral: (query?: Record<string, string>) => string
      security: () => string
    }
    inbox: { index: () => string; get: (id: number) => string }
    bookings: { index: (query?: Record<string, string | boolean>) => string }
    paymentHistory: () => string
    widget: () => string
    wishlists: () => string
    hostApplication: { resume: (query?: Record<string, string>) => string }
  }
  public: {
    scheme: string
    host: string
    subdomains: {
      default: string
      en: string
      fr: string
      es: string
      it: string
      zh: string
      'zh-cn': string
      'zh-CN': string
      'zh-tw': string
      'zh-TW': string
      he: string
      screen: string
      business: string
      public: string
      static: string
      blog: string
      kitchen: string
      worker: string
      dashboard: string
      cw: string
    } & Record<string, string>
    canonical: string
  }
  screen: {
    scheme: string
    host: string
    subdomains: {
      default: string
      en: string
      fr: string
      es: string
      it: string
      zh: string
      'zh-cn': string
      'zh-CN': string
      'zh-tw': string
      'zh-TW': string
      he: string
      screen: string
      business: string
      public: string
      static: string
      blog: string
      kitchen: string
      worker: string
      dashboard: string
      cw: string
    } & Record<string, string>
    canonical: string
    shorten: (hash: string) => string
    files: { get: (id: number) => string; transform: (id: number, ucarePath: string) => string }
    rss: { get: (ressource: string) => string }
    qrCode: { get: (query: Record<string, string>) => string }
  }
  business: {
    scheme: string
    host: string
    subdomains: {
      default: string
      en: string
      fr: string
      es: string
      it: string
      zh: string
      'zh-cn': string
      'zh-CN': string
      'zh-tw': string
      'zh-TW': string
      he: string
      screen: string
      business: string
      public: string
      static: string
      blog: string
      kitchen: string
      worker: string
      dashboard: string
      cw: string
    } & Record<string, string>
    canonical: string
    authToken: (accountId: number) => string
    user: (id: number) => string
    users: (query: Record<string, string>) => string
    usersCount: () => string
    event: (id: number) => string
    events: (query: Record<string, string>) => string
    eventsCount: () => string
    booking: (id: number) => string
    bookings: (query: Record<string, string>) => string
    searchBookings: (query: Record<string, string>) => string
    bookingsCount: () => string
  }
  kitchen: {
    scheme: string
    host: string
    subdomains: {
      default: string
      en: string
      fr: string
      es: string
      it: string
      zh: string
      'zh-cn': string
      'zh-CN': string
      'zh-tw': string
      'zh-TW': string
      he: string
      screen: string
      business: string
      public: string
      static: string
      blog: string
      kitchen: string
      worker: string
      dashboard: string
      cw: string
    } & Record<string, string>
    canonical: string
    user: { get: (id: number) => string }
    booking: { get: (id: number) => string }
    request: { get: (id: number) => string }
    event: { get: (id: number) => string }
    review: { get: (id: number) => string }
  }
  socialNetworks: {
    get: (socialName: SocialName, country: string) => string
  }
} {
  const scheme = ['production', 'staging', 'preview'].indexOf(config.env) >= 0 ? 'https' : 'http'
  const host = config.url.host || 'eatwith.com'
  const shortHost = config.url.shortHost || 'eat.lc'
  const subdomains = Object.assign(
    {
      default: '',
      en: '',
      fr: 'fr',
      es: 'es',
      it: 'it',
      zh: 'zh-CN',
      'zh-cn': 'zh-CN',
      'zh-CN': 'zh-CN',
      'zh-tw': 'zh-TW',
      'zh-TW': 'zh-TW',
      he: 'he',
      screen: 'screen-api',
      business: 'business-api',
      public: 'public-api',
      static: 'static',
      blog: 'blog',
      kitchen: 'kitchen',
      worker: 'worker',
      dashboard: 'my',
      cw: 'cw',
    },
    config.url.subdomains,
  )

  const getSocialNetworks = (name: SocialName, country: string) => {
    const key = country.toLocaleLowerCase()
    return SOCIAL_NETWORK_URLS[name][key] || SOCIAL_NETWORK_URLS[name].default
  }

  /**
   * Parse and validate that the id is a positive integer
   * It will throw an exception if not.
   * @param  {Number|String} id the identifier
   * @return {Number}           the parsed identifier
   */
  const validId = (id: number | string): number => {
    const int = Number(id)
    if (!int) throw new Error('Wrong type for id parameter, expected integer.')
    return int
  }

  /**
   * Format query string
   * @param  {String|Object} input return the input as-is if it's a string, stringify the object passed otherwise
   * @return {String}              the string part of the URI
   */
  const formatQuery = (input) => {
    return typeof input === 'string' ? input : qs.stringify(input)
  }

  /**
   * Return the subdomain corresponding to the source and the language
   * @param  {String} source      Any of web, business, screen, public, kitchen
   * @return {String}             The matching subdomain or the default one if none matched
   * @example
   *   getSubdomain('web') => ''
   *   getSubdomain('business') => business-api
   */
  const getSubdomain = (source) => (source === 'web' ? subdomains.default : subdomains[source]) || subdomains.default

  /**
   * Return the base URI corresponding to the source and the language / the subdomain passed
   * If subdomain is given it will be used, otherwise, the source will be used in conjonction with the lang if it is passed
   * @param  {String} source    Any of web, business, screen, public, kitchen
   * @param  {String} lang      Any of the supported languages
   * @param  {String} subdomain A predetermined subdomain
   * @return {String}           The base URI corresponding
   */
  const getBaseURI = ({ source, subdomain = getSubdomain(source) }: { source: string; subdomain?: string }): string => {
    return cleanURL(`${scheme}://${subdomain ? `${subdomain}.` : ''}${host}`)
  }

  /**
   * Return the full formatted URI
   * @param  {String} source              Any of web, business, screen, public, kitchen
   * @param  {String} lang                Any of the supported languages
   * @param  {String} subdomain           A predetermined subdomain
   * @param  {String} [path='']           The path part of the URI
   * @param  {String|Object} [query=''] The query part of the URI (will be stringified if passed as object)
   * @return {String}                     The complete URI
   */
  const getFullURI = ({
    source,
    lang,
    subdomain,
    path = '',
    query = '',
  }: {
    source: string
    lang: string
    subdomain?: string
    path?: string
    query?: string
  }) => {
    const localeSubPath = (source === 'web' && lang !== 'en' && MAP_SUBPATHS_TO_LANGUAGES[lang] && `/${lang}`) || ''
    const pathname = path === '' ? localeSubPath : `${localeSubPath}/${path}`
    return cleanURL(`${getBaseURI({ source, subdomain })}${pathname}${query === '' ? '' : `?${formatQuery(query)}`}`)
  }

  /**
   * Return the full formatted shorten URI
   * @param  {String} source              Any of web, business, screen, public, kitchen
   * @param  {String} lang                Any of the supported languages
   * @param  {String} [path='']           The path part of the URI
   * @param  {String|Object} [query=''] The query part of the URI (will be stringified if passed as object)
   * @return {String}                     The complete URI
   */
  const getShortenURI = ({ path = '' }) => {
    return cleanURL(`${scheme}://${shortHost}/${path}`)
  }

  /**
   * Return the full formatted URI for a ressource
   * @param  {String} source    Any of web, business, screen, public, kitchen
   * @param  {String} ressource A primary ressource to look for (events, users, bookings...)
   * @param  {String} lang      Any of the supported languages
   * @param  {Number} id        The ressource identifier
   * @return {String}           The complete URI
   */
  const getRessourceURI = ({
    source,
    ressource,
    lang,
    id,
  }: {
    source: string
    ressource: string
    lang: string
    id: number
  }): string => {
    let uri
    switch (source) {
      case 'business':
        uri = getFullURI({ source, lang, path: `api/${ressource}/${validId(id)}` })
        break
      case 'kitchen':
        uri = getFullURI({ source, lang, path: ressource, query: `search=${validId(id)}` })
        break
      case 'screen':
      case 'web':
      case 'public':
      default:
        uri = getFullURI({ source, lang, path: `${ressource}/${validId(id)}` })
    }
    return uri
  }

  // Shortcut for source specific functions
  const getWebRessourceURI = (args) => getRessourceURI(Object.assign(args, { source: 'web' }))
  const getScreenRessourceURI = (args) => getRessourceURI(Object.assign(args, { source: 'screen' })) // eslint-disable-line no-unused-vars
  const getBusinessRessourceURI = (args) => getRessourceURI(Object.assign(args, { source: 'business' }))
  // const getPublicRessourceURI = (args) => getRessourceURI(Object.assign(args, { source: 'public' })) // eslint-disable-line no-unused-vars
  // const getKitchenRessourceURI = (args) => getRessourceURI(Object.assign(args, { source: 'kitchen' })) // eslint-disable-line no-unused-vars
  const getWebURI = (args) => getFullURI(Object.assign(args, { source: 'web' }))
  const getScreenURI = (args) => getFullURI(Object.assign(args, { source: 'screen' })) // eslint-disable-line no-unused-vars
  const getBusinessURI = (args) => getFullURI(Object.assign(args, { source: 'business' }))
  // const getPublicURI = (args) => getFullURI(Object.assign(args, { source: 'public' })) // eslint-disable-line no-unused-vars
  const getKitchenURI = (args) => getFullURI(Object.assign(args, { source: 'kitchen' })) // eslint-disable-line no-unused-vars
  const getStaticURI = (args) => getFullURI(Object.assign(args, { source: 'static' }))
  const getDashboardURI = (args) => getFullURI(Object.assign(args, { source: 'dashboard' }))
  const getCFWorkersURI = (args) => getFullURI(Object.assign(args, { source: 'cw' }))

  return {
    static: {
      scheme,
      host,
      subdomains,
      canonical: getBaseURI({ source: 'static' }),
      file: (path: string) => getStaticURI({ path }),
    },
    cfw: {
      scheme,
      host,
      subdomains,
      canonical: getBaseURI({ source: 'cw' }),
      geo: {
        place: (lang?: string, query?: Record<string, string>) => getCFWorkersURI({ path: 'geo/place', query, lang }),
        autocomplete: (lang?: string, query?: Record<string, string>) =>
          getCFWorkersURI({ path: 'geo/autocomplete', query, lang }),
        geocode: (lang?: string, query?: Record<string, string>) =>
          getCFWorkersURI({ path: 'geo/geocode', query, lang }),
        reverse: (lang?: string, query?: Record<string, string>) =>
          getCFWorkersURI({ path: 'geo/reverse', query, lang }),
      },
      rates: {
        index: () => getCFWorkersURI({ path: `exchange-rates` }),
        get: (base: string) => getCFWorkersURI({ path: `exchange-rates/${base}` }),
      },
      proxy: {
        stadiamaps: () => getCFWorkersURI({ path: 'proxy/stadiamaps' }),
      },
    },
    web: {
      scheme,
      host,
      subdomains,
      canonical: (lang: string) => getWebURI({ subdomain: 'www', lang }),
      home: (lang?: string, query?: Record<string, string>) => getWebURI({ lang, query }),
      homeOnlineExperiences: (lang, query) => getWebURI({ lang, query, path: '/online' }),
      howItWorks: (lang?: string) => getWebURI({ lang, path: '/how-it-works' }),
      search: (query, lang?: string) => getWebURI({ lang, query, path: 'search' }),
      shorten: (hash: string) => getShortenURI({ path: `${config.env === 'production' ? 's' : 'shorten'}/${hash}` }),
      pages: {
        photoTutorial: (lang: string) => getWebURI({ lang, path: 'pages/photo-tutorial' }),
      },
      events: {
        get: (id: number, lang?: string, query: Record<string, string> = {}) =>
          getWebURI({ lang, path: `events/${validId(id)}`, query: queryToString(query) }),
        book: (id: number, query: Record<string, string>, lang: string) =>
          getWebURI({ lang, path: `events/${validId(id)}/book`, query: queryToString(query) }),
        invite: (id: number, token: string, lang: string) =>
          getWebURI({ lang, path: `events/${validId(id)}`, query: queryToString({ token }) }),
      },
      users: {
        get: (id: number, lang?: string) => getWebRessourceURI({ lang, id, ressource: 'users' }),
        widget: (lang: string, userId: number, currency: string, eventIds: number[]) =>
          getWebURI({
            lang,
            path: 'widget',
            query: queryToString({ userId, currency, eventIds, language: lang || 'en' }),
          }),
      },
      wishlists: {
        get: (id: number, lang: string, query?: Record<string, string>) =>
          getWebURI({ lang, path: `wishlists/${validId(id)}`, query: queryToString(query || {}) }),
      },
    },
    dashboard: {
      scheme,
      host,
      subdomains,
      canonical: getBaseURI({ source: 'dashboard' }),
      forgottenPassword: () => getDashboardURI({ path: 'forgotten-password' }),
      changePassword: (token: string) => getDashboardURI({ path: `change-password/${token}` }),
      planning: {
        index: () => getDashboardURI({ path: 'dashboard/planning' }),
        eventAtDate: (id: number, date: string) => {
          const mDate = moment.utc(date)
          return getDashboardURI({
            path: `dashboard/planning/${mDate.year()}/${mDate.month() + 1}/${mDate.date()}/event/${validId(id)}`,
          })
        },
      },
      events: {
        index: () => getDashboardURI({ path: 'dashboard/my-meals' }),
        new: () => getDashboardURI({ path: 'dashboard/my-meals/create' }),
        edit: (id: number) => getDashboardURI({ path: `dashboard/my-meals/edit/${validId(id)}` }),
        editProfile: (id: number) => getDashboardURI({ path: `dashboard/my-meals/edit/${validId(id)}/profile` }),
        editPhoto: (id: number) => getDashboardURI({ path: `dashboard/my-meals/edit/${validId(id)}/photos` }),
        editPlace: (id: number) =>
          getDashboardURI({ path: `dashboard/my-meals/edit/${validId(id)}/place-and-amenities` }),
      },
      users: {
        profile: () => getDashboardURI({ path: 'dashboard/profile/personal-info' }),
        reviews: {
          index: () => getDashboardURI({ path: 'dashboard/profile/reviews' }),
          get: (type: string) => getDashboardURI({ path: `dashboard/profile/reviews/${type}` }),
        },
        payoutPreferences: (query) => getDashboardURI({ path: 'dashboard/account/payout-preferences', query }),
        referral: (query) => getDashboardURI({ path: 'dashboard/referral', query }),
        security: () => getDashboardURI({ path: 'dashboard/account/security' }),
      },
      inbox: {
        index: () => getDashboardURI({ path: 'dashboard/inbox' }),
        get: (id: number) => getDashboardURI({ path: `dashboard/inbox/${validId(id)}` }),
      },
      bookings: {
        index: (query) => getDashboardURI({ path: 'dashboard/bookings', query }),
      },
      paymentHistory: () => getDashboardURI({ path: 'dashboard/payment-history' }),
      widget: () => getDashboardURI({ path: 'dashboard/widget' }),
      wishlists: () => getDashboardURI({ path: 'dashboard/wishlists' }),
      hostApplication: {
        resume: (query = { resume: 'true' }) => getDashboardURI({ path: 'host-application', query }),
      },
    },
    public: {
      scheme,
      host,
      subdomains,
      canonical: getBaseURI({ source: 'public' }),
    },
    screen: {
      scheme,
      host,
      subdomains,
      canonical: getBaseURI({ source: 'screen' }),
      shorten: (hash: string) => getScreenURI({ path: `shorten/${hash}` }),
      files: {
        get: (id: number) => getScreenRessourceURI({ ressource: 'files', id }),
        transform: (id: number, ucarePath: string) => getScreenURI({ path: `files/${validId(id)}${ucarePath}` }) + '/',
      },
      rss: {
        get: (ressource: string) => getScreenURI({ path: `rss/${ressource}` }),
      },
      qrCode: {
        get: (query: Record<string, string>) => getScreenURI({ path: 'qr-code', query }),
      },
    },
    business: {
      scheme,
      host,
      subdomains,
      canonical: getBaseURI({ source: 'business' }),
      authToken: (accountId: number) => getBusinessURI({ path: `api/accounts/${accountId}/token/` }),
      user: (id: number) => getBusinessRessourceURI({ ressource: 'users', id }),
      users: (query: Record<string, string>) => getBusinessURI({ path: 'api/users', query }),
      usersCount: () => getBusinessURI({ path: 'api/users/count' }),
      event: (id: number) => getBusinessRessourceURI({ ressource: 'events', id }),
      events: (query: Record<string, string>) => getBusinessURI({ path: 'api/events', query }),
      eventsCount: () => getBusinessURI({ path: 'api/events/count' }),
      booking: (id: number) => getBusinessRessourceURI({ ressource: 'bookings', id }),
      bookings: (query: Record<string, string>) => getBusinessURI({ path: 'api/bookings', query }),
      searchBookings: (query: Record<string, string>) => getBusinessURI({ path: 'api/bookings/search', query }),
      bookingsCount: () => getBusinessURI({ path: 'api/bookings/count' }),
    },
    kitchen: {
      scheme,
      host,
      subdomains,
      canonical: getBaseURI({ source: 'kitchen' }),
      user: {
        get: (id: number) => getKitchenURI({ path: `users/${id}` }),
      },
      booking: {
        get: (id: number) => getKitchenURI({ path: `bookings/${id}` }),
      },
      request: {
        get: (id: number) => getKitchenURI({ path: `requests/${id}` }),
      },
      event: {
        get: (id: number) => getKitchenURI({ path: `events/${id}` }),
      },
      review: {
        get: (id: number) => getKitchenURI({ path: `reviews/${id}` }),
      },
    },
    socialNetworks: {
      get: (name: SocialName, country) => getSocialNetworks(name, country),
    },
  }
}
