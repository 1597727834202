// Libs
import React, { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
// Components
import { Menu, Dropdown, Button } from 'semantic-ui-react'
import { Link } from 'react-router'
// Redux
import { loginActions } from 'redux/actions'
import { getLanguages, getBankAccountsViewPermissionError } from 'redux/entities/selectors'
import { getAppLanguage } from 'redux/reducers/appSettings'
import { getAdminProfile } from 'redux/reducers/admin/selectors'
import { setAppLocale, updateAccountLanguage } from 'redux/entities/actions'
// helpers
import { getTitleFromLocale } from 'helpers/languages'
// style
import './Default.css'

export default function DefaultHeader() {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const pathname = useSelector((state) => state.getIn(['routing', 'locationBeforeTransitions', 'pathname']))
  const language = useSelector(getAppLanguage)
  const languages = useSelector(getLanguages)
    .toList()
    .filter((language) => language.translated && language.activated)
  const adminProfile = useSelector(getAdminProfile)
  const bankAccountsViewPermissionError = useSelector(getBankAccountsViewPermissionError)

  async function handleSelectLanguage(languageId, locale) {
    await dispatch(setAppLocale(locale))
    if (adminProfile.id) await dispatch(updateAccountLanguage(adminProfile.account_id, languageId))
  }

  return (
    <Fragment>
      <Menu pointing borderless className='PrimaryMenu'>
        <Menu.Item as={Link} header to='/' active={pathname === '/'}>
          {t('Layout::Eatwith Community')}
        </Menu.Item>
        <Menu.Item position='right'>
          <Dropdown item text={language.locale}>
            <Dropdown.Menu>
              {languages
                .filter(({ locale }) => i18n.options.whitelist.includes(locale))
                .map(({ id, locale }) => (
                  <Dropdown.Item key={id} onClick={() => handleSelectLanguage(id, locale)}>
                    {getTitleFromLocale(locale)}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
          <Button
            content={t('Layout::Logout')}
            icon='power'
            labelPosition='left'
            basic
            onClick={() => dispatch(loginActions.logout)}
          />
        </Menu.Item>
      </Menu>
      <Menu pointing secondary borderless className='SecondaryMenu'>
        <Menu.Item as={Link} to={{ pathname: '/users' }} active={pathname.startsWith('/users')}>
          {t('Layout::Users')}
        </Menu.Item>
        <Menu.Item as={Link} to={{ pathname: '/requests' }} active={pathname.startsWith('/requests')}>
          {t('Layout::Requests')}
        </Menu.Item>
        <Menu.Item as={Link} to={{ pathname: '/bookings' }} active={pathname.startsWith('/bookings')}>
          {t('Layout::Bookings')}
        </Menu.Item>
        <Dropdown item text={t('Layout::Experiences')}>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={{ pathname: '/events' }} active={pathname.startsWith('/events')}>
              {t('Layout::Experiences')}
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={{ pathname: '/hosts-applications' }}
              active={pathname.startsWith('/hosts-applications')}
            >
              {t('Layout::Experiences to review')}
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={{ pathname: '/host-applications' }}
              active={pathname.startsWith('/host-applications')}
            >
              {t('Layout::Host Applications')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item as={Link} to={{ pathname: '/calendar-events' }} active={pathname.startsWith('/calendar-events')}>
          {t('Layout::Events calendar')}
        </Menu.Item>
        <Menu.Item as={Link} to={{ pathname: '/conversations' }} active={pathname.startsWith('/conversations')}>
          {t('Layout::Conversations')}
        </Menu.Item>
        <Menu.Item as={Link} to={{ pathname: '/offline-reviews' }} active={pathname.startsWith('/offline-reviews')}>
          {t('Layout::Offline reviews')}
        </Menu.Item>
        <Menu.Item as={Link} to={{ pathname: '/reviews' }} active={pathname.startsWith('/reviews')}>
          {t('Layout::Reviews')}
        </Menu.Item>
        <Menu.Item as={Link} to={{ pathname: '/playlists' }} active={pathname.startsWith('/playlists')}>
          {t('Layout::Playlists')}
        </Menu.Item>
        <Menu.Item as={Link} to={{ pathname: '/organizations' }} active={pathname.startsWith('/organizations')}>
          {t('Layout::Organizations')}
        </Menu.Item>
        <Dropdown item text={t('Layout::Partners')}>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={{ pathname: '/partners' }} active={pathname.startsWith('/partners')}>
              {t('Layout::Partners')}
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={{ pathname: '/partner-demands' }}
              active={pathname.startsWith('/partner-demands')}
            >
              {t('Layout::Partner Demands')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item as={Link} to={{ pathname: '/campaigns' }} active={pathname.startsWith('/campaigns')}>
          {t('Layout::Campaigns')}
        </Menu.Item>
        <Dropdown item text={t('Layout::Categories')}>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={{ pathname: '/categories' }} active={pathname.startsWith('/categories')}>
              {t('Layout::Categories')}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={{ pathname: '/tags' }} active={pathname.startsWith('/tags')}>
              {t('Layout::Tags')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item as={Link} to={{ pathname: '/payouts' }} active={pathname.startsWith('/payouts')}>
          {t('Layout::Payouts')}
        </Menu.Item>
        <Menu.Item as={Link} to={{ pathname: '/gallery' }} active={pathname.startsWith('/gallery')}>
          {t('Layout::Gallery')}
        </Menu.Item>
        {!bankAccountsViewPermissionError && (
          <Menu.Item as={Link} to={{ pathname: '/bank-accounts' }} active={pathname.startsWith('/bank-accounts')}>
            {t('Layout::Bank Accounts')}
          </Menu.Item>
        )}
      </Menu>
    </Fragment>
  )
}
