import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { Popup, Icon } from 'semantic-ui-react'

export function Tooltip({ description, visibility }) {
  const { t } = useTranslation()

  if (!description) return null

  return (
    <Popup
      trigger={<Icon name='info' size='small' circular style={{ cursor: 'pointer' }} />}
      content={
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: '8px' }}>
          <span>{t('Tooltip::Description: {{description}}', { description })}</span>
          {visibility && <span>{t('Tooltip::Visibility: {{visibility}}', { visibility })}</span>}
        </div>
      }
      position='top left'
    />
  )
}

Tooltip.propTypes = {
  description: PropTypes.string,
  visibility: PropTypes.string,
}

Tooltip.defaultProps = {
  description: undefined,
  visibility: undefined,
}
