// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import { connect } from 'react-redux'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
// Components
import DatePicker from 'react-datepicker'
import { Form, Button } from 'semantic-ui-react'
import { InputExpandingOnFocus } from 'layouts'
import { SearchInput, SelectInput } from 'components/inputs'
import { CategoriesDropdown } from 'components/forms/categories/CategoriesDropdown'
// Selectors
import { getLocation } from 'redux/reducers/router'
// Redux
import { getAdmins, getOrganizationsList } from 'redux/entities/selectors'
import { fetchOrganizations } from 'redux/entities/actions'
// Helpers
import { getFormattedAdmins } from 'helpers/admins'
import { getTranslatedOptions } from 'helpers/options'
import { statusOptions } from 'helpers/partnerDemands'

const mapStateToProps = (state) => ({
  fromStore: {
    admins: getAdmins(state),
    location: getLocation(state),
    organizations: getOrganizationsList(state),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchOrganizations: () => dispatch(fetchOrganizations({ query: { all: true } })),
  },
})

const getSeatsBookedOptions = (t) => [
  { text: t('PartnerDemands::All'), value: '' },
  { text: t('PartnerDemands::Complete'), value: 'complete' },
  { text: t('PartnerDemands::Partial'), value: 'partial' },
  { text: t('PartnerDemands::Empty'), value: 'empty' },
]

class _PartnerDemandSearchForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      fetchOrganizations: PropTypes.func.isRequired,
    }).isRequired,
    fromStore: PropTypes.shape({
      admins: PropTypes.immutable.map.isRequired,
      location: PropTypes.immutable.map.isRequired,
      organizations: PropTypes.immutable.list,
    }).isRequired,
  }

  static defaultProps = {
    organizations: undefined,
  }

  static contextTypes = { router: PropTypes.object }

  state = {}

  tagsOpts = []

  componentDidMount() {
    this.props.actions.fetchOrganizations()
    return this.updateStateFromLocation()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.fromStore.location.equals(this.props.fromStore.location)) {
      return this.updateStateFromLocation()
    }
  }

  updateStateFromLocation = () => {
    const { location } = this.props.fromStore
    return this.setState({
      location: location.getIn(['query', 'location'], ''),
      partner: location.getIn(['query', 'partner'], ''),
      organization_ids: location.getIn(['query', 'organization_ids'], ''),
      reference: location.getIn(['query', 'reference'], ''),
      status: location.getIn(['query', 'status'], ''),
      seats_booked: location.getIn(['query', 'seats_booked'], ''),
      tags: location.getIn(['query', 'tags'], []),
    })
  }

  updateQuery = () => {
    this.context.router.push(this.props.fromStore.location.mergeIn(['query'], { ...this.state, offset: 0 }).toJS())
  }

  clearSorting = () => {
    return this.context.router.push(this.props.fromStore.location.mergeIn(['query'], { sortBy: '', order: '' }).toJS())
  }

  clearFilters = () => {
    return this.setState(
      {
        fromdate: '',
        location: '',
        organization_ids: [],
        partner: '',
        reference: '',
        seats_booked: '',
        status: '',
        todate: '',
        assignment: '',
        tags: [],
      },
      () => this.updateQuery({ ...this.state }),
    )
  }

  updateFromDate = (date) => {
    const fromdate = moment(date).format('YYYY-MM-DD')
    this.setState({ fromdate })
    if (!this.state.todate || this.state.todate < fromdate) {
      this.setState({ todate: fromdate })
    }
  }

  updateToDate = (date) => {
    const todate = moment(date).format('YYYY-MM-DD')
    this.setState({ todate })
    if (!this.state.fromdate || this.state.fromdate > todate) {
      this.setState({ fromdate: todate })
    }
  }

  search = (e) => {
    e.preventDefault()
    return this.updateQuery()
  }

  formatOrganizationsOptions = () =>
    this.props.fromStore.organizations.map((org) => ({ text: org.name, value: org.id.toString() }))

  render() {
    const { t } = this.props
    return (
      <Form onSubmit={this.search} autocomplete='off'>
        <Form.Group>
          <InputExpandingOnFocus
            label={t('PartnerDemands::Organizations')}
            blurWidth={120}
            Component={SelectInput}
            queryName='organization_ids'
            options={this.formatOrganizationsOptions().toArray()}
            multiple
            fluid
            search
            value={this.state.organization_ids}
          />

          <Form.Field>
            <label>{t('PartnerDemands::Organization Tags')}</label>
            <CategoriesDropdown
              handleChange={(_, { value: tags }) => this.setState({ tags })}
              value={this.state.tags}
            />
          </Form.Field>

          <InputExpandingOnFocus
            label={t('PartnerDemands::Partner')}
            blurWidth={160}
            Component={SearchInput}
            queryName='partner'
            placeholder={t('PartnerDemands::Name / Organization / Email')}
            value={this.state.partner}
            onChange={(value) => this.setState({ partner: value })}
          />

          <InputExpandingOnFocus
            label={t('PartnerDemands::Reference')}
            blurWidth={160}
            Component={SearchInput}
            placeholder={t('PartnerDemands::Reference')}
            queryName='reference'
            value={this.state.reference}
            onChange={(value) => this.setState({ reference: value })}
          />

          <InputExpandingOnFocus
            label={t('PartnerDemands::Location')}
            blurWidth={160}
            Component={SearchInput}
            queryName='location'
            placeholder={t('PartnerDemands::Address, city, country (iso), postal code')}
            icon='search'
            iconPosition='left'
            value={this.state.location}
            onChange={(value) => this.setState({ location: value })}
          />
        </Form.Group>

        <Form.Group>
          <InputExpandingOnFocus
            label={t('PartnerDemands::Status')}
            blurWidth={80}
            Component={SelectInput}
            queryName='status'
            options={getTranslatedOptions(t, statusOptions)}
            fluid
            search
            multiple
            value={this.state.status}
          />

          <InputExpandingOnFocus
            label={t('PartnerDemands::Seats booked')}
            blurWidth={80}
            Component={SelectInput}
            queryName='seats_booked'
            options={getSeatsBookedOptions(t)}
            fluid
            value={this.state.seats_booked}
          />
          <Form.Field>
            <label>{t('PartnerDemands::Between')}</label>
            <DatePicker
              name='fromdate'
              todayButton={t('PartnerDemands::Today')}
              selected={this.state.fromdate && moment(this.state.fromdate).isValid() ? moment(this.state.fromdate) : ''}
              onChange={this.updateFromDate}
              placeholderText={t('PartnerDemands::start date')}
              dateFormat='DD/MM/YYYY'
            />
          </Form.Field>

          <Form.Field>
            <label>{t('PartnerDemands::And')}</label>
            <DatePicker
              name='todate'
              todayButton={t('PartnerDemands::Today')}
              selected={this.state.todate && moment(this.state.todate).isValid() ? moment(this.state.todate) : ''}
              onChange={this.updateToDate}
              placeholderText={t('PartnerDemands::end date')}
              dateFormat='DD/MM/YYYY'
            />
          </Form.Field>
          <Form.Field>
            <label>{t('PartnerDemands::Assignment')}</label>
            <SelectInput
              queryName='assignment'
              fluid
              search
              options={getFormattedAdmins(this.props.fromStore.admins)}
              value={this.state.assignment}
            />
          </Form.Field>

          <Form.Field>
            <label>&nbsp;</label>
            <Button onClick={this.search}>{t('PartnerDemands::Search')}</Button>
            <Button onClick={this.clearFilters}>{t('PartnerDemands::Clear filters')}</Button>
            <Button onClick={this.clearSorting}>{t('PartnerDemands::Clear sorting')}</Button>
          </Form.Field>
        </Form.Group>
      </Form>
    )
  }
}

export const PartnerDemandSearchForm = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_PartnerDemandSearchForm),
)
