// libs
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import settings from 'settings'
import { withTranslation } from 'react-i18next'
// helpers
import PropTypes from 'helpers/proptypes'
import { getTranslatedOptions } from 'helpers/options'
import { scaleCropFileUrl, fileFormatOptions, fileCategoryOptions } from 'helpers/files'
// components
import { Card, Form, Button, Dropdown, Popup, Image } from 'semantic-ui-react'
import { IntersectionObserver } from '@vizeat/components/es6/components/IntersectionObserver'
// redux
import { mergeInForm } from 'redux/forms/actions'
import { getForm } from 'redux/forms/reducer'
import { getUserGallery } from 'redux/entities/selectors'
import './ImageCard.css'

const mapStateToProps = (state, props) => ({
  fromStore: {
    form: getForm(state, 'event'),
    gallery: getUserGallery(state, { id: props.event.user.id }),
  },
})
const mapDispatchToProps = (dispatch) => ({
  actions: {
    mergeInForm: (formData) => dispatch(mergeInForm({ formName: 'event', value: formData })),
  },
})

const categoryOptions = fileCategoryOptions.map(({ text, value }) => ({ key: value, text, value }))

class _ImageCard extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    category: PropTypes.string,
    fromStore: PropTypes.shape({
      form: PropTypes.immutable.map,
    }).isRequired,
    actions: PropTypes.shape({
      mergeInForm: PropTypes.func.isRequired,
    }).isRequired,
    file: PropTypes.shape({
      id: PropTypes.number,
      uploadcare_id: PropTypes.string,
    }).isRequired,
  }

  static defaultProps = {
    category: undefined,
  }

  state = { format: 'original' }

  copyLink = (fileId) => {
    const tmp = document.createElement('input')
    tmp.value = this.getImageLink(fileId)
    document.body.appendChild(tmp)
    tmp.select()
    document.execCommand('copy')
    tmp.remove()
  }

  getImageLink = (fileId) => {
    const ucarePath =
      this.state.format === 'original' ? '/-/raw' : `/-/scale_crop/${this.state.format}/center/-/progressive/yes`
    return settings.globalUrlFactory.screen.files.transform(fileId, ucarePath)
  }

  changeFormat = (e, value, fileId) => {
    return this.setState({ format: value }, this.copyLink(fileId))
  }

  handleSelectCategory = (newCategory, uid) => {
    const { category } = this.props
    if (category === newCategory) return

    const categoryToAddTo =
      newCategory === 'cover' ? { cover: uid } : { [newCategory]: this.props.fromStore.form.get(newCategory).push(uid) }

    const categoryToRemoveFrom =
      category &&
      (category === 'cover'
        ? { cover: undefined }
        : { [category]: this.props.fromStore.form.get(category).filter((id) => id !== uid) })

    this.props.actions.mergeInForm({ ...categoryToAddTo, ...categoryToRemoveFrom })
  }

  handleDeletePictureChecked = (e, { checked, value }) => {
    const {
      fromStore: { form },
      actions,
    } = this.props
    const delete_pictures_ids = !checked // eslint-disable-line camelcase
      ? form.get('delete_pictures_ids').toSet().delete(value).toList()
      : form.get('delete_pictures_ids').push(value)
    return actions.mergeInForm({ delete_pictures_ids })
  }

  handleUnselectPictureChecked = (uid) => {
    const { category, actions } = this.props

    return actions.mergeInForm({
      [category]: this.props.fromStore.form.get(category).filter((id) => id !== uid),
    })
  }

  render() {
    const { t, file, category } = this.props

    return (
      <IntersectionObserver onlyOnce>
        {({ isIntersecting }) => (
          <div className='__card'>
            {isIntersecting && (
              <Card key={file.uploadcare_id} className='__card'>
                <Card.Header>
                  <Image src={scaleCropFileUrl(file, '300x200')} className='__image' />
                </Card.Header>
                <Card.Content>
                  <Card.Description>
                    <Popup
                      trigger={
                        <Button
                          label={
                            <Dropdown
                              value={category}
                              options={getTranslatedOptions(t, categoryOptions)}
                              onChange={(e, { value }) => this.handleSelectCategory(value, file.uploadcare_id)}
                            />
                          }
                          icon='exchange'
                          title={t('Experiences::Summary::Select image category')}
                          labelPosition='right'
                          basic
                        />
                      }
                      content={t("Experiences::Summary::'Moved!")}
                      on='click'
                      inverted
                      hideOnScroll
                    />

                    {category !== 'cover' && (
                      <Form.Checkbox
                        label={t('Experiences::Summary::Delete permanently')}
                        value={file.id}
                        onChange={this.handleDeletePictureChecked}
                        style={{ marginTop: 15 }}
                      />
                    )}

                    {!!category && category !== 'cover' && (
                      <Button
                        onClick={() => this.handleUnselectPictureChecked(file.uploadcare_id)}
                        style={{ marginBottom: 10 }}
                        icon='trash'
                        content={t('Experiences::Summary::Remove from experience')}
                        labelPosition='left'
                        basic
                      />
                    )}

                    <Popup
                      trigger={
                        <Button
                          label={
                            <Dropdown
                              value={this.state.format}
                              options={getTranslatedOptions(t, fileFormatOptions)}
                              onChange={(e, { value }) => this.changeFormat(e, value, file.id)}
                            />
                          }
                          icon='linkify'
                          title={t('Experiences::Summary::Copy image link')}
                          onClick={() => this.copyLink(file.id)}
                          labelPosition='right'
                          basic
                        />
                      }
                      content={t('Experiences::Summary::Copied!')}
                      on='click'
                      inverted
                      hideOnScroll
                    />
                  </Card.Description>
                </Card.Content>
              </Card>
            )}
          </div>
        )}
      </IntersectionObserver>
    )
  }
}

export const ImageCard = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_ImageCard))
