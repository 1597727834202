import { schema } from 'normalizr'
import { Record } from 'immutable'
import { users } from './users'

const _schema = new schema.Entity('bankAccounts', {
  user: users.schema,
})

const _Record = Record({
  id: undefined,
  type: undefined,
  country_iso: undefined,
  bank_name: undefined,
  account_holder_name: undefined,
  account_number: undefined,
  currency: undefined,
  default_for_currency: undefined,
  user: new users.Record(),
  organization: undefined,
  created_at: undefined,
  updated_at: undefined,
})

export const bankAccounts = {
  schema: _schema,
  Record: _Record,
}
