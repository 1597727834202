// Libs
import React, { useState, useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { withRouter } from 'react-router'
// Components
import { UserCard } from 'components/cards/UserCard'
import {
  Dimmer,
  Loader,
  Grid,
  TextArea,
  Segment,
  Form,
  Button,
  Divider,
  Popup,
  Accordion,
  Icon,
  List,
} from 'semantic-ui-react'
// Redux
import { fetchUserBankAccounts } from 'redux/entities/actions'
import {
  getUserBankAccounts,
  getUserBankAccountsError,
  fetchingUserBankAccounts,
  getUser,
} from 'redux/entities/selectors'
import { connectAs } from 'helpers/users'

function _BankAccountsPage({ params: { id }, router: { goBack: handleGoBack } }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const bankAccounts = useSelector((state) => getUserBankAccounts(state, { id }))
  const isFetchingBankAccounts = useSelector((state) => fetchingUserBankAccounts(state, id))
  const bankAccountsFetchError = useSelector((state) => getUserBankAccountsError(state, id))
  const user = useSelector((state) => getUser(state, { id }))

  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    dispatch(fetchUserBankAccounts(id))
  }, [dispatch, id])

  function handleClick(_, { index }) {
    return setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index))
  }

  function handleCopyBankAccountToClipboard({ bankName, accountNumber, accountHolderName }) {
    navigator.clipboard.writeText(
      `Bank name: ${bankName}, Account number: ${accountNumber}, Account holder name: ${accountHolderName}`,
    )
  }

  if (bankAccountsFetchError)
    return (
      <Dimmer active>
        <Icon name='warning sign' />
        {t('Users::BankAccounts::You are not authorized to view this page')}
      </Dimmer>
    )

  if (isFetchingBankAccounts)
    return (
      <Dimmer active>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    )

  return (
    <div style={{ width: '60vw', margin: 'auto' }}>
      <Button content='Back' icon='long arrow alternate left' basic color='black' onClick={handleGoBack} />
      <Divider horizontal />
      <Grid>
        <Grid.Row>
          <Grid.Column width={5}>
            <UserCard user={user} connectAs={() => connectAs(user.account.id)} />
          </Grid.Column>
          <Grid.Column width={9}>
            <Segment>
              <h2>{t('Users::BankAccounts::Billing Address')}</h2>
              <Divider horizontal />
              {user.billing_address ? (
                <React.Fragment>
                  <Form>
                    <TextArea value={user.billing_address} disabled />
                    <div style={{ position: 'absolute', bottom: '16px', right: '16px' }}>
                      <Popup
                        content={t('Users::BankAccounts::Copy to clipboard')}
                        trigger={
                          <Button
                            icon='clipboard'
                            basic
                            onClick={() => navigator.clipboard.writeText(user.billing_address)}
                          />
                        }
                      />
                    </div>
                  </Form>
                </React.Fragment>
              ) : (
                t('Users::BankAccounts::No billing address provided')
              )}
            </Segment>

            <Segment>
              <h2>{t('Users::BankAccounts::Bank Accounts')}</h2>
              <Divider horizontal />
              {bankAccounts.size > 0 ? (
                <Accordion styled fluid>
                  {bankAccounts.map(
                    (
                      {
                        id,
                        bank_name: bankName,
                        account_number: accountNumber,
                        account_holder_name: accountHolderName,
                      },
                      key,
                    ) => (
                      <div key={id}>
                        <Accordion.Title active={activeIndex === +key} index={+key} onClick={handleClick}>
                          <Icon name='dropdown' />
                          {`Bank Account ${+key + 1}`}
                        </Accordion.Title>

                        <Accordion.Content active={activeIndex === +key}>
                          <List>
                            <List.Item>
                              <Trans
                                i18nKey={__('Users::BankAccounts::<bold>Bank name</bold>: {{bankName}}')}
                                values={{ bankName }}
                                components={{ bold: <b /> }}
                              />
                            </List.Item>
                            <List.Item style={{ overflowWrap: 'break-word' }}>
                              <Trans
                                i18nKey={__('Users::BankAccounts::<bold>Account number</bold>: {{accountNumber}}')}
                                values={{ accountNumber }}
                                components={{ bold: <b /> }}
                              />
                            </List.Item>
                            <List.Item>
                              <Trans
                                i18nKey={__(
                                  'Users::BankAccounts::<bold>Account holder name</bold>: {{accountHolderName}}',
                                )}
                                values={{ accountHolderName }}
                                components={{ bold: <b /> }}
                              />
                            </List.Item>
                            <List.Item style={{ textAlign: 'right' }}>
                              <Popup
                                content={t('Users::BankAccounts::Copy to clipboard')}
                                trigger={
                                  <Button
                                    basic
                                    icon='clipboard'
                                    onClick={() =>
                                      handleCopyBankAccountToClipboard({
                                        bankName,
                                        accountNumber,
                                        accountHolderName,
                                      })
                                    }
                                  />
                                }
                              />
                            </List.Item>
                          </List>
                        </Accordion.Content>
                      </div>
                    ),
                  )}
                </Accordion>
              ) : (
                t('Users::BankAccounts::No bank accounts found')
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

_BankAccountsPage.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  router: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
}

export default withRouter(_BankAccountsPage)
