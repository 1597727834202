import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const pricingsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'pricings' })

export function fetchPricing(id, query) {
  return pricingsActions.fetch({
    url: `/api/events/${id}/pricing`,
    query,
    schema: { pricing: schemas.pricings },
  })
}
