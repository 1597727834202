import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHasLoadingSucceeded } from 'hooks'
import { Card, List, Button } from 'semantic-ui-react'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { toast } from 'react-toastify'
import { formatTagTitle } from 'helpers/tags'
import { getForm } from 'redux/forms/reducer'
import { createTag, updateTag, archiveTag, unarchiveTag } from 'redux/entities/actions'
import {
  creatingTag,
  updatingTag,
  getTagError,
  getTagsError,
  getCategory,
  archivingTag,
  unarchivingTag,
} from 'redux/entities/selectors'
import { withRouter } from 'react-router'
import './Card.css'

function ActionButtons({ onTagCreation, tag, title }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const form = useSelector((state) => getForm(state, 'tag'))
  const isCreatingTag = useSelector(creatingTag)
  const isUpdatingTag = useSelector((state) => updatingTag(state, tag.id))
  const category = useSelector((state) => getCategory(state, { id: tag.category_id }))
  const isArchivingTag = useSelector((state) => archivingTag(state, tag.id))
  const isUnarchivingTag = useSelector((state) => unarchivingTag(state, tag.id))

  async function handleSave(e) {
    if (e) e.preventDefault()

    if (tag.id)
      return dispatch(
        updateTag(tag.id, {
          tag,
          title,
          description: form.get('description'),
          category_id: form.get('category_id'),
        }),
      )

    const {
      payload: { data, error },
    } = await dispatch(
      createTag({
        title,
        description: form.get('description'),
        category: { id: form.get('category_id') },
      }),
    )
    if (!error) onTagCreation(data.result.tag)
  }

  if (!tag.id)
    return (
      <Card.Content textAlign='center'>
        <Button primary onClick={handleSave} loading={isCreatingTag}>
          {t('Tags::Summary::Save tag')}
        </Button>
      </Card.Content>
    )

  if (category.readonly) return null

  return (
    <Card.Content textAlign='center'>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center' }}>
        <Button primary onClick={handleSave} loading={isUpdatingTag}>
          {t('Tags::Summary::Update tag')}
        </Button>

        {tag.archived_at ? (
          <Button primary onClick={() => dispatch(unarchiveTag(tag.id))} loading={isArchivingTag}>
            {t('Tags::Summary::Unarchive')}
          </Button>
        ) : (
          <Button primary onClick={() => dispatch(archiveTag(tag.id))} loading={isUnarchivingTag}>
            {t('Tags::Summary::Archive')}
          </Button>
        )}
      </div>
    </Card.Content>
  )
}

ActionButtons.propTypes = {
  onTagCreation: PropTypes.func.isRequired,
  tag: PropTypes.shape({
    id: PropTypes.number,
    category_id: PropTypes.number,
    archived_at: PropTypes.string,
  }),
  title: PropTypes.string,
}

ActionButtons.defaultProps = {
  tag: undefined,
  title: undefined,
}

function _TagCard({ router, tag }) {
  const { t } = useTranslation()

  const [createdTagId, setCreatedTagId] = useState()

  const form = useSelector((state) => getForm(state, 'tag'))
  const getCategoryById = useSelector((state) => (id) => getCategory(state, { id }))

  const createError = useSelector(getTagsError)
  const isUpdatingTag = useSelector((state) => updatingTag(state, tag.id))
  const updateError = useSelector((state) => getTagError(state, tag.id))
  const isTagUpdated = useHasLoadingSucceeded(isUpdatingTag, updateError)

  const title = useMemo(() => formatTagTitle(form.get('title')).slice(0, 191), [form])

  useEffect(() => {
    if (createdTagId)
      toast.success(`${t("ToastNotification::Well done! You've just saved the tag")} 👍`, {
        type: toast.TYPE.SUCCESS,
        onClose: () => router.push(`/tags/edit/${createdTagId}`),
      })
  }, [createdTagId, router, t])

  useEffect(() => {
    if (isTagUpdated)
      toast.success(
        `${t(`ToastNotification::Well done! You've just updated the tag "{{title}}"`, { title: tag.title })} 👍`,
        { type: toast.TYPE.SUCCESS },
      )
  }, [isTagUpdated, t, tag.title])

  return (
    <Card className='Tag-Card'>
      <Card.Content>
        <Card.Header>{t('Tags::Summary::Tag preview')}</Card.Header>
        <List bulleted>
          <List.Item>{t('Tags::Summary::Title: {{title}}', { title })}</List.Item>

          <List.Item>
            {t('Tags::Summary::Category: {{category}}', {
              category: getCategoryById(form.get('category_id')).name,
            })}
          </List.Item>

          <List.Item>
            {t('Tags::Summary::Description: {{description}}', {
              description: form.get('description'),
            })}
          </List.Item>
        </List>
      </Card.Content>

      <ActionButtons tag={tag} title={title} onTagCreation={setCreatedTagId} />

      {(createError || updateError) && (
        <Card.Content>
          <ApiErrorMessage error={createError || updateError} />
        </Card.Content>
      )}
    </Card>
  )
}

_TagCard.propTypes = {
  tag: PropTypes.immutable.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

_TagCard.defaultProps = {
  tag: undefined,
}

export const TagCard = withRouter(_TagCard)
