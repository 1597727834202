import settings from 'settings'
import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'
import cookies from 'helpers/cookies'
import { Loader } from 'semantic-ui-react'
import { loginActions } from 'redux/actions'
import { fetchStatics, fetchCategories, checkBankAccountsViewPermission } from 'redux/entities/actions'
import { getCategories, getCategoriesTotal } from 'redux/entities/selectors'
import { getLocation } from 'redux/reducers/router'
import { getAuthToken, getLoading } from 'redux/reducers/admin'

function useFetchCategories(isAuthenticated) {
  const dispatch = useDispatch()
  const categories = useSelector(getCategories)
  const location = useSelector(getLocation)
  const categoriesTotal = useSelector(getCategoriesTotal)

  const isCategoryPage = location.get('pathname') === '/categories'

  /* 
    This hook is not consumed by the category page which has her own paginated fetching logic.
    Redirected from the category page, we cannot detect which range has already been fetched
    based on the set offset hence we refetch all the categories
  */
  useEffect(() => {
    if (isAuthenticated && !isCategoryPage && (categories.size === 0 || categories.size !== categoriesTotal))
      dispatch(
        fetchCategories({
          query: {
            offset: categoriesTotal > 0 ? settings.defaultPaginationSize : 0,
            size:
              categoriesTotal > 0 ? categoriesTotal - settings.defaultPaginationSize : settings.defaultPaginationSize,
          },
        }),
      )
  }, [categories.size, categoriesTotal, dispatch, isAuthenticated, isCategoryPage])
}

function useAuthentication({ location, replace }) {
  const dispatch = useDispatch()
  const reduxToken = useSelector(getAuthToken)
  const cookieToken = cookies.kitchenAuthToken.value

  const debouncedAuthByToken = useMemo(
    () => debounce(() => dispatch(loginActions.logInWithToken(cookieToken)), 200),
    [dispatch, cookieToken],
  )

  useEffect(() => {
    if (!reduxToken && !cookieToken && location.pathname !== '/login')
      replace({
        pathname: '/login',
        query: {
          redirect: location.pathname + location.search,
        },
      })
  }, [cookieToken, location.pathname, location.search, reduxToken, replace])

  useEffect(() => {
    if (!reduxToken && cookieToken) debouncedAuthByToken()
    return () => debouncedAuthByToken.cancel()
  }, [cookieToken, debouncedAuthByToken, reduxToken])

  return {
    isAuthenticated: !!(reduxToken && cookieToken),
    accessForbiddenForTheCurrentPage: !reduxToken && location.pathname !== '/login',
  }
}

function _Authenticate({ children, router }) {
  const dispatch = useDispatch()
  const isLoggingIn = useSelector(getLoading)
  const { isAuthenticated, accessForbiddenForTheCurrentPage } = useAuthentication(router)
  useFetchCategories(isAuthenticated)

  useEffect(() => {
    dispatch(fetchStatics(['languages', 'currencies']))
  }, [dispatch])

  useEffect(() => {
    if (isAuthenticated) dispatch(checkBankAccountsViewPermission())
  }, [dispatch, isAuthenticated])

  if (accessForbiddenForTheCurrentPage) return null

  if (isLoggingIn) return <Loader />

  return children
}

_Authenticate.propTypes = {
  children: PropTypes.node.isRequired,
  router: PropTypes.shape({
    replace: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  }).isRequired,
}

const Authenticate = withRouter(_Authenticate)
export default Authenticate
