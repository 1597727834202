// Libs
import React, { PureComponent } from 'react'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// Components
import { Table, Button, Icon, Label, List, Checkbox, Popup } from 'semantic-ui-react'
import { Link } from 'react-router'
import { FormattedFollowup } from 'components/followups'
import { ConversationModal } from '../../components/conversations/'
// Redux
import { getRequestStatus } from 'redux/entities/selectors'
// Utils
import { getLocalityAndCountry } from 'helpers/places'

import './RequestRow.css'

const { Row, Cell } = Table

const mapStateToProps = (state, props) => ({
  fromStore: {
    getRequestStatus: (id) => getRequestStatus(state, { id }),
  },
})

class _RequestRow extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    request: PropTypes.immutable.record.isRequired,
    onEditStatus: PropTypes.func.isRequired,
    onAddFollowup: PropTypes.func.isRequired,
    onOpenInvoiceModal: PropTypes.func.isRequired,
    addingFollowup: PropTypes.bool,
    selected: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    fromStore: PropTypes.shape({
      getRequestStatus: PropTypes.string.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    changingStatus: undefined,
    addingFollowup: undefined,
    selected: false,
  }

  state = { isInvoiceModalVisible: false }

  getExpirationDate() {
    const {
      request: { expired_at: expiredAt, will_expire_at: willExpireAt },
    } = this.props
    if (expiredAt) return moment(expiredAt).format('lll')
    if (willExpireAt) return moment(willExpireAt).format('lll')
    return null
  }

  formatFollowup(followup) {
    if (!followup) return
    return (
      <List>
        {followup.admin && (
          <List.Item>
            <span>
              <Label circular color='blue'>
                {followup.admin.get('fullname')}
              </Label>{' '}
              on {moment(followup.created_at).format('lll')}
            </span>
          </List.Item>
        )}
        <List.Item>
          {followup.action && (
            <strong> {followup.action.charAt(0).toUpperCase() + followup.action.slice(1).toLowerCase()}</strong>
          )}
          {followup.action && followup.comment ? <strong>: </strong> : ''}
          {followup.comment.toLowerCase()}
        </List.Item>
      </List>
    )
  }

  render() {
    const { t, addingFollowup, onEditStatus, onOpenInvoiceModal, request } = this.props
    return (
      <Row className={request.booking_ids.size ? 'darker-row' : ''}>
        <Cell className={request.ewid ? 'ew' : ''}>
          <Link to={`/requests/${request.id}`}>{request.id}</Link>
          {request.booking_ids.size > 0 && (
            <span>
              <br />
              <Link to={`/bookings?search=${request.booking_ids.first()}`}>{`B-${request.booking_ids.first()}`}</Link>
            </span>
          )}
        </Cell>

        <Cell>{moment(request.updated_at).format('lll')}</Cell>

        <Cell>{request.place.country_iso}</Cell>

        <Cell>{getLocalityAndCountry(request.place)}</Cell>

        <Cell>
          {this.props.fromStore.getRequestStatus(this.props.request.id)}
          {request.privatized_at && <Label size='small'>{t('Requests::PRIVATE EVENT')}</Label>}
        </Cell>

        <Cell style={{ whiteSpace: 'pre-line' }}>
          {request.date && moment(request.date).format('ll')}
          {request.begins_at && (
            <span>
              <br />
              {t('Requests::Begins at {{beginsAt}}', { beginsAt: moment(request.begins_at, 'HH:mm:ss').format('LT') })}
            </span>
          )}
        </Cell>

        <Cell style={{ whiteSpace: 'pre-line' }}>{this.getExpirationDate()}</Cell>

        <Cell className={request.user.ewid ? 'ew' : ''}>
          <Link to={`/users?search=${request.user.id}`}>
            {`${request.user.id} - ${request.user.firstname} ${request.user.lastname}`}
          </Link>
        </Cell>

        <Cell className={request.event.user.ewid ? 'ew' : ''}>
          <Link to={`/users?search=${request.event.user.id}`}>
            {request.event.user.id} - {request.event.user.firstname} {request.event.user.lastname}
          </Link>
        </Cell>

        <Cell className={request.event.ewid ? 'ew' : ''}>
          <Link to={`/events?search=${request.event.id}`}>{`${request.event.id} - ${request.event.title}`}</Link>
        </Cell>

        <Cell>{request.seats}</Cell>

        <Cell>
          <FormattedFollowup followups={request.followups} />
          &nbsp;
          {addingFollowup && <Icon loading name='circle notched' />}
        </Cell>

        <Cell textAlign='center'>
          <Popup
            trigger={<Button primary circular onClick={() => onEditStatus(request)} icon='compose' />}
            content={t('Requests::Change Status')}
            inverted
          />

          <ConversationModal
            conversationId={request.conversation_id}
            senderId={request.user.id}
            recipientId={request.event.user.id}
          />

          <Popup
            trigger={<Button primary circular onClick={() => onOpenInvoiceModal(request)} icon='dollar' />}
            content={t('Requests::Create an experience paid by invoice from this request')}
            inverted
          />
        </Cell>

        <Cell className='select'>
          <Checkbox
            onChange={(e, { checked }) => this.props.onSelect(checked, request.id)}
            checked={this.props.selected}
          />
        </Cell>
      </Row>
    )
  }
}

export const RequestRow = withTranslation('common')(connect(mapStateToProps)(_RequestRow))
