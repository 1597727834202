import { schema } from 'normalizr'
import { Record, Map } from 'immutable'
import { currencies } from './statics'

const _schema = new schema.Entity(
  'pricings',
  {},
  {
    idAttribute: (entity) => entity.event_id,
  },
)

const _Record = Record({
  payee: new Map({
    currency: new currencies.Record(),
    price: null,
  }),
  discount: new Map({
    currency: new currencies.Record(),
    discount_to_payee_conversion_rate: undefined,
    amount_in_discount_currency: undefined,
    amount_in_guest_currency: undefined,
    amount_in_event_currency: undefined,
  }),
  fees: new Map({
    currency: new currencies.Record(),
    rate: undefined,
    vizeat: undefined,
    conversion: undefined,
    total: undefined,
  }),
  payer: new Map({
    currency: new currencies.Record(),
    payee_to_payer_conversion_rate: undefined,
    unit_price: undefined,
    seats: undefined,
    sub_total: undefined,
    fees: undefined,
    discount: undefined,
    total_paid: undefined,
    unit_price_with_fees: undefined,
  }),
})

export const pricings = {
  schema: _schema,
  Record: _Record,
}
