// libs
import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'
// helpers
import PropTypes from 'helpers/proptypes'
import { removeDuplicates } from 'helpers/files'
// components
import { Card, Button, Segment } from 'semantic-ui-react'
import { FileUploader } from 'components/inputs'
import { Paginate } from 'components/lists'
import { ImageCard } from './ImageCard'
// redux
import { mergeInForm } from 'redux/forms/actions'
import { deleteFile } from 'redux/entities/actions'
import { getForm } from 'redux/forms/reducer'

const mapStateToProps = (state, props) => ({
  fromStore: {
    form: getForm(state, 'event'),
  },
})
const mapDispatchToProps = (dispatch) => ({
  actions: {
    mergeInForm: (formData) => dispatch(mergeInForm({ formName: 'event', value: formData })),
    removeFile: (id) => dispatch(deleteFile(id)),
  },
})

class _PhotoSection extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    event: PropTypes.immutable.record.isRequired,
    photos: PropTypes.immutable.map,
    title: PropTypes.string.isRequired,
    category: PropTypes.string,
    fromStore: PropTypes.shape({
      form: PropTypes.immutable.map,
    }).isRequired,
    actions: PropTypes.shape({
      mergeInForm: PropTypes.func,
      removeFile: PropTypes.func,
    }).isRequired,
  }

  static defaultProps = {
    category: undefined,
    photos: Map(),
  }

  state = { offset: 0, itemsPerPage: 4 }

  setOffset = (offset) => this.setState({ offset })

  handleFileUploaded = (filesInfo = [{}], category) => {
    const {
      fromStore: { form },
      actions: { mergeInForm },
    } = this.props
    return mergeInForm({ [category]: form.get(category).push(...filesInfo.map((f) => f.uuid)) })
  }

  deletePictures = (e) => {
    if (e) e.preventDefault()
    const {
      fromStore: { form },
      actions: { removeFile },
    } = this.props
    form.get('delete_pictures_ids').map((id) => removeFile(id))
  }

  render() {
    const { t, title, event, category } = this.props
    const { offset, itemsPerPage } = this.state

    const photos = removeDuplicates(this.props.photos.toList().filter((f) => !!f.uploadcare_id))
    const paginatedPhotos = photos.slice(offset, offset + itemsPerPage)

    return (
      <Segment>
        <h4>{title}</h4>

        <FileUploader
          primary
          model='events'
          id={event.id}
          multiple
          onUploaded={this.handleFileUploaded}
          category={category}
        >
          {t('Experiences::Summary::Upload files')}
        </FileUploader>

        {photos.size > 0 && (
          <Fragment>
            <Button onClick={this.deletePictures}>{t('Experiences::Summary::Delete files')}</Button>
            <Paginate
              ignoreLocation
              count={photos.size}
              previous
              next
              customOffset={offset}
              customSize={itemsPerPage}
              onPageChange={this.setOffset}
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={(value) => {
                this.setState({ offset: 0, itemsPerPage: value })
              }}
            >
              <Card.Group itemsPerRow={4} style={{ paddingTop: 15, paddingBottom: 30 }}>
                {paginatedPhotos.map((file) => (
                  <ImageCard key={file.uploadcare_id} file={file} event={event} category={category} />
                ))}
              </Card.Group>
            </Paginate>
          </Fragment>
        )}
      </Segment>
    )
  }
}

export const PhotoSection = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(_PhotoSection))
