// Libraries
import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { omitBy, get } from 'lodash'
import { withTranslation, Trans } from 'react-i18next'
// Components
import { Form, Button, Divider, Message, Label, Dimmer, Loader } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import {
  DateSection,
  PricingSection,
  ToggleSection,
  RestrictionSection,
  UserSection,
  OccasionSection,
  EventSection,
  ReferenceSection,
  SeatsSection,
} from './components'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
// Helpers
import { formatRestrictions } from 'helpers/restrictions'
import { isDateTodayOrLater } from 'helpers/dates'
import { isInvalid } from 'helpers/forms'
// Redux
import { createDirectBooking, fetchPartnerDemands, fetchRequest } from 'redux/entities/actions'
import {
  fetchingRequest,
  getCreatingDirectBookingError,
  getDiets,
  getOrganization,
  getPartnerDemand,
  getRequest,
  getSchedule,
} from 'redux/entities/selectors'

import './form.css'

const mapStateToProps = (state, props) => ({
  fromStore: {
    errors: {
      booking: getCreatingDirectBookingError(state),
    },
    request: props.request && getRequest(state, { id: props.request.id }),
    isLoadingRequest: props.request && fetchingRequest(state, props.request.id),
    diets: getDiets(state),
    getOrganization: (id) => getOrganization(state, { id }),
    getSchedule: (date) => getSchedule(state, date),
    getPartnerDemand: (id) => getPartnerDemand(state, { id }),
  },
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    createDirectBooking: (payload) => dispatch(createDirectBooking(payload)),
    fetchPartnerDemand: (id) => dispatch(fetchPartnerDemands({ query: { search: id } })),
    loadRequest: (id) => dispatch(fetchRequest(id)),
  },
})

class _InvoiceBookingForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    fromStore: PropTypes.shape({
      diets: PropTypes.immutable.map,
      getOrganization: PropTypes.func.isRequired,
      getSchedule: PropTypes.func.isRequired,
      errors: PropTypes.shape({
        booking: PropTypes.object,
      }).isRequired,
      request: PropTypes.shape({
        user_id: PropTypes.number.isRequired,
        seats: PropTypes.number,
        occasion: PropTypes.string,
      }),
    }).isRequired,
    isLoadingRequest: PropTypes.bool,
    actions: PropTypes.shape({
      createDirectBooking: PropTypes.func,
      fetchPartnerDemand: PropTypes.func,
      loadRequest: PropTypes.func,
    }).isRequired,
    btnSubmitTitle: PropTypes.string.isRequired,
    eventWithOverrides: PropTypes.shape({
      id: PropTypes.number.isRequired,
      user: PropTypes.object,
      begins_at: PropTypes.string,
      ends_at: PropTypes.string,
      price: PropTypes.number,
    }),
    request: PropTypes.shape({
      user_id: PropTypes.number.isRequired,
    }),
    date: PropTypes.string,
    partnerDemandId: PropTypes.number,
  }

  static defaultProps = {
    isLoadingRequest: false,
    eventWithOverrides: undefined,
    begins_at: '19:00',
    ends_at: '22:00',
    request: undefined,
    date: undefined,
    partnerDemandId: undefined,
  }

  state = {
    additionalInfo: undefined,
    body: '',
    bookAs: this.props.bookAs ? { id: this.props.bookAs.id } : undefined,
    currency: {},
    date: this.props.date,
    error: undefined,
    event: this.props.eventWithOverrides,
    from: get(this.props, ['eventWithOverrides', 'begins_at']),
    guestPrice: undefined,
    host: get(this.props, ['eventWithOverrides', 'user']),
    isInstantBooking: true,
    isInvoicePayment: false,
    isPrivateEvent: true,
    notifyGuest: false,
    notifyHost: false,
    occasion: 'other',
    price: get(this.props, ['eventWithOverrides', 'price']),
    referenceCode: this.props.referenceCode,
    restrictions: [],
    schedule: undefined,
    seats: 1,
    ewFreeSeats: 0,
    hostFreeSeats: 0,
    submitted: false,
    to: get(this.props, ['eventWithOverrides', 'ends_at']),
    totalPrice: undefined,
    partnerDemandId: this.props.partnerDemandId,
  }

  componentDidMount() {
    const {
      actions,
      fromStore: { getPartnerDemand },
      partnerDemandId,
      request,
    } = this.props

    if (request) {
      this.setState({ bookAs: { id: request.user_id } })
      actions.loadRequest(request.id)
    }

    const demandId = partnerDemandId || get(request, 'partner_demand_id')
    if (demandId) {
      if (getPartnerDemand(demandId).id) {
        this.setState({ referenceCode: getPartnerDemand(demandId).reference.code })
      } else {
        this.setState({ partnerDemandId: demandId })
        actions.fetchPartnerDemand(demandId)
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { submitted, event, referenceCode, partnerDemandId } = this.state
    const {
      t,
      fromStore: { errors, getPartnerDemand, request },
      handleSubmit,
    } = this.props

    if (request && prevProps.fromStore.request !== request) {
      this.setState({
        occasion: request.occasion || 'other',
        seats: request.seats,
        additionalInfo: request.additional_info,
        date: request.date,
      })
    }

    // Autofill reference code with code from potential partner demand id reference
    if (
      partnerDemandId &&
      !prevProps.fromStore.getPartnerDemand(partnerDemandId).id &&
      getPartnerDemand(partnerDemandId).id &&
      !referenceCode
    ) {
      this.setState({ referenceCode: getPartnerDemand(partnerDemandId).reference.code })
    }

    if (submitted && errors.booking === undefined) {
      toast.success(
        `${(t('ToastNotification::{{eventTitle}} has been correctly created'), { eventTitle: event.title })} 👍`,
        {
          type: toast.TYPE.SUCCESS,
        },
      )

      this.setState({ submitted: false })
      handleSubmit()
    }
  }

  handleSubmit = async () => {
    const {
      additionalInfo,
      body,
      bookAs,
      currency,
      date,
      event,
      from,
      guestPrice,
      isInstantBooking,
      isPrivateEvent,
      notifyGuest,
      notifyHost,
      occasion,
      price,
      referenceCode,
      restrictions,
      seats,
      ewFreeSeats,
      hostFreeSeats,
      to,
      totalPrice,
      partnerDemandId,
    } = this.state
    const { actions, fromStore, request } = this.props

    this.setState({ submitted: false })

    const directBooking = omitBy(
      {
        additional_info: additionalInfo || formatRestrictions(restrictions, fromStore.diets),
        begins_at: from,
        body,
        currency,
        date: moment(date).format('YYYY-MM-DD'),
        ends_at: to,
        event_id: event.id,
        guest_price: guestPrice,
        instant_booking: isInstantBooking,
        invoice: true,
        notify_guest: notifyGuest,
        notify_host: notifyHost,
        occasion,
        phone: bookAs.phone,
        price,
        reference_code: referenceCode || '',
        seats: seats,
        ew_free_seats: ewFreeSeats,
        host_free_seats: hostFreeSeats,
        total_price: totalPrice,
        user: bookAs,
        partner_demand_id: partnerDemandId,
        request,
      },
      isInvalid,
    )

    if (isPrivateEvent) {
      Object.assign(directBooking, { privatizer_id: request ? request.privatizer_id : bookAs.id })
    }

    await actions.createDirectBooking(directBooking)

    this.setState({ submitted: true })
  }

  checkInvoicePayment(user) {
    this.setState({ bookAs: user })
    if (user && user.partner) {
      const organization = this.props.fromStore.getOrganization(user.partner.organization_id)
      this.setState({ isInvoicePayment: !organization.payment })
    } else {
      this.setState({ isInvoicePayment: false })
    }
  }

  render() {
    const {
      additionalInfo,
      bookAs,
      currency,
      date,
      event,
      from,
      host,
      isInstantBooking,
      isInvoicePayment,
      isPrivateEvent,
      notifyGuest,
      notifyHost,
      occasion,
      partnerDemandId,
      price,
      referenceCode,
      restrictions,
      schedule,
      seats,
      ewFreeSeats,
      hostFreeSeats,
      to,
    } = this.state
    const {
      t,
      fromStore: { errors, diets, getSchedule, isLoadingRequest },
      btnSubmitTitle,
      customPricingMessage,
    } = this.props
    const isDisabled = !event || !isInvoicePayment || (bookAs && !bookAs.phone)
    const canSubmit =
      host &&
      event &&
      seats &&
      date &&
      from &&
      to &&
      price >= 0 &&
      bookAs &&
      !isDisabled &&
      isDateTodayOrLater(date) &&
      !(ewFreeSeats + hostFreeSeats > seats)
    const isOverrideDisabled = schedule && schedule.booking_ids.size > 0
    const seatsToInvoice = seats - hostFreeSeats

    return (
      <Fragment>
        {isLoadingRequest ? (
          <Dimmer active inverted>
            <Loader inverted content={t('Loading::Loading')} />
          </Dimmer>
        ) : (
          <Form onSubmit={this.handleSubmit}>
            <UserSection
              placeholder={t('EventsCalendar::Host name, email or id')}
              inputLabel={t('EventsCalendar::Host')}
              onUserChange={(host) => host && this.setState({ host })}
              isHost
              user={host}
            />

            <EventSection
              host={host}
              isDisabled={!host}
              onEventChange={(event) => event && this.setState({ event, from: event.begins_at, to: event.ends_at })}
              event={event}
            />

            <UserSection
              placeholder={t('EventsCalendar::Guest name, email or id')}
              inputLabel={t('EventsCalendar::Guest (to book as)')}
              onUserChange={(guest) => this.checkInvoicePayment(guest)}
              isDisabled={!event}
              user={bookAs}
            />

            {event && bookAs && (
              <div>
                {!isInvoicePayment && (
                  <Message negative>
                    {t('EventsCalendar::{{firstName}} {{lastName}} is not allowed to pay by invoice', {
                      firstName: bookAs.firstname,
                      lastName: bookAs.lastname,
                    })}
                  </Message>
                )}

                {bookAs && !bookAs.phone && (
                  <Message negative>
                    <Trans
                      i18nKey={__(
                        'EventsCalendar::Partner must have a phone number <userURL>Click here to add it</userURL>',
                      )}
                      components={{
                        userURL: <a href={`/users/${bookAs.id}`} target='_blank' rel='noopener noreferrer' />,
                      }}
                    />
                  </Message>
                )}
              </div>
            )}

            <Divider />

            <DateSection
              from={from}
              to={to}
              date={date}
              handleDateChange={(dte) => this.setState({ date: dte, schedule: getSchedule(dte) })}
              handleChange={(value) => this.setState(value)}
              isDisabled={isDisabled}
              isTimeDisabled={isOverrideDisabled}
              eventId={event?.id}
            />

            <Divider />

            <Form.Group className='__item'>
              <OccasionSection
                inputWitdh={8}
                occasion={occasion}
                handleChange={(value) => this.setState(value)}
                isDisabled={isDisabled}
              />
              <Form.Field inline style={{ marginTop: '2em', marginLeft: '1em' }} width={8} disabled={isDisabled}>
                <label>{t('EventsCalendar::Booking type:')}</label>
                <Label color={isPrivateEvent ? 'blue' : 'green'}>
                  {isPrivateEvent ? t('EventsCalendar::PRIVATE') : t('EventsCalendar::OPEN')}
                </Label>
              </Form.Field>
            </Form.Group>

            <Divider />

            {!isDisabled && bookAs && date && (
              <Fragment>
                <SeatsSection
                  seats={seats}
                  ewFreeSeats={ewFreeSeats}
                  hostFreeSeats={hostFreeSeats}
                  handleChange={(value) => value && this.setState(value)}
                />

                {customPricingMessage && <Message info icon='info circle' content={customPricingMessage} />}

                <PricingSection
                  allowGuestPriceEdition
                  currency={currency}
                  date={date}
                  event={event}
                  handleChange={(value) => value && this.setState(value)}
                  isPriceDisabled={isOverrideDisabled}
                  price={price}
                  seats={seatsToInvoice}
                  user={bookAs}
                />
              </Fragment>
            )}

            {!isDisabled && event.max_seats < seats && (
              <Message
                info
                icon='info circle'
                content={t('EventsCalendar::The number of seats is over the event capacity')}
              />
            )}
            {price < 0 && <Message negative>{t('EventsCalendar::Price should not be negative')}</Message>}

            <RestrictionSection
              restrictions={restrictions}
              additionalInfo={additionalInfo}
              diets={diets}
              isDisabled={!event}
              handleChange={(value) => this.setState(value)}
            />

            <Divider />

            <ToggleSection
              notifyHost={notifyHost}
              notifyGuest={notifyGuest}
              handleChange={(value) => this.setState(value)}
              isDisabled={isDisabled}
              isInstantBooking={isInstantBooking}
              isPrivateEvent={isPrivateEvent}
              showInstantBooking
              showNotifyHost
              showNotifyGuest
              showPrivateEvent
            />

            <Divider />

            <ReferenceSection
              isDisabled={isDisabled || partnerDemandId}
              referenceCode={referenceCode}
              handleChange={(value) => value && this.setState(value)}
            />

            <Form.Field className='__item'>
              <label>{t('EventsCalendar::Message to the host (optional):')}</label>
              <Form.TextArea
                name='body'
                placeholder={t('EventsCalendar::Write a message')}
                disabled={isDisabled}
                rows={2}
                onChange={(e, input) => this.setState({ body: input.value, notifyHost: true })}
              />
            </Form.Field>

            <Form.Field className='__btnSubmit'>
              <Button primary size='big' type='submit' disabled={!canSubmit} content={btnSubmitTitle.toUpperCase()} />
            </Form.Field>
            <ApiErrorMessage error={errors.booking} modal />
          </Form>
        )}
      </Fragment>
    )
  }
}

export const InvoiceBookingForm = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_InvoiceBookingForm),
)
