import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetchPaginatedResults } from '../../hooks/pagination/useFetchPaginatedResults'
import { useSelector } from 'react-redux'
import { getLocation } from 'redux/reducers/router'
import {
  getBankAccountsList,
  getBankAccountsWithGivenIds,
  getBankAccountsTotal,
  fetchingBankAccounts,
  getBankAccountsError,
} from 'redux/entities/selectors'
import { Button, Checkbox } from 'semantic-ui-react'
import { BankAccountRow } from './BankAccountRow'
import { List, Paginate } from 'components/lists'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import moment from 'moment'
import { downloadFromBlob } from 'helpers/files'

const HEADERS = [
  { label: __('BankAccounts::Id'), field: 'id' },
  { label: __('BankAccounts::Type') },
  { label: __('BankAccounts::Country') },
  { label: __('BankAccounts::Created at'), field: 'created_at' },
  { label: __('BankAccounts::Updated at'), field: 'updated_at' },
  { label: __('BankAccounts::User') },
  { label: __('BankAccounts::Organization') },
  { label: __('BankAccounts::Currency') },
  { label: __('BankAccounts::Bank name') },
  { label: __('BankAccounts::Account holder name') },
  { label: __('BankAccounts::Account number') },
]

function useExportToCSV(selectedBankAccountsIds) {
  const { t } = useTranslation()
  const bankAccounts = useSelector((state) => getBankAccountsWithGivenIds(state, selectedBankAccountsIds))

  return useCallback(() => {
    const headers = HEADERS.map(({ label }) => t(label)).join(',')
    const contents = bankAccounts.map((ba) =>
      [
        ba.id,
        ba.type,
        ba.country_iso,
        moment.utc(ba.created_at).format('YYYY-MM-DD'),
        moment.utc(ba.updated_at).format('YYYY-MM-DD'),
        `${ba.user.firstname} ${ba.user.lastname}`,
        ba.organization,
        ba.currency,
        ba.bank_name,
        ba.account_holder_name,
        ba.account_number,
      ].join(','),
    )
    const csvData = new Blob([[headers, ...contents].join('\r\n')], { type: 'text/csv' })
    downloadFromBlob(csvData, `bank_accounts_EW_${moment.utc().format('YYYY-MM-DD')}`)
  }, [bankAccounts, t])
}

export function BankAccountList() {
  const { t } = useTranslation()

  const [selection, setSelection] = useState([])

  const bankAccounts = useSelector(getBankAccountsList)
  const total = useSelector(getBankAccountsTotal)
  const loadingBankAccounts = useSelector(fetchingBankAccounts)
  const bankAccountsFetchError = useSelector(getBankAccountsError)
  const location = useSelector(getLocation)
  useFetchPaginatedResults('bankAccount')
  const handleDownloadCSV = useExportToCSV(selection)

  const headers = useMemo(() => {
    const tranlsatedHeaders = HEADERS.map(({ label, field } = {}) => ({
      label: t(label),
      ...(field && { field }),
    }))
    tranlsatedHeaders.push({
      label: (
        <Checkbox
          onChange={(_, { checked }) => setSelection(checked ? bankAccounts.map(({ id }) => id).toArray() : [])}
          checked={selection.length === bankAccounts.size}
        />
      ),
    })
    return tranlsatedHeaders
  }, [bankAccounts, selection.length, t])

  function handleSelection(checked, bankAccountId) {
    setSelection(checked ? [...selection, bankAccountId] : selection.filter((id) => id !== bankAccountId))
  }

  return (
    <div>
      <Paginate count={total} previous next loading={loadingBankAccounts}>
        <Button type='submit' disabled={selection.length === 0} onClick={handleDownloadCSV}>
          {t('BankAccounts::Export selection to CSV')}
        </Button>

        <List striped celled sortable headers={headers} location={location}>
          {bankAccounts.toList().map((bankAccount) => (
            <BankAccountRow
              key={bankAccount.id}
              bankAccount={bankAccount}
              onSelect={handleSelection}
              selected={selection.includes(bankAccount.id)}
            />
          ))}
        </List>
      </Paginate>
      <ApiErrorMessage error={bankAccountsFetchError} modal />
    </div>
  )
}
