import React from 'react'
import { CategoriesListSearchForm } from './CategoriesListSearchForm'
import { CategoriesList } from './CategoriesList'

export function CategoriesIndex() {
  return (
    <div>
      <CategoriesListSearchForm />
      <CategoriesList />
    </div>
  )
}
