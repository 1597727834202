import React, { useEffect, useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { getTag } from 'redux/entities/selectors'
import { fetchTag } from 'redux/entities/actions'
import { Grid } from 'semantic-ui-react'
import EditPageMenu from 'components/menus/EditPageMenu'
import { TagForm } from './Form'
import { TagCard } from './Card'

export function TagFormWrapper({ params: { id } }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const tag = useSelector((state) => getTag(state, { id }))

  const menuItems = useMemo(
    () => [
      { text: t('Tags::Summary::Title'), value: 'title' },
      { text: t('Tags::Summary::Category'), value: 'category' },
      { text: t('Tags::Summary::Description'), value: 'description' },
    ],
    [t],
  )

  useEffect(() => {
    if (tag.id) dispatch(fetchTag(id))
  }, [dispatch, id, tag.id])

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={3}>
          <EditPageMenu listName='tags' menuItems={menuItems} />
        </Grid.Column>
        <Grid.Column width={8}>
          <TagForm tag={tag} />
        </Grid.Column>
        <Grid.Column width={3}>
          <TagCard tag={tag} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

TagFormWrapper.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
}
