import { getEntities, getEntity, getEntityOrder, getEntityTotal, isFetching, getError } from './_utils'
import { getUser } from './users'
export const getBankAccounts = getEntities('bankAccounts')
export const getBankAccount = getEntity('bankAccounts')
export const getBankAccountsOrder = getEntityOrder('bankAccounts')
export const getBankAccountsList = (state) => getBankAccountsOrder(state).map((id) => getBankAccount(state, { id }))
export const getBankAccountsTotal = getEntityTotal('bankAccounts')

export const getBankAccountsWithGivenIds = (state, ids) => {
  const bankAccounts = getBankAccounts(state)
  return bankAccounts
    .filter(({ id }) => ids.includes(id))
    .map((ba) => ba.set('user', getUser(state, { id: ba.user })))
    .toList()
}

export const fetchingBankAccounts = (state) => isFetching(`/api/bank_accounts`)(state)

export const getBankAccountsError = getError('/api/bank_accounts')
export const getBankAccountsViewPermissionError = getError('/api/bank_accounts')
