import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { fetchEvents } from 'redux/entities/actions'
import { fetchingEvents, getEventsError, getEventsFromIdsArray } from 'redux/entities/selectors'
import { Form } from 'semantic-ui-react'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { getLocalityAndCountry } from 'helpers/places'

export function EventSection({ isDisabled, onEventChange, host, event, canShowLabel, filterTags, fullWidth }) {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const [fetchedEventIds, setFetchedEventIds] = useState([])

  const isFetchingExperiences = useSelector(fetchingEvents)
  const fetchExperienceError = useSelector(getEventsError)
  const fetchedExperiences = useSelector((reducerState) => getEventsFromIdsArray(reducerState, fetchedEventIds))

  const eventOptions = useMemo(
    () =>
      fetchedExperiences.toJS().map((event) => ({
        text: `${event.id} - ${event.title}${event.place ? ` - ${getLocalityAndCountry(event.place)}` : ''} - ${
          event.max_seats
        } seats`,
        value: event.id,
      })),
    [fetchedExperiences],
  )

  useEffect(() => {
    if (host?.id) {
      const query = { host: host.id, status: 'approved', tags: filterTags.join(','), size: 50 }
      dispatch(fetchEvents({ query })).then(({ payload: { data } }) => {
        if (data.result?.events) setFetchedEventIds(data.result.events)
      })
    }
  }, [dispatch, filterTags, host?.id])

  function handleEventChange(_, { value: id }) {
    const selectedEvent = fetchedExperiences.find((ev) => ev.id === id)
    onEventChange(selectedEvent)
  }

  return (
    <React.Fragment>
      <Form.Field required className='__item' width={fullWidth ? 16 : 13}>
        {canShowLabel && <label>{t('EventsCalendar::Event')}</label>}
        <Form.Dropdown
          placeholder={t('EventsCalendar::Event name, event id')}
          search
          fluid
          selection
          options={eventOptions}
          onChange={handleEventChange}
          disabled={isDisabled}
          noResultsMessage={
            isFetchingExperiences ? t('EventsCalendar::Loading...') : t('EventsCalendar::No results found.')
          }
          value={event?.id}
        />
      </Form.Field>
      <ApiErrorMessage error={fetchExperienceError} modal />
    </React.Fragment>
  )
}

EventSection.propTypes = {
  isDisabled: PropTypes.bool,
  onEventChange: PropTypes.func.isRequired,
  filterTags: PropTypes.arrayOf(PropTypes.string),
  canShowLabel: PropTypes.bool,
  fullWidth: PropTypes.bool,
  host: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    max_seats: PropTypes.number.isRequired,
    place: PropTypes.shape(),
    tags: PropTypes.arrayOf(PropTypes.string),
  }),
}

EventSection.defaultProps = {
  isDisabled: true,
  canShowLabel: false,
  host: undefined,
  event: undefined,
  filterTags: [],
  fullWidth: false,
}
