// libs
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetchPaginatedResults } from '../../hooks/pagination/useFetchPaginatedResults'
import { useSelector } from 'react-redux'
// components
import { List, Paginate } from 'components/lists'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { PlaylistRow } from './PlaylistRow'
// redux
import {
  getFetchPlaylistsError,
  fetchingPlaylists,
  getPlaylistsList,
  getPlaylistsTotal,
} from 'redux/entities/selectors'
import { getLocation } from 'redux/reducers/router'

export default function PlaylistList() {
  const { t } = useTranslation()
  const playlists = useSelector(getPlaylistsList)
  const total = useSelector(getPlaylistsTotal)
  const location = useSelector(getLocation)
  const loading = useSelector(fetchingPlaylists)
  const fetchError = useSelector(getFetchPlaylistsError)
  useFetchPaginatedResults('playlist')

  const headers = useMemo(
    () => [
      { label: t('Playlists::Id'), field: 'id' },
      { label: t('Playlists::Name') },
      { label: t('Playlists::Tag') },
      { label: t('Playlists::Location') },
      { label: t('Playlists::Selected events') },
      { label: t('Playlists::Order'), field: 'order' },
      { label: t('Playlists::Type') },
      { label: t('Playlists::Visibility') },
      { label: t('Playlists::Created'), field: 'created_at' },
      { label: t('Playlists::Updated'), field: 'updated_at' },
      { label: t('Playlists::Deleted'), field: 'deleted_at' },
      { label: t('Playlists::Actions') },
    ],
    [t],
  )

  return (
    <Paginate count={total} previous next loading={loading}>
      <List striped celled sortable headers={headers} location={location}>
        {playlists.toList().map((playlist) => (
          <PlaylistRow key={playlist.id} playlist={playlist} />
        ))}
      </List>
      <ApiErrorMessage error={fetchError} modal />
    </Paginate>
  )
}
