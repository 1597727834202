import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { Button, Card, CardMeta, CardHeader, CardContent, CardDescription, Icon, Label } from 'semantic-ui-react'
import { Link } from 'react-router'
import { connectAs } from 'helpers/users'

export function PartnerCard({ partner }) {
  const { t } = useTranslation()

  return (
    <Card>
      <CardContent>
        <CardHeader>{`${partner.id} - ${partner.firstname} ${partner.lastname}`}</CardHeader>
        <CardMeta>
          <Icon name='mail' style={{ marginRight: '4px' }} />
          {partner.account.email}
        </CardMeta>
        <CardDescription>
          <div>
            <Icon name='phone' />
            {partner.phone ? (
              partner.phone
            ) : (
              <Label color='red' horizontal>
                {t('Labels::not set')}
              </Label>
            )}
          </div>
          <div>
            <Icon name='dollar' />
            {partner.currency.iso_3}
          </div>
        </CardDescription>
      </CardContent>
      <CardContent extra>
        <div className='ui two buttons'>
          <Button
            basic
            color='blue'
            as={Link}
            to={{ pathname: '/partners', query: { search: partner.account.email } }}
            content={t('UserCard::View in list')}
          />
          <Button
            basic
            color='blue'
            onClick={() => connectAs(partner.account_id)}
            content={t('EventsCalendar::Connect As')}
          />
        </div>
      </CardContent>
    </Card>
  )
}

PartnerCard.propTypes = {
  partner: PropTypes.shape({
    id: PropTypes.number,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    account_id: PropTypes.number,
    locality: PropTypes.string,
    country: PropTypes.string,
    phone: PropTypes.string,
    account: PropTypes.shape({
      email: PropTypes.string,
    }),
    currency: PropTypes.shape({
      iso_3: PropTypes.sring,
    }),
  }).isRequired,
}
