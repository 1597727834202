import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const usersActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'users' })

export const fetchUsers = ({ query } = {}) =>
  usersActions.fetch({
    url: '/api/users',
    query,
    schema: { users: [schemas.users] },
  })

export const fetchUser = (id, { query } = {}) =>
  usersActions.fetch({
    url: `/api/users/${id}`,
    query,
    schema: { user: schemas.users },
  })

export const updateUser = (id, payload, { query } = {}) =>
  usersActions.update({
    url: `/api/users/${id}`,
    payload,
    query,
    schema: { user: schemas.users },
  })

export const deleteUser = (id) =>
  usersActions.delete({
    url: `/api/users/${id}`,
    deleted: { users: [id] },
    schema: { user: schemas.users },
  })

export const fetchUserBankAccounts = (userId) =>
  usersActions.fetch({
    url: `/api/users/${userId}/bank_accounts`,
    schema: {
      bankAccounts: [schemas.bankAccounts],
    },
  })
