// libs
import React, { useState } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
// components
import { Autocomplete } from '@vizeat/components/es6/components/Autocomplete'
import { CopyToClipboardButton } from 'components/buttons'
import { Flex } from '@vizeat/components/es6/components/Flex'
// helpers
import { debouncedfetchAutocomplete as fetchAutocomplete, fetchPlace } from 'helpers/places'

export const EditPlaceInput = ({ defaultValue, onCancel, onEdit, forced, onPlaceChanged }) => {
  const { t, i18n } = useTranslation()
  const [editable, setEditable] = useState(false)

  const handleCancel = (e) => {
    e.preventDefault()
    setEditable(false)
    onCancel()
  }

  const handleEdit = (e) => {
    e.preventDefault()
    setEditable(true)
    onEdit()
  }

  const onChange = async (data) => {
    if (data?.item?.provider_id) {
      const place = await fetchPlace({ id: data.item.provider_id, language: i18n.language })
      data.item.lat = place?.lat
      data.item.lng = place?.lng
    }

    onPlaceChanged(data)
  }

  return editable ? (
    <div>
      <Autocomplete
        initialValue={defaultValue.formatted}
        initialItem={defaultValue.toJS ? defaultValue.toJS() : defaultValue}
        getItems={async (value) => fetchAutocomplete({ query: value, language: i18n.language })}
        itemToString={(item) => (item && item.formatted) || ''}
        typeahead={!forced}
        onChange={onChange}
      />
      {/* Should be replaced by <button /> or <span role="button" /> */}
      <a href='#' onClick={handleCancel}>
        {t('Inputs::Cancel')}
      </a>
    </div>
  ) : (
    <Flex alignItems='center'>
      {defaultValue.formatted}
      <a href='#' onClick={handleEdit} style={{ display: 'inline-block', marginLeft: 8 }}>
        {t('Inputs::Edit')}
      </a>
      <CopyToClipboardButton text={defaultValue.formatted || ''} style={{ position: 'static', marginLeft: 16 }} />
    </Flex>
  )
}

EditPlaceInput.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.shape({ formatted: PropTypes.string }), PropTypes.immutable.record]),
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  forced: PropTypes.bool,
  onPlaceChanged: PropTypes.func.isRequired,
}

EditPlaceInput.defaultProps = {
  defaultValue: {},
  forced: false,
}
