// Libs
import React, { useState, useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useToggle } from '@vizeat/components/es6/hooks'
import { useHasLoadingSucceeded } from 'hooks'
// Components
import { Link } from 'react-router'
import { Grid, Modal, Button, Message, Divider } from 'semantic-ui-react'
import Sticky from 'react-stickynode'
import { EventIdMenu } from './Menu'
import { UserCard } from 'components/cards/UserCard'
import { EventIdUserForm } from './UserForm'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { EventForm } from './Form'
// Redux
import { fetchEvent, fetchUser, fetchUserFiles } from 'redux/entities/actions'
import { getEvent, fetchingEvent, getEventError } from 'redux/entities/selectors'
import { connectAs } from 'helpers/users'

export default function EventIdLayout({ params: { id } }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showModal, { toggle: toggleModalVisibility }] = useToggle()

  const [duplicatedEventId, setDuplicatedEventId] = useState()

  const event = useSelector((state) => getEvent(state, { id }))
  const isFetchingEvent = useSelector((state) => fetchingEvent(state, id))
  const error = useSelector((state) => getEventError(state, id))
  const isEventFetched = useHasLoadingSucceeded(isFetchingEvent, error)

  useEffect(() => {
    dispatch(fetchEvent(id))
  }, [dispatch, id])

  useEffect(() => {
    if (isEventFetched && !!event.user?.id) {
      dispatch(fetchUser(event.user.id))
      dispatch(fetchUserFiles(event.user.id))
    }
  }, [dispatch, event.user.id, isEventFetched])

  return (
    <Grid padded>
      <Modal open={showModal} onClose={toggleModalVisibility} closeIcon size='small'>
        <Modal.Header>
          {t('Experiences::Summary::Edit user n° {{userId}}: {{firstName}} {{lastName}}', {
            userId: event?.user?.id,
            firstName: event?.user?.firstname,
            lastName: event?.user?.lastname,
          })}
        </Modal.Header>
        <Modal.Content>
          <EventIdUserForm userId={event?.user?.id} />
        </Modal.Content>
      </Modal>

      <Grid.Row>
        <Grid.Column width={3}>
          <Sticky>
            <EventIdMenu event={event} onDuplicateEvent={setDuplicatedEventId} />
          </Sticky>
        </Grid.Column>

        <Grid.Column width={10}>
          {!!duplicatedEventId && (
            <Message info>
              <Message.Content>
                {t(
                  'Experiences::Summary::This experience has been duplicated from the experience n° {{experienceId}}',
                  { experienceId: duplicatedEventId },
                )}
              </Message.Content>
            </Message>
          )}

          {!!event.id && <EventForm event={event} />}
        </Grid.Column>

        <Grid.Column width={3}>
          <Sticky top={15}>
            {event?.user?.id && (
              <UserCard user={event.user} connectAs={() => connectAs(event.user.account_id)}>
                <div style={{ textAlign: 'center' }}>
                  <Button as={Link} primary basic compact to={{ pathname: `/users/${event.user.id}/bank-accounts` }}>
                    {t('UserCard::View payout details')}
                  </Button>
                  <Divider horizontal />
                  <Button onClick={toggleModalVisibility}>{t('Experiences::Summary::Edit user')}</Button>
                </div>
              </UserCard>
            )}
          </Sticky>
        </Grid.Column>
      </Grid.Row>
      <ApiErrorMessage error={error} modal />
    </Grid>
  )
}

EventIdLayout.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
}
