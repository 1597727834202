// Libs
import React, { useEffect, useState } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Container, Divider } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import first from 'lodash/first'
import { useTranslation } from 'react-i18next'
// Components
import { DemandDetails } from './DemandDetails'
import { DemandBookings } from './DemandBookings'
import { DemandRequests } from './DemandRequests'
import { FollowupsList } from 'components/followups'
import { FormModal } from 'components/modals'
import { InvoiceBookingForm, BatchRequestForm } from 'pages/events-calendar/privateEventForm'
import { PartnerDemandForm } from '../components/PartnerDemandForm'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
// Redux
import { fetchPartnerDemand, updatePartnerDemand } from 'redux/entities/actions'
import {
  getPartnerDemand,
  getPartnerDemandBookingsValidatedSeats,
  getPartnerDemandRequestedSeats,
  updatePartnerDemandError,
  updatingPartnerDemand,
} from 'redux/entities/selectors'
// Hooks
import { useHasLoadingSucceeded } from 'hooks'
// Helpers
import { numbers } from '@vizeat/helpers'

const { formatIntegerPrice } = numbers

export function PartnerDemandPage({ params: { id } }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const demand = useSelector((state) => getPartnerDemand(state, { id }))
  const isUpdatingDemand = useSelector((state) => updatingPartnerDemand(state, id))
  const partnerDemandError = useSelector((state) => updatePartnerDemandError(state, id))
  const requestedSeats = useSelector((state) => getPartnerDemandRequestedSeats(state, { id }))
  const validatedSeats = useSelector((state) => getPartnerDemandBookingsValidatedSeats(state, { id }))

  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isInvoiceModalVisible, setIsInvoiceModalVisible] = useState(false)
  const [isRequestModalVisible, setIsRequestModalVisible] = useState(false)
  const isUpdatingSucceded = useHasLoadingSucceeded(isUpdatingDemand, partnerDemandError)

  useEffect(() => {
    if (id) dispatch(fetchPartnerDemand(id))
  }, [id, dispatch])

  useEffect(() => {
    if (isUpdatingSucceded) {
      toast.success('The partner demand has been correctly saved 👍', { type: toast.TYPE.SUCCESS })
      setIsEditModalVisible(false)
    }
  }, [isUpdatingSucceded])

  function handleRequestSubmit() {
    setIsRequestModalVisible(false)
    dispatch(fetchPartnerDemand(id))
  }

  function handleInvoiceSubmit() {
    setIsInvoiceModalVisible(false)
    dispatch(fetchPartnerDemand(id))
  }

  function submitNewPartnerDemand(partnerDemand) {
    dispatch(
      updatePartnerDemand(id, {
        age_interval: partnerDemand.ageInterval,
        begins_at: partnerDemand.beginsAt,
        country_iso: partnerDemand.countryIso,
        currency_id: partnerDemand.currencyId,
        date: first(partnerDemand.selectedDays) || partnerDemand.date,
        event_type: partnerDemand.eventType,
        locality: partnerDemand.locality,
        price: partnerDemand.price,
        seats: partnerDemand.seats,
        free_seats: partnerDemand.freeSeats,
        status: partnerDemand.status,
      }),
    )
  }

  if (!demand.id) return null
  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <FormModal
          openModal={() => setIsEditModalVisible(true)}
          closeModal={() => setIsEditModalVisible(false)}
          isOpen={isEditModalVisible}
          btnTitle={t('PartnerDemands::Summary::Edit Demand')}
          headerTitle={t('PartnerDemands::Summary::Edit partner demand')}
        >
          <PartnerDemandForm isEdit partnerDemand={demand} handleFormSubmission={submitNewPartnerDemand} />
          <ApiErrorMessage error={partnerDemandError} noStack />
        </FormModal>

        <FormModal
          openModal={() => setIsRequestModalVisible(true)}
          closeModal={() => setIsRequestModalVisible(false)}
          isOpen={isRequestModalVisible}
          btnTitle={t('PartnerDemands::Summary::PE Requests')}
          headerTitle={t('PartnerDemands::Summary::Create Private Event Requests')}
        >
          <BatchRequestForm
            guest={demand.reference.partner.user}
            requestDate={demand.date}
            onSubmit={handleRequestSubmit}
            partnerDemandId={demand.id}
            locality={demand.locality}
          />
        </FormModal>
        <FormModal
          openModal={() => setIsInvoiceModalVisible(true)}
          closeModal={() => setIsInvoiceModalVisible(false)}
          isOpen={isInvoiceModalVisible}
          btnTitle={t('PartnerDemands::Summary::Invoice Booking')}
          headerTitle={t('PartnerDemands::Summary::Create a new invoice booking')}
        >
          <InvoiceBookingForm
            btnSubmitTitle={t('PartnerDemands::Summary::Create Private Event paid by invoice')}
            customPricingMessage={t("PartnerDemands::Summary::The demand's partner price is: {{price}}", {
              price: formatIntegerPrice(demand.price, 'en', demand.currency),
            })}
            date={demand.date}
            bookAs={demand.reference.partner.user}
            handleSubmit={handleInvoiceSubmit}
            partnerDemandId={demand.id}
            referenceCode={demand.reference.code}
          />
        </FormModal>
      </div>
      <Grid>
        <Grid.Row centered>
          <Grid.Column>
            <h2>{t('PartnerDemands::Summary::Details')}</h2>
            <DemandDetails demand={demand} requestedSeats={requestedSeats} validatedSeats={validatedSeats} />
          </Grid.Column>
        </Grid.Row>

        <Divider />

        <Grid.Row centered>
          <Grid.Column>
            <h2>
              {t('PartnerDemands::Summary::Bookings (validated {{validatedSeats}} / {{demandedSeats}})', {
                validatedSeats,
                demandedSeats: demand.seats,
              })}
            </h2>
            <DemandBookings demandId={demand.id} />
          </Grid.Column>
        </Grid.Row>

        {demand.requests.size > 0 && (
          <React.Fragment>
            <Divider />

            <Grid.Row centered>
              <Grid.Column>
                <h2>
                  {t('PartnerDemands::Summary::Requests (requested {{requestedSeats}} / {{demandedSeats}})', {
                    requestedSeats,
                    demandedSeats: demand.seats,
                  })}
                </h2>
                <DemandRequests demand={demand} />
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        )}

        <Divider />

        <Grid.Row centered>
          <Grid.Column>
            <h2>{t('PartnerDemands::Summary::Followups')}</h2>
            <FollowupsList followups={demand.followups} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  )
}

PartnerDemandPage.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}
