import moment from 'moment'
import {
  BOOKING_DEADLINE_OPTIONS,
  CANCELLATION_DEADLINE_OPTIONS,
  DEFAULT_NO_CANCELLATION_DEADLINE,
  PE_CANCELLATION_DEADLINE_OPTIONS,
} from './konstants.js'

export const UNITS = ['years', 'months', 'weeks', 'days', 'hours', 'minutes']

type Config = {
  shouldIgnoreWeeks?: boolean
  currentDeadline?: string
  isPrivate?: boolean
  max?: number
  min?: number
  formatText?: (unit: string, count: number) => string
}
/**
 * Decomposes a duration into its composing time units
 * @example 'P50D18H' -> { years: 0 months: 1, weeks: 1 days: 10, hours: 18, minutes: 0 }
 * @param   {String} duration  A iso string representing a duration (eg. 'PT36H', 'P10D')
 * @param   {Object} config    Config possibilities, such as to group days by weeks or not
 * @return  {Object}           Decomposition of moment duration in all different time units
 */
export function decomposeDuration(duration: string | number, config: Config = {}): { [unit: string]: number } {
  const { shouldIgnoreWeeks } = config
  const momentDuration = moment.duration(duration)
  return UNITS.reduce((acc, unit) => {
    if (shouldIgnoreWeeks && unit === 'weeks') return acc
    let count = momentDuration.get(unit as moment.unitOfTime.Base)
    // Moment does not handle automatically grouping days in weeks, same as it does for months
    // Ensure 'P10D' is decomposed in '1 week 3 days' intead of '10 days'
    if (!shouldIgnoreWeeks && unit === 'days') {
      const weeks = momentDuration.get('weeks')
      if (weeks > 0) count -= weeks * 7
    }
    return count <= 0 ? acc : { ...acc, [unit]: count }
  }, {} as { [unit: string]: number })
}

/**
 * Decomposes the duration and formats it a string
 * @example 'P50H' -> '2 days 2 hours'
 * @param   {String}         duration  A iso string representing a duration (eg. 'PT36H', 'P10D')
 * @param   {Object}         config    Config possibilites, handle formatting for decomposed duration and if should group by weeks or not
 * @return  {String | Null}            Formatted decomposed duration
 */
export function formatDuration(duration: string | number, config: Config = {}): string | null {
  const { formatText = (unit, count) => `${count} ${unit}` } = config
  const decomposed = decomposeDuration(duration, config)
  if (Object.keys(decomposed).length === 0) return null
  return Object.entries(decomposed)
    .map(([unit, count]) => formatText(unit, count))
    .join(' ')
}

/**
 * Takes a list of deadlines and returns unique, filtered and sorted list of "valueOf" input deadlines
 * @param  {Array}   deadlines  List of deadlines
 * @param  {Object}  config     Handles optional filtering, max, min bounds (inclusive)
 * @return {Array}              Unique, filtered and sorted list of "valueOf" input deadlines
 */
export function sanitizeDeadlines(deadlines: string[], config: Config = {}): number[] {
  const { max, min } = config
  function deadlineFilter(value: number) {
    if (max !== undefined && value > (moment.duration(max).valueOf() as number)) return false
    if (min !== undefined && value < (moment.duration(min).valueOf() as number)) return false
    return true
  }
  return deadlines
    .filter((d) => d) // filter null, undefined values to prevent creating undesired moment objects
    .map((d) => moment.duration(d).valueOf() as number) // transform in comparable elements
    .filter(deadlineFilter) // remove elements outisde of range
    .reduce<number[]>((unique, d) => (unique.includes(d) ? unique : [...unique, d]), []) // keep unique values
    .sort((a, b) => a - b) // sort elements incremetally
}

export function isCancellationDeadlineNonRefundable(duration: string): boolean {
  return moment.duration(duration).valueOf() === moment.duration(DEFAULT_NO_CANCELLATION_DEADLINE).valueOf()
}

export function formatDeadlines(
  deadlines: string[],
  config: Config = {},
): { duration: number; formattedDuration: string | null }[] {
  const allOptions = [...deadlines, config.currentDeadline as string]
  return sanitizeDeadlines(allOptions, config).map((duration) => ({
    duration,
    formattedDuration: formatDuration(duration, config),
  }))
}

export function formatBookingDeadlines(config: Config = {}): ReturnType<typeof formatDeadlines> {
  return formatDeadlines(BOOKING_DEADLINE_OPTIONS, config)
}

export function formatCancellationDeadlines(
  config: Config = {},
): { duration: number; formattedDuration: string | null }[] {
  return formatDeadlines(config.isPrivate ? PE_CANCELLATION_DEADLINE_OPTIONS : CANCELLATION_DEADLINE_OPTIONS, config)
}
