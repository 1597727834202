import React, { useState, useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { withRouter, Link } from 'react-router'
import { usePrevious } from 'hooks/usePrevious'
import { Form, Button, Checkbox } from 'semantic-ui-react'
import { SearchInput, SelectInput } from 'components/inputs'
import { getLocation } from 'redux/reducers/router'
import { getSortedTags } from 'redux/entities/selectors'
import { fetchTags } from 'redux/entities/actions'
import { konstants } from '@vizeat/helpers'
import { buildOptionsWithArray } from 'helpers/forms'

const INITIAL_STATE = {
  category: undefined,
  readonly: undefined,
  search: undefined,
  showArchivedOnly: undefined,
  tags: undefined,
  visibility: undefined,
}

function _CategoriesListSearchForm({ router }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const tags = useSelector(getSortedTags)
  const location = useSelector(getLocation)
  const prevLocation = usePrevious(location)

  const [state, setState] = useState(INITIAL_STATE)

  const visibilityOptions = useMemo(
    () => [
      { text: '', value: '' },
      { text: t('Categories::Public'), value: konstants.CATEGORY_VISIBILITIES.PUBLIC },
      { text: t('Categories::Private'), value: konstants.CATEGORY_VISIBILITIES.PRIVATE },
      { text: t('Categories::Partner'), value: konstants.CATEGORY_VISIBILITIES.PARTNER },
    ],
    [t],
  )

  const readonlyOptions = useMemo(
    () => [
      { text: t('Categories::All'), value: undefined },
      { text: t('Categories::Mutable'), value: 'false' },
      { text: t('Categories::Immutable'), value: 'true' },
    ],
    [t],
  )

  const updateStateFromLocation = useCallback(() => {
    setState({
      category: location.getIn(['query', 'category'], undefined),
      readonly: location.getIn(['query', 'readonly'], undefined),
      search: location.getIn(['query', 'search'], undefined),
      showArchivedOnly: location.getIn(['query', 'showArchivedOnly'], undefined),
      tags: location.getIn(['query', 'tags'], undefined),
      visibility: location.getIn(['query', 'visibility'], undefined),
    })
  }, [location])

  useEffect(() => {
    // if the category page is the first one visited, tags must be fetched to be shown as options
    // in the filter's select, otherwise they've been initially fetched through all categories in
    // the Authenticate component
    dispatch(fetchTags())
  }, [dispatch])

  useEffect(() => {
    if (!location.equals(prevLocation)) updateStateFromLocation()
  }, [location, prevLocation, updateStateFromLocation])

  function updateQuery(query = state) {
    router.push(location.mergeIn(['query'], { ...query, offset: 0 }).toJS())
  }

  function clearFilters() {
    setState(INITIAL_STATE)
    updateQuery(INITIAL_STATE)
  }

  function clearSorting() {
    router.push(location.mergeIn(['query'], { sortBy: '', order: '' }).toJS())
  }

  function handleSubmit(e) {
    e.preventDefault()
    updateQuery()
  }

  return (
    <Form onSubmit={handleSubmit} style={{ marginBottom: '1em' }} autoComplete='off'>
      <Form.Group>
        <Form.Field>
          <label>{t('Categories::Search by category id or title')}</label>
          <SearchInput
            placeholder={t('Categories::Title')}
            fluid
            name='search'
            icon='search'
            iconPosition='left'
            value={state.search || ''}
            onChange={(search) => setState((prevState) => ({ ...prevState, search }))}
          />
        </Form.Field>

        <Form.Field>
          <label>{t('Categories::Tags')}</label>
          <SelectInput
            queryName='tags'
            search
            options={buildOptionsWithArray(tags.map(({ title }) => title))}
            value={state.tags || ''}
            onChange={(tags) => setState((prevState) => ({ ...prevState, tags: tags.join(',') }))}
            multiple
            preventNavigation
          />
        </Form.Field>

        <Form.Field>
          <label>{t('Categories::Visibility')}</label>
          <SelectInput
            queryName='visibility'
            search
            options={visibilityOptions}
            value={state.visibility || ''}
            onChange={(visibility) => setState((prevState) => ({ ...prevState, visibility }))}
            preventNavigation
          />
        </Form.Field>

        <Form.Field>
          <label>{t('Categories::Type')}</label>
          <SelectInput
            queryName='readonly'
            search
            options={readonlyOptions}
            value={state.readonly}
            onChange={(readonly) => setState((prevState) => ({ ...prevState, readonly }))}
            preventNavigation
          />
        </Form.Field>

        <Form.Field>
          <label>{t('Categories::Show archived categories')}</label>
          <Checkbox
            onChange={(_, { checked }) => setState((prevState) => ({ ...prevState, showArchivedOnly: checked }))}
            checked={String(state.showArchivedOnly) === 'true'}
          />
        </Form.Field>

        <Form.Field style={{ display: 'flex', alignItems: 'flex-end', gap: '24px' }}>
          <label>&nbsp;</label>
          <Button type='submit'>{t('Categories::Search')}</Button>

          <Button.Group vertical basic compact size='small'>
            <Button basic onClick={clearFilters}>
              {t('Categories::Clear filters')}
            </Button>
            <Button onClick={clearSorting}>{t('Categories::Clear sorting')}</Button>
          </Button.Group>

          <Button as={Link} to='/tags/add' primary>
            {t('Categories::Create new Tag')}
          </Button>
        </Form.Field>
      </Form.Group>
    </Form>
  )
}

_CategoriesListSearchForm.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

export const CategoriesListSearchForm = withRouter(_CategoriesListSearchForm)
