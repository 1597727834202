import { Map } from 'immutable'
import {
  getEntities,
  getEntity,
  getEntityCount,
  isFetching,
  isUpdating,
  isDeleting,
  getError,
  getEntityOrder,
  getEntityTotal,
} from './_utils'

import { getAccount } from './accounts'
import { getEvent, getEventsFromIdsArray } from './events'
import { getFiles, getFile } from './files'
import { getBankAccounts } from './bankAccounts'
import { getLanguage, getCurrency } from './statics'

export const getUsers = getEntities('users')
export const getUsersCount = getEntityCount('users')
export const getUsersOrder = getEntityOrder('users')
export const getUsersTotal = getEntityTotal('users')
export const getUser = (state, { id, raw = false }) => {
  const user = getEntity('users')(state, { id })
  return raw
    ? user
    : user
        .set('account', getAccount(state, { id: user.account_id || user.account }))
        .set('avatar', getFile(state, { id: user.avatar_id || user.avatar }))
        .set('currency', getCurrency(state, { id: user.currency_id || user.currency }))
        .set(
          'languages',
          user.languages.map((id) => getLanguage(state, { id })),
        )
}
export const getUsersList = (state) =>
  getUsersOrder(state)
    .map((id) => getUser(state, { id }))
    .filter(({ id }) => !!id) // has to filter after because order is not impacted by deletetion...

export function getUserWithEvents(state, { id }) {
  const user = getUser(state, { id })
  return user.set(
    'events',
    user.events.map((id) => getEvent(state, { id })),
  )
}

export function getUserGallery(state, { id }) {
  const user = getUser(state, { id })
  const accountId = user.account_id || user.account.id
  if (!accountId) return new Map()
  return getFiles(state)
    .filter((file) => file.account_id === accountId)
    .sortBy((f) => f.id)
}

export function getUserBankAccounts(state, { id }) {
  const bankAccounts = getBankAccounts(state)
  return bankAccounts.filter((ba) => ba.user === parseInt(id, 10)).toList()
}

export function getUsersWithGivenIds(state, ids) {
  const users = getUsers(state)
  return users
    .filter(({ id }) => ids.includes(id))
    .map((user) => user.set('account', getAccount(state, { id: user.account_id || user.account })))
    .toList()
}

export function getUsersFromExperienceIds(state, eventsIds = []) {
  const events = getEventsFromIdsArray(state, eventsIds)
  return events.map(({ user }) => user).toList()
}

export const fetchingUser = (state, id) => isFetching(`/api/users/${id}`)(state)
export const fetchingUsers = isFetching('/api/users')
export const fetchingUserBankAccounts = (state, userId) => isFetching(`/api/users/${userId}/bank_accounts`)(state)
export const updatingUser = (state, id) => isUpdating(`/api/users/${id}`)(state)
export const deletingUser = (state, id) => isDeleting(`/api/users/${id}`)(state)

export const getUsersError = getError('/api/users')
export const getUserError = (state, id) => getError(`/api/users/${id}`)(state)
export const getUserBankAccountsError = (state, userId) => getError(`/api/users/${userId}/bank_accounts`)(state)
