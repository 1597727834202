// libs
import React, { PureComponent, useState } from 'react'
import { connect } from 'react-redux'
import { fromJS, List } from 'immutable'
import { useTranslation, withTranslation } from 'react-i18next'
// helpers
import PropTypes from 'helpers/proptypes'
import { getTranslatedOptions } from 'helpers/options'
import { getDescriptionSkeleton, getDescriptionItemSkeleton, mealTypesOptions } from 'helpers/events'
import { base64ToBlob, downloadFromBlob } from 'helpers/files'
import { screenApi } from 'helpers/api'
// components
import { Form, Button, Input } from 'semantic-ui-react'
import { TabsDescriptionsByLang } from 'components/inputs/TabsDescriptionsByLang'
import { CopyToClipboardButton, IconButton } from 'components/buttons'
// styles
import './index.css'
// redux
import { mergeInForm } from 'redux/forms/actions'
import { getForm } from 'redux/forms/reducer'
import { getSortedLanguages, getLanguage } from 'redux/entities/selectors'
import { useIsMounted } from 'hooks/useIsMounted'

const mapStateToProps = (state) => ({
  fromStore: {
    form: getForm(state, 'event'),
    languages: getSortedLanguages(state),
    getLanguage: (id) => getLanguage(state, { id }),
  },
})
const mapDispatchToProps = (dispatch) => ({
  actions: {
    mergeInForm: (formData) => dispatch(mergeInForm({ formName: 'event', value: formData })),
  },
})

const eventDescriptionSkeleton = fromJS(getDescriptionSkeleton())
const eventDescriptionItemSkeleton = fromJS(getDescriptionItemSkeleton())

const DownloadMenuButton = ({ event }) => {
  const { t } = useTranslation()
  const isMounted = useIsMounted()
  const [isLoading, setIsLoading] = useState(false)

  const handleDownloadMenu = async () => {
    setIsLoading(true)

    const response = await screenApi.get(`/events/${event.id}/menu`)
    if (response?.data) {
      const blob = base64ToBlob(response.data, 'application/pdf')
      const title = `${event.title.replace(/\s/g, '-')}_Menu.pdf`
      downloadFromBlob(blob, title)
    }

    if (isMounted()) setIsLoading(false)
  }

  return (
    <IconButton
      name={isLoading ? 'spinner' : 'file pdf'}
      onClick={handleDownloadMenu}
      disabled={isLoading}
      tooltipContent={t('Experiences::Download menu')}
      size='big'
      loading={isLoading}
    />
  )
}

DownloadMenuButton.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
}

class _SectionDescription extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    event: PropTypes.shape({
      tags: PropTypes.object,
      frozen_at: PropTypes.date,
      user: PropTypes.immutable.record.isRequired,
      id: PropTypes.number,
    }).isRequired,
    fromStore: PropTypes.shape({
      form: PropTypes.immutable.map,
      languages: PropTypes.immutable.list,
      getLanguage: PropTypes.func,
    }).isRequired,
    actions: PropTypes.shape({
      mergeInForm: PropTypes.func,
    }).isRequired,
  }

  generateDescriptionChange = ({ description, field }) => {
    const {
      fromStore: { form },
      actions,
    } = this.props
    const descriptionIndex = form.get('descriptions').indexOf(description)

    return (e, { value }) =>
      actions.mergeInForm({
        descriptions: form.get('descriptions').mergeIn([descriptionIndex], { [field]: value || undefined }),
      })
  }

  generateDescriptionItemChange = ({ description, item, field }) => {
    const {
      fromStore: { form },
      actions,
    } = this.props
    const descriptionIndex = form.get('descriptions').indexOf(description)
    const itemIndex = form.getIn(['descriptions', descriptionIndex, 'items']).indexOf(item)

    return (e, { value }) =>
      actions.mergeInForm({
        descriptions: form
          .get('descriptions')
          .mergeIn([descriptionIndex, 'items', itemIndex], { [field]: value || undefined }),
      })
  }

  getDescription = ({ langId, order, describes }) => {
    return this.props.fromStore.form
      .get('descriptions')
      .find((s) => s.get('language_id') === langId && s.get('order') === order && s.get('describes') === describes)
  }

  getDescriptionItem({ order, description, langId = null, describes = null, descriptionOrder = null }) {
    if (!description) description = this.getDescription({ langId, order: descriptionOrder, describes })
    if (!description) return undefined
    return description.get('items').find((item) => item.get('order') === order)
  }

  getDescriptionsForLang(langId, describes) {
    const {
      fromStore: { form },
    } = this.props
    return form
      .get('descriptions')
      .filter((s) => s.get('describes') === describes && s.get('language_id') === parseInt(langId))
      .sortBy(
        (s) => s.get('order'),
        (o1, o2) => o1 - o2,
      )
      .map((s) =>
        s.set(
          'items',
          s.get('items').sortBy(
            (s) => s.get('order'),
            (o1, o2) => o1 - o2,
          ),
        ),
      )
  }

  moveDescriptionUp = ({ description }) => {
    const {
      fromStore: { form },
      actions,
    } = this.props
    const describes = description.get('describes')
    const usedLanguages = this.getUsedLanguages(describes)
    const order = description.get('order')
    if (order === 0) return
    let nextDescriptions = form.get('descriptions')
    usedLanguages.forEach((language) => {
      const description = this.getDescription({ langId: language.id, order, describes })
      const descriptionIndex = form.get('descriptions').indexOf(description)
      const descriptionBefore = this.getDescription({ langId: language.id, order: order - 1, describes })
      const descriptionBeforeIndex = form.get('descriptions').indexOf(descriptionBefore)
      nextDescriptions = nextDescriptions
        .setIn([descriptionBeforeIndex, 'order'], order)
        .setIn([descriptionIndex, 'order'], order - 1)
    })
    return actions.mergeInForm({ descriptions: nextDescriptions })
  }

  moveDescriptionDown = ({ description }) => {
    const langId = description.get('language_id')
    const describes = description.get('describes')
    const order = description.get('order')
    const previousDescription = this.getDescription({ describes, order: order + 1, langId })
    if (previousDescription) return this.moveDescriptionUp({ description: previousDescription })
  }

  removeDescription = ({ description }) => {
    const {
      fromStore: { form },
      actions,
    } = this.props
    const describes = description.get('describes')
    const order = description.get('order')

    const nextDescriptions = form
      .get('descriptions')
      .map((s) => {
        if (s.get('describes') !== describes) return s
        if (s.get('order') === order) return undefined
        if (s.get('order') > order) return s.set('order', s.get('order') - 1)
        return s
      })
      .filter((s) => !!s)

    return actions.mergeInForm({ descriptions: nextDescriptions })
  }

  moveDescriptionItemUp = ({ description, item }) => {
    const {
      fromStore: { form },
      actions,
    } = this.props
    const describes = description.get('describes')
    const descriptionOrder = description.get('order')
    const order = item.get('order')
    const usedLanguages = this.getUsedLanguages(describes)

    if (order === 0) return

    let nextDescriptions = form.get('descriptions')
    usedLanguages.forEach((language) => {
      const description = this.getDescription({ langId: language.id, describes, order: descriptionOrder })
      const descriptionIndex = form.get('descriptions').indexOf(description)
      const item = this.getDescriptionItem({ description, order })
      const itemIndex = description.get('items').indexOf(item)
      const itemBefore = this.getDescriptionItem({ description, order: order - 1 })
      const itemBeforeIndex = description.get('items').indexOf(itemBefore)

      nextDescriptions = nextDescriptions
        .setIn([descriptionIndex, 'items', itemBeforeIndex, 'order'], order)
        .setIn([descriptionIndex, 'items', itemIndex, 'order'], order - 1)
    })
    return actions.mergeInForm({ descriptions: nextDescriptions })
  }

  moveDescriptionItemDown = ({ description, item }) => {
    const order = item.get('order')
    const nextItem = this.getDescriptionItem({ description, order: order + 1 })
    if (nextItem) return this.moveDescriptionItemUp({ description, item: nextItem })
  }

  removeDescriptionItem = ({ description, item }) => {
    const {
      fromStore: { form },
      actions,
    } = this.props
    let nextDescription = form.get('descriptions')
    const descriptionOrder = description.get('order')
    const describes = description.get('describes')
    const order = item.get('order')
    const usedLanguages = this.getUsedLanguages(describes)

    usedLanguages.forEach((language) => {
      const description = this.getDescription({ describes, order: descriptionOrder, langId: language.id })
      const descriptionIndex = form.get('descriptions').indexOf(description)
      const nextItems = description
        .get('items')
        .map((item) => {
          if (item.get('order') === order) return undefined
          if (item.get('order') > order) return item.set('order', item.get('order') - 1)
          return item
        })
        .filter((d) => !!d)
      nextDescription = nextDescription.setIn([descriptionIndex, 'items'], nextItems)
    })
    return actions.mergeInForm({ descriptions: nextDescription })
  }

  addDescriptionItem = ({ description }) => {
    const {
      fromStore: { form },
      actions,
    } = this.props
    const descriptionsToUpdateToo = form
      .get('descriptions')
      .filter((d) => d.get('describes') === description.get('describes') && d.get('order') === description.get('order'))
    const orderOfNewDesc = description
      .get('items')
      .map((i) => i.get('order', 0))
      .max()
    const itemToPush = eventDescriptionItemSkeleton.set('order', orderOfNewDesc + 1)

    let nextDescriptions = form.get('descriptions')
    descriptionsToUpdateToo.forEach((description) => {
      const nextItems = description.get('items').push(itemToPush)
      const descriptionIndex = form.get('descriptions').indexOf(description)
      nextDescriptions = nextDescriptions.setIn([descriptionIndex, 'items'], nextItems)
    })
    return actions.mergeInForm({ descriptions: nextDescriptions })
  }

  addMenuDescription = () => {
    const {
      fromStore: { form },
      actions,
    } = this.props
    const menuDescriptions = form.get('descriptions').filter((s) => s.get('describes') === 'menu')
    const usedLanguages = menuDescriptions
      .map((s) => s.get('language_id'))
      .toSet()
      .toList()
    const orderOfNewDescription = menuDescriptions.filter((s) => s.get('language_id') === usedLanguages.first()).size
    const descriptionToPush = eventDescriptionSkeleton.merge({
      order: orderOfNewDescription,
      describes: 'menu',
      items: new List([eventDescriptionItemSkeleton.merge({ order: 0 })]),
    })

    let nextDescriptions = form.get('descriptions')
    usedLanguages.forEach((languageId) => {
      nextDescriptions = nextDescriptions.push(descriptionToPush.set('language_id', languageId))
    })
    return actions.mergeInForm({ descriptions: nextDescriptions })
  }

  addDescriptionsForLanguage = (languageId, describes) => {
    const {
      fromStore: { form },
      actions,
    } = this.props
    const descriptionsOfSameType = form.get('descriptions').filter((s) => s.get('describes') === describes)

    let descriptionsToPush
    if (!descriptionsOfSameType.size) {
      descriptionsToPush = new List([
        eventDescriptionSkeleton.merge({
          language_id: parseInt(languageId),
          order: 0,
          describes,
          items: new List([eventDescriptionItemSkeleton.merge({ order: 0 })]),
        }),
      ])
    } else {
      const langId = descriptionsOfSameType.first().get('language_id')
      const descriptionsOfSameTypeForOneLang = descriptionsOfSameType.filter((s) => s.get('language_id') === langId)
      descriptionsToPush = descriptionsOfSameTypeForOneLang.map((description) => {
        const items = description.get('items').map((desc) => {
          return desc.merge({
            id: undefined,
            title: undefined,
            body: desc.get('body'),
          })
        })
        return description.merge({
          id: undefined,
          title: description.get('title'),
          language_id: parseInt(languageId),
          items,
        })
      })
    }
    const nextDescriptions = form.get('descriptions').concat(descriptionsToPush)
    actions.mergeInForm({ descriptions: nextDescriptions })
  }

  removeDescriptionsForLanguage(languageId, describes) {
    const {
      fromStore: { form },
      actions,
    } = this.props
    const nextDescriptions = form
      .get('descriptions')
      .filterNot((s) => s.get('describes') === describes && s.get('language_id') === languageId)
      .sortBy(
        (s) => s.get('order'),
        (o1, o2) => o1 - o2,
      )
    actions.mergeInForm({ descriptions: nextDescriptions })
  }

  getUsedLanguages(describes) {
    const {
      fromStore: { getLanguage, form },
    } = this.props
    const descriptionsByDescribes = form.get('descriptions', new List()).filter((s) => s.get('describes') === describes)
    return descriptionsByDescribes
      .map((s) => s.get('language_id'))
      .toSet()
      .toList()
      .sort()
      .map(getLanguage)
  }

  render() {
    const {
      t,
      event,
      fromStore: { form },
      actions,
    } = this.props
    const isEventFrozen = !!event.frozen_at
    if (!form.size) return null

    return (
      <section id='section-description' className='EventForm-description'>
        <h3 className='section-title'>{t('Experiences::Summary::Description')}</h3>

        <Form.Field disabled={isEventFrozen}>
          <label>{t('Experiences::Summary::Experience title')}</label>
          <TabsDescriptionsByLang
            hostLanguage={event.user.account.language}
            describes='event'
            field='title'
            addDescriptionsForLanguage={(langId) => this.addDescriptionsForLanguage(langId, 'event')}
            removeDescriptionsForLanguage={(langId) => this.removeDescriptionsForLanguage(langId, 'event')}
            getUsedLanguages={() => this.getUsedLanguages('event')}
          >
            {({ languageId }) =>
              this.getDescriptionsForLang(languageId, 'event').map((description, index) => (
                <div key={description.get('id') || `title ${index}`}>
                  {description && (
                    <Form.Input
                      key={description.get('id') || `title ${index}`}
                      value={description.get('title', '')}
                      onChange={this.generateDescriptionChange({ description, field: 'title' })}
                      rows={10}
                      style={{ resize: 'vertical' }}
                      icon={
                        <CopyToClipboardButton text={description.get('title', '') || ''} style={{ top: 8, right: 4 }} />
                      }
                    />
                  )}
                </div>
              ))
            }
          </TabsDescriptionsByLang>
        </Form.Field>

        <Form.Field disabled={isEventFrozen}>
          <label>{t('Experiences::Summary::Meal type')}</label>
          <Form.Select
            value={form.get('type')}
            onChange={(e, { value }) => actions.mergeInForm({ type: value })}
            options={getTranslatedOptions(
              t,
              mealTypesOptions.map((option) => ({ key: option.value, ...option })),
            )}
          />
        </Form.Field>

        <Form.Field disabled={isEventFrozen}>
          <label>{t('Experiences::Summary::Description')}</label>
          <TabsDescriptionsByLang
            hostLanguage={event.user.account.language}
            describes='event'
            addDescriptionsForLanguage={(langId) => this.addDescriptionsForLanguage(langId, 'event')}
            removeDescriptionsForLanguage={(langId) => this.removeDescriptionsForLanguage(langId, 'event')}
            getUsedLanguages={() => this.getUsedLanguages('event')}
          >
            {({ languageId }) =>
              this.getDescriptionsForLang(languageId, 'event').map((description, index) => (
                <div key={description.get('id') || `description ${index}`}>
                  {(description.get('items').size ? description.get('items') : [eventDescriptionItemSkeleton]).map(
                    (item, i) => (
                      <div key={item.get('id') || `item ${i}`} style={{ position: 'relative' }}>
                        <Form.TextArea
                          value={item.get('body', '')}
                          onChange={this.generateDescriptionItemChange({ description, item, field: 'body' })}
                          rows={10}
                          style={{ resize: 'vertical', paddingRight: 32 }}
                        />
                        <CopyToClipboardButton text={item.get('body') || ''} style={{ bottom: 8, right: 4 }} />
                      </div>
                    ),
                  )}
                </div>
              ))
            }
          </TabsDescriptionsByLang>

          {!!event.tags.find((tag) => tag.indexOf('partner') !== -1) && (
            <TabsDescriptionsByLang
              hostLanguage={event.user.account.language}
              describes='partner_event'
              addDescriptionsForLanguage={(langId) => this.addDescriptionsForLanguage(langId, 'partner_event')}
              removeDescriptionsForLanguage={(langId) => this.removeDescriptionsForLanguage(langId, 'partner_event')}
              getUsedLanguages={() => this.getUsedLanguages('partner_event')}
            >
              {({ languageId }) =>
                this.getDescriptionsForLang(languageId, 'partner_event').map((description, index) => (
                  <div key={description.get('id') || `description ${index}`}>
                    {(description.get('items').size ? description.get('items') : [eventDescriptionItemSkeleton]).map(
                      (item, i) => (
                        <div key={item.get('id') || `item ${i}`} style={{ position: 'relative' }}>
                          <Form.TextArea
                            value={item.get('body', '')}
                            onChange={this.generateDescriptionItemChange({ description, item, field: 'body' })}
                            rows={10}
                            style={{ resize: 'vertical' }}
                          />
                          <CopyToClipboardButton text={item.get('body') || ''} style={{ bottom: 8, right: 4 }} />
                        </div>
                      ),
                    )}
                  </div>
                ))
              }
            </TabsDescriptionsByLang>
          )}

          <label>{t('Experiences::Summary::Highlight')}</label>
          <TabsDescriptionsByLang
            hostLanguage={event.user.account.language}
            describes='highlight'
            addDescriptionsForLanguage={(langId) => this.addDescriptionsForLanguage(langId, 'highlight')}
            removeDescriptionsForLanguage={(langId) => this.removeDescriptionsForLanguage(langId, 'highlight')}
            getUsedLanguages={() => this.getUsedLanguages('highlight')}
          >
            {({ languageId }) => (
              <div>
                {this.getDescriptionsForLang(languageId, 'highlight').map((description, index) => (
                  <div key={description.get('id') || `description ${index}`} className='__sectionItem'>
                    <div className='__sectionInputs'>
                      {description.get('items').map((item, i) => (
                        <div key={item.get('id') || `item ${i}`} className='__sectionDescriptionItem'>
                          <Form.TextArea
                            className='__sectionDescriptionBodyInput'
                            placeholder={t('Experiences::Summary::Highlight item')}
                            value={item.get('title') || ''}
                            onChange={this.generateDescriptionItemChange({ description, item, field: 'title' })}
                            rows={2}
                            style={{ resize: 'vertical' }}
                          />

                          <CopyToClipboardButton text={item.get('title') || ''} style={{ top: 32, right: -36 }} />

                          <div className='__sectionDescriptionMenu'>
                            <Button
                              basic
                              compact
                              circular
                              size='mini'
                              icon='chevron up'
                              disabled={i === 0}
                              onClick={() => this.moveDescriptionItemUp({ description, item })}
                            />
                            <Button
                              basic
                              compact
                              circular
                              size='mini'
                              icon='close'
                              onClick={() => this.removeDescriptionItem({ description, item })}
                            />
                            <Button
                              basic
                              compact
                              circular
                              size='mini'
                              icon='chevron down'
                              disabled={i === description.get('items').size - 1}
                              onClick={() => this.moveDescriptionItemDown({ description, item })}
                            />
                          </div>
                        </div>
                      ))}
                      <div className='__footerBtnContainer'>
                        <Button basic onClick={() => this.addDescriptionItem({ description })}>
                          {t('Experiences::Summary::Add a highlight')}
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </TabsDescriptionsByLang>

          <TabsDescriptionsByLang
            hostLanguage={event.user.account.language}
            describes='menu'
            addDescriptionsForLanguage={(langId) => this.addDescriptionsForLanguage(langId, 'menu')}
            removeDescriptionsForLanguage={(langId) => this.removeDescriptionsForLanguage(langId, 'menu')}
            getUsedLanguages={() => this.getUsedLanguages('menu')}
          >
            {({ languageId }) => {
              const descriptions = this.getDescriptionsForLang(languageId, 'menu')
              return (
                <div>
                  {descriptions.map((description, index) => (
                    <div key={description.get('id') || `description ${index}`} className='__sectionItem'>
                      <div className='__sectionMenu'>
                        {index === 0 && !!event.id && <DownloadMenuButton event={event} />}

                        <Button
                          basic
                          circular
                          icon='chevron up'
                          disabled={index === 0}
                          onClick={() => this.moveDescriptionUp({ description })}
                        />
                        <Button basic circular icon='close' onClick={() => this.removeDescription({ description })} />
                        <Button
                          basic
                          circular
                          icon='chevron down'
                          disabled={index === descriptions.size - 1}
                          onClick={() => this.moveDescriptionDown({ description })}
                        />
                      </div>

                      <div className='__sectionInputs'>
                        <Input
                          className='__sectionTitleInput'
                          placeholder={t('Experiences::Summary::Course title')}
                          value={description.get('title') || ''}
                          onChange={this.generateDescriptionChange({ description, field: 'title' })}
                        />

                        {description.get('items').map((item, i) => (
                          <div key={item.get('id') || `item ${i}`} className='__sectionDescriptionItem'>
                            <Input
                              className='__sectionDescriptionTitleInput'
                              placeholder={t('Experiences::Summary::Dish title')}
                              value={item.get('title') || ''}
                              onChange={this.generateDescriptionItemChange({ description, item, field: 'title' })}
                            />
                            <div style={{ position: 'relative' }}>
                              <Form.TextArea
                                className='__sectionDescriptionBodyInput'
                                placeholder={t('Experiences::Summary::Dish item')}
                                value={item.get('body') || ''}
                                onChange={this.generateDescriptionItemChange({ description, item, field: 'body' })}
                                rows={2}
                                style={{ resize: 'vertical' }}
                              />
                              <CopyToClipboardButton text={item.get('body') || ''} style={{ top: 16, right: -36 }} />
                            </div>

                            <div className='__sectionDescriptionMenu'>
                              <Button
                                basic
                                compact
                                circular
                                size='mini'
                                icon='chevron up'
                                disabled={i === 0}
                                onClick={() => this.moveDescriptionItemUp({ description, item })}
                              />
                              <Button
                                basic
                                compact
                                circular
                                size='mini'
                                icon='close'
                                onClick={() => this.removeDescriptionItem({ description, item })}
                              />
                              <Button
                                basic
                                compact
                                circular
                                size='mini'
                                icon='chevron down'
                                disabled={i === description.get('items').size - 1}
                                onClick={() => this.moveDescriptionItemDown({ description, item })}
                              />
                            </div>
                          </div>
                        ))}
                        <div className='__footerBtnContainer'>
                          <Button basic onClick={() => this.addDescriptionItem({ description })}>
                            {t('Experiences::Summary::Add a dish')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className='__footerBtnContainer'>
                    <Button basic onClick={() => this.addMenuDescription()}>
                      {t('Experiences::Summary::Add a course')}
                    </Button>
                  </div>
                </div>
              )
            }}
          </TabsDescriptionsByLang>
        </Form.Field>
      </section>
    )
  }
}

export const SectionDescription = withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(_SectionDescription),
)
