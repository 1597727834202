import { isEmpty } from 'lodash'
import i18n from '../i18n-client'

export const formatRestrictions = (restrictions, diets) => {
  if (isEmpty(restrictions)) return

  return restrictions
    .map((restriction, i) => {
      const guest =
        restriction.guest.trim() === ''
          ? i18n.t('Restrictions::Guest {{guestNumber}}', { guestNumber: i + 1 })
          : restriction.guest.trim()
      const text = diets
        .toJS()
        .filter((diet) => restriction.diets.includes(diet.id))
        .map((diet) => diet.title)
        .join(', ')
      return `${guest}: ${text}`
    })
    .join('\n')
}
