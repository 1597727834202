import React, { Fragment } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  getFood as getFoodSelector,
  getAlcohol as getAlcoholSelector,
  getDiet as getDietSelector,
} from 'redux/entities/selectors'
import { Segment, Header, Divider, Grid, List } from 'semantic-ui-react'
import { SampleExperienceLocation } from './SampleExperienceLocation'
import { SampleExperienceDescription } from './SampleExperienceDescription'
import { SampleExperienceGallery } from './SampleExperienceGallery'

export function SampleExperienceSection({ hostApplication: { sample_experience: sampleExperience, user, place } }) {
  const { t } = useTranslation()
  const getFood = useSelector((state) => (id) => getFoodSelector(state, { id }))
  const getAlcohol = useSelector((state) => (id) => getAlcoholSelector(state, { id }))
  const getDiet = useSelector((state) => (id) => getDietSelector(state, { id }))

  const experienceDescription = sampleExperience.get('description')

  if (!sampleExperience) return null

  return (
    <Segment>
      <Header as='h3'>{t('HostApplications::Summary::Sample experience')}</Header>
      <Segment basic>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Header size='small'>{t('HostApplications::Summary::Type of experience')}</Header>
              {sampleExperience.get('experience_type')}

              <Header size='small'>{t('HostApplications::Summary::Experience for')}</Header>
              <Trans
                i18nKey={__('HostApplications::Summary::From {{minSeatsNumber}} to {{maxSeatsNumber}} seats')}
                values={{
                  minSeatsNumber: String(sampleExperience.get('min_seats') || ''),
                  maxSeatsNumber: String(sampleExperience.get('max_seats') || ''),
                }}
              />
              <br />
              {sampleExperience.get('guest_type') && (
                <List bulleted>
                  {sampleExperience.get('guest_type').map((guestType) => (
                    <List.Item key={guestType}>{guestType}</List.Item>
                  ))}
                </List>
              )}
            </Grid.Column>

            <Grid.Column>
              {experienceDescription && experienceDescription.get('cuisine_ids') && (
                <Fragment>
                  <Header size='small'>{t('HostApplications::Summary::Type of cuisine')}</Header>
                  <List bulleted>
                    {experienceDescription.get('cuisine_ids').map((cuisineId) => (
                      <List.Item key={cuisineId}>{getFood(cuisineId).title}</List.Item>
                    ))}
                  </List>
                </Fragment>
              )}

              <Header size='small'>{t('HostApplications::Summary::Price')}</Header>
              {`${sampleExperience.get('price')} ${sampleExperience.get('currency_iso3')}`}

              <Header size='small'>{t('HostApplications::Summary::Is price flexible?')}</Header>
              {sampleExperience.get('is_price_flexible')
                ? t('HostApplications::Summary::Yes')
                : t('HostApplications::Summary::No')}
            </Grid.Column>

            <Grid.Column>
              <Header size='small'>{t('HostApplications::Summary::Drinks')}</Header>
              <List bulleted>
                {experienceDescription.get('alcohols_ids').map((alcoholId) => (
                  <List.Item key={alcoholId}>{getAlcohol(alcoholId).title}</List.Item>
                ))}
              </List>
              <Header size='small'>{t('HostApplications::Summary::Food intolerances')}</Header>
              <List bulleted>
                {experienceDescription.get('diets_ids').map((dietid) => (
                  <List.Item key={dietid}>{getDiet(dietid).title}</List.Item>
                ))}
              </List>
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row>
            <Grid.Column>
              <SampleExperienceLocation place={place} sampleExperience={sampleExperience} />
              <Divider />
            </Grid.Column>
          </Grid.Row>

          {sampleExperience.get('description') && (
            <Grid.Row>
              <Grid.Column>
                <SampleExperienceDescription sampleExperienceDescription={sampleExperience.get('description')} />
              </Grid.Column>
            </Grid.Row>
          )}

          {user && (
            <Grid.Row>
              <Grid.Column>
                <SampleExperienceGallery user={user} />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Segment>
    </Segment>
  )
}

SampleExperienceSection.propTypes = {
  hostApplication: PropTypes.shape({
    user: PropTypes.shape({}),
    sample_experience: PropTypes.shape({
      experience_type: PropTypes.string,
      min_seats: PropTypes.string,
      max_seats: PropTypes.string,
      price: PropTypes.string,
      currency_iso3: PropTypes.string,
      is_price_flexible: PropTypes.bool,
      guest_type: PropTypes.arrayOf([PropTypes.string]),
      cuisine_ids: PropTypes.arrayOf([PropTypes.number]),
      alcohols_ids: PropTypes.arrayOf([PropTypes.number]),
      diets_ids: PropTypes.arrayOf([PropTypes.number]),
      description: PropTypes.string,
    }),
    place: PropTypes.shape({}),
  }).isRequired,
}
