import React, { useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { useHasLoadingSucceeded } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import {
  archivingCategory,
  unarchivingCategory,
  getArchiveCategoryError,
  getUnarchiveCategoryError,
} from 'redux/entities/selectors'
import { getLocation } from 'redux/reducers/router'
import { archiveCategory, unarchiveCategory, fetchCategories } from 'redux/entities/actions'
import { Table, Button, Label, Icon, Popup, List } from 'semantic-ui-react'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { Tooltip } from 'components/tooltips/Tooltip'
import { omitBy, isNil } from 'lodash'
import moment from 'moment'
import { toast } from 'react-toastify'
import { CATEGORY_VISIBILITY_ICONS } from 'helpers/categories'

function ArchiveButton({ category }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  if (category.archived_at)
    return (
      <Button color='green' onClick={() => dispatch(unarchiveCategory(category.id))}>
        {t('Category::Unarchive')}
      </Button>
    )

  if (category.readonly)
    return (
      <Popup
        trigger={<Button>{t('Category::Archive')}</Button>}
        content={t("Category::Immutable categories can't be archived")}
        position='top left'
      />
    )

  return (
    <Button color='red' onClick={() => dispatch(archiveCategory(category.id))}>
      {t('Category::Archive')}
    </Button>
  )
}

ArchiveButton.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    archived_at: PropTypes.string,
    readonly: PropTypes.bool,
  }).isRequired,
}

export function CategoriesRow({ category }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const location = useSelector(getLocation)
  const isArchivingCategory = useSelector((state) => archivingCategory(state, category.id))
  const isUnarchivingCategory = useSelector((state) => unarchivingCategory(state, category.id))
  const archiveCategoryError = useSelector((state) => getArchiveCategoryError(state, category.id))
  const unarchiveCategoryError = useSelector((state) => getUnarchiveCategoryError(state, category.id))
  const isCategoryArchived = useHasLoadingSucceeded(isArchivingCategory, archiveCategoryError)
  const isCategoryUnarchived = useHasLoadingSucceeded(isUnarchivingCategory, unarchiveCategoryError)

  useEffect(() => {
    if (isCategoryArchived || isCategoryUnarchived) {
      const query = omitBy(location.get('query').toJS(), isNil())
      dispatch(fetchCategories({ query })) // need to set the current offset
    }
  }, [dispatch, isCategoryArchived, isCategoryUnarchived, location])

  useEffect(() => {
    if (isCategoryArchived || isCategoryUnarchived) {
      toast.success(
        `${
          isCategoryArchived
            ? t('ToastNotification::The category has been archived')
            : t('ToastNotification::The category has been unarchived')
        } 👍`,
        { type: toast.TYPE.SUCCESS },
      )
    }
  }, [isCategoryArchived, isCategoryUnarchived, t])

  return (
    <React.Fragment>
      <Table.Row>
        <Table.Cell>{category.id}</Table.Cell>
        <Table.Cell>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
            <Label circular size='mini' style={{ backgroundColor: category.color }} />
            {category.name}
            <Tooltip description={category.description} visibility={category.visibility} />
          </div>
        </Table.Cell>
        <Table.Cell>
          <span>
            <Icon name={CATEGORY_VISIBILITY_ICONS[category.visibility]} /> {category.visibility}
          </span>
        </Table.Cell>
        <Table.Cell>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
            {category.tags.size}{' '}
            {category.tags.size > 0 && (
              <Popup
                trigger={<Icon name='info' size='small' circular style={{ cursor: 'pointer' }} />}
                content={
                  <List bulleted>
                    {category.tags.map((tag) => (
                      <List.Item key={tag.id}>
                        {tag.title} {!!tag.archived_at && <b>t('Category::(Archived)')</b>}
                      </List.Item>
                    ))}
                  </List>
                }
                position='top left'
              />
            )}
          </div>
        </Table.Cell>
        <Table.Cell>{category.archived_at && moment.utc(category.archived_at).format('YYYY-MM-DD')}</Table.Cell>
        <Table.Cell>
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <ArchiveButton category={category} />
          </div>
        </Table.Cell>
      </Table.Row>
      {(archiveCategoryError || unarchiveCategoryError) && (
        <ApiErrorMessage error={archiveCategoryError || unarchiveCategoryError} modal />
      )}
    </React.Fragment>
  )
}

CategoriesRow.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    color: PropTypes.string,
    description: PropTypes.string,
    visibility: PropTypes.string,
    archived_at: PropTypes.string,
    tags: PropTypes.immutable.List,
  }).isRequired,
}
