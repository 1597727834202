// Libs
import React from 'react'
import { Route, IndexRoute, IndexRedirect, Redirect } from 'react-router'

// Components
import DefaultLayout from 'layouts/Default'
import NotFound from 'components/errors/NotFound'

export default (
  <Route component={require('pages/Authenticate').default}>
    <IndexRedirect to='/' />
    <Route path='/' component={DefaultLayout}>
      <IndexRoute component={require('pages/index').default} />

      <Route path='/login' component={require('pages/login').default} />

      <Route path='/users'>
        <IndexRoute component={require('pages/users').default} />
        <Route path=':id' component={require('pages/users/id').default} />
        <Route path=':id/bank-accounts' component={require('pages/users/id/bank-accounts').default} />
      </Route>

      <Route path='/requests'>
        <IndexRoute component={require('pages/requests').default} />
        <Route path=':id' component={require('pages/requests/id').default} />
      </Route>

      <Route path='/bookings'>
        <IndexRoute component={require('pages/bookings').default} />
        <Route path=':id' component={require('pages/bookings/id').default} />
      </Route>

      <Route path='/reviews'>
        <IndexRoute component={require('pages/reviews').default} />
        <Route path=':id' component={require('pages/reviews/id').default} />
      </Route>

      <Route path='/events'>
        <IndexRoute component={require('pages/events').default} type='reviewed' />
        <Route path=':id' component={require('pages/events/id').default} />
        <Redirect path=':id/details' to=':id' />
        <Redirect path=':id/media' to=':id' />
      </Route>

      <Route path='/hosts-applications'>
        <IndexRoute component={require('pages/events').default} type='review' />
        <Route path=':id' component={require('pages/events/id').default} />
        <Redirect path=':id/details' to=':id' />
        <Redirect path=':id/media' to=':id' />
      </Route>

      <Route path='/calendar-events'>
        <IndexRoute component={require('pages/events-calendar').default} />
      </Route>

      <Route path='/conversations'>
        <IndexRoute component={require('pages/conversations').default} />
      </Route>

      <Route path='/offline-reviews' component={require('pages/offline-reviews').default} />

      <Route path='/playlists'>
        <IndexRoute component={require('pages/playlists').default} />
        <Route path='add' component={require('pages/playlists/edit').default} />
        <Route path='edit/:id' component={require('pages/playlists/edit').default} />
      </Route>

      <Route path='/tags'>
        <IndexRoute component={require('pages/tags').TagsIndex} />
        <Route path='add' component={require('pages/tags/edit').TagFormWrapper} />
        <Route path='edit/:id' component={require('pages/tags/edit').TagFormWrapper} />
      </Route>

      <Route path='/categories'>
        <IndexRoute component={require('pages/categories').CategoriesIndex} />
        <Route path='add' component={require('pages/categories/edit').CategoryFormWrapper} />
        <Route path='edit/:id' component={require('pages/categories/edit').CategoryFormWrapper} />
      </Route>

      <Redirect from='/organisations' to='/organizations' />
      <Route path='/organizations'>
        <IndexRoute component={require('pages/organizations').OrganizationsIndex} />
        <Route path='add' component={require('pages/organizations/edit').OrganizationFormWrapper} />
        <Route path='edit/:id' component={require('pages/organizations/edit').OrganizationFormWrapper} />
      </Route>

      <Route path='/partners'>
        <IndexRoute component={require('pages/partners').PartnersIndex} />
        <Route path='add' component={require('pages/partners/edit').PartnerFormWrapper} />
        <Route path='edit/:id' component={require('pages/partners/edit').PartnerFormWrapper} />
      </Route>

      <Route path='/partner-demands'>
        <IndexRoute component={require('pages/partner-demands').PartnerDemandsIndex} />
        <Route path=':id' component={require('pages/partner-demands/id').PartnerDemandPage} />
      </Route>

      <Route path='/campaigns'>
        <IndexRoute component={require('pages/campaigns').default} />
        <Route path='add' component={require('pages/campaigns/edit').default} />
        <Route path='edit/:id' component={require('pages/campaigns/edit').default} />
      </Route>

      <Route path='/payouts'>
        <IndexRoute components={require('pages/payouts').default} />
      </Route>

      <Route path='/gallery'>
        <IndexRoute component={require('pages/gallery').default} />
      </Route>

      <Route path='/host-applications'>
        <IndexRoute component={require('pages/host-applications').HostApplicationsIndex} />
        <Route path='edit/:id' component={require('pages/host-applications/edit').HostApplicationPage} />
      </Route>

      <Route path='/bank-accounts'>
        <IndexRoute component={require('pages/bank-accounts').default} />
      </Route>

      <Route path='*' component={NotFound} />
    </Route>
  </Route>
)
