import { businessApi } from 'helpers/api'
import { ActionsCreator } from 'redux/entities'
import { schemas } from 'redux/entities/schemas'

export const bankAccountsActions = new ActionsCreator({ Api: businessApi, actionTypesPrefix: 'bankAccounts' })

export const fetchBankAccounts = ({ query } = {}) =>
  bankAccountsActions.fetch({
    url: '/api/bank_accounts',
    query,
    schema: { bankAccounts: [schemas.bankAccounts] },
  })

export const checkBankAccountsViewPermission = (query = {}) =>
  bankAccountsActions.head({
    url: '/api/bank_accounts',
    query,
  })
