// libs
import React from 'react'
import PropTypes from 'helpers/proptypes'
// components
import { Form, Button, Segment } from 'semantic-ui-react'
import { CategoriesDropdown } from 'components/forms/categories/CategoriesDropdown'
// helpers
import { getTranslatedOptions } from 'helpers/options'
import { organizationTypes } from 'helpers/organizations'
import { useTranslation } from 'react-i18next'

export const OrganizationForm = ({
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  values,
  touched,
  errors,
}) => {
  const { t } = useTranslation()
  return (
    <Form onSubmit={handleSubmit} autoComplete='off'>
      <Form.Field>
        <label>{t('Organizations::Summary::Tags')}</label>
        <CategoriesDropdown
          handleChange={(_, { value }) => setFieldValue('tags', value)}
          value={values.tags}
          error={!!errors.tags}
        />
      </Form.Field>

      <Form.Input
        id='name'
        label={t('Organizations::Summary::Name')}
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          errors.name &&
          touched.name && {
            content: errors.name,
          }
        }
      />

      <Form.Dropdown
        id='type'
        autoComplete='off'
        label={t('Organizations::Summary::Organization type')}
        selection
        options={getTranslatedOptions(t, organizationTypes)}
        value={values.type}
        onChange={(e, { value }) => setFieldValue('type', value)}
        onBlur={handleBlur}
        error={
          errors.type && {
            content: errors.type,
          }
        }
      />

      <Form.Input
        id='logoUrl'
        label={t('Organizations::Summary::Logo')}
        value={values.logoUrl}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          errors.logoUrl &&
          touched.logoUrl && {
            content: errors.logoUrl,
          }
        }
      />

      <Form.Input
        id='cancellationDelay'
        value={values.cancellationDelay}
        label={t('Organizations::Summary::Cancellation delay')}
        type='number'
        min='0'
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          errors.cancellationDelay &&
          touched.cancellationDelay && {
            content: errors.cancellationDelay,
          }
        }
      />

      <Form.Input
        id='revenueShare'
        value={values.revenueShare}
        label={t('Organizations::Summary::Revenue share')}
        type='number'
        min='0'
        max='1'
        step='0.01'
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          errors.revenueShare &&
          touched.revenueShare && {
            content: errors.revenueShare,
          }
        }
      />

      <Form.Input
        id='supportUrl'
        value={values.supportUrl}
        label={t('Organizations::Summary::Support url')}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          errors.supportUrl &&
          touched.supportUrl && {
            content: errors.supportUrl,
          }
        }
      />

      <Form.Input
        id='supportEmail'
        label={t('Organizations::Summary::Support email')}
        type='email'
        value={values.supportEmail}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          errors.supportEmail &&
          touched.supportEmail && {
            content: errors.supportEmail,
          }
        }
      />

      <Form.Input
        id='supportPhone'
        label={t('Organizations::Summary::Support phone number')}
        value={values.supportPhone}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          errors.supportPhone &&
          touched.supportPhone && {
            content: errors.supportPhone,
          }
        }
      />

      <Form.Checkbox
        id='displayPrice'
        checked={values.displayPrice}
        label={t('Organizations::Summary::Should we display prices ?')}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          errors.displayPrice &&
          touched.displayPrice && {
            content: errors.displayPrice,
          }
        }
      />

      <Form.Checkbox
        id='onlinePayment'
        checked={values.onlinePayment}
        label={t('Organizations::Summary::Does the partner pay by credit card? (uncheck to allow paying by invoice)')}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          errors.onlinePayment &&
          touched.onlinePayment && {
            content: errors.onlinePayment,
          }
        }
      />

      <Segment basic textAlign='center'>
        <Button primary type='submit' size='big'>
          {t('Organizations::Summary::Submit')}
        </Button>
      </Segment>
    </Form>
  )
}

OrganizationForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.shape({
    tagsList: PropTypes.immutable.list.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(organizationTypes.map((it) => it.value)).isRequired,
    logoUrl: PropTypes.string.isRequired,
    cancellationDelay: PropTypes.number.isRequired,
    revenueShare: PropTypes.number.isRequired,
    supportUrl: PropTypes.string,
    supportEmail: PropTypes.string,
    supportPhone: PropTypes.string,
    displayPrice: PropTypes.bool.isRequired,
    onlinePayment: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }).isRequired,
  errors: PropTypes.shape({
    tags: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    logoUrl: PropTypes.string,
    cancellationDelay: PropTypes.string,
    revenueShare: PropTypes.string,
    supportUrl: PropTypes.string,
    supportEmail: PropTypes.string,
    supportPhone: PropTypes.string,
    displayPrice: PropTypes.string,
    onlinePayment: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    name: PropTypes.bool,
    logoUrl: PropTypes.bool,
    cancellationDelay: PropTypes.bool,
    revenueShare: PropTypes.bool,
    supportUrl: PropTypes.bool,
    supportEmail: PropTypes.bool,
    supportPhone: PropTypes.bool,
    displayPrice: PropTypes.bool,
    onlinePayment: PropTypes.bool,
  }).isRequired,
}
