import { getEntities, getEntity } from './_utils'
import { getAmenity } from './categories'

export const getPlaces = getEntities('places')
export const getPlace = (state, { id }) => {
  const place = getEntity('places')(state, { id })
  return place.set(
    'amenities',
    place.amenities.map((amenity) => getAmenity(state, { id: amenity.get('id') })),
  )
}
