import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { hasOpenedSchedules, fetchingSchedule } from 'redux/entities/selectors'
import { Message } from 'semantic-ui-react'
import { getExperienceAppearanceInSearchError } from 'helpers/events'

export function SearchAppearanceCriteria({ event }) {
  const { t } = useTranslation()

  const hasOpenedScheduledEvents = useSelector((state) => hasOpenedSchedules(state, event.id))
  const searchError = getExperienceAppearanceInSearchError(event, hasOpenedScheduledEvents)
  const isFetchingEventSchedules = useSelector((state) => fetchingSchedule(state, event.id))

  if (isFetchingEventSchedules || !searchError.hasError) return null

  return (
    <section id='section-error-infos'>
      <Message negative>
        <Message.Header>
          {t('Experiences::Summary::This experience will not be displayed in the search for the following reasons:')}
        </Message.Header>
        <Message.List>
          {searchError.errors.doesNotHaveVizeatTag && (
            <Message.Item>{t('Experiences::Summary::The experience does not have the vizeat tag')}</Message.Item>
          )}
          {searchError.errors.hostHasChargesDisabled && (
            <Message.Item>{t('Experiences::Summary::The host has not completed the payment settings')}</Message.Item>
          )}
          {searchError.errors.eventIsUnpublished && (
            <Message.Item>{t('Experiences::Summary::The experience is not published')}</Message.Item>
          )}
          {searchError.errors.hasFutureDateClosed && (
            <Message.Item>{t('Experiences::Summary::The experience has no scheduled dates')}</Message.Item>
          )}
          {searchError.errors.reindexHasNotFinished && (
            <Message.Item>{t('Experiences::Summary::Reindexing the event may not have completed yet')}</Message.Item>
          )}
        </Message.List>
      </Message>
    </section>
  )
}

SearchAppearanceCriteria.propTypes = {
  event: PropTypes.immutable.record.isRequired,
}
